import { VscListSelection } from 'react-icons/vsc'

const Title = (props) => {
    return (
        <div className="tools title">
            {props.text || 'כותרת'}
            <VscListSelection />
        </div>
    )
}

export default Title
