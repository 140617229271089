import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import IconBtn from 'utils/tools/iconBtn/IconBtn'

const MyProductsPage = (props) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const onNavigateHandler = (to) => {
        if (searchParams.get('selected') === to) navigate({ pathname: '' })
        else navigate({ pathname: '', search: createSearchParams({ selected: to }).toString() })
    }
    return (
        <div className="myProductsPage storePage">
            <div className="menu">
                <IconBtn onClick={() => onNavigateHandler('1')} className={searchParams.get('selected') === '1' && 'selected'} classIcon="fi-rr-eye" classIconHover="fi-sr-eye" text="הכי ניצפים" />
                <IconBtn onClick={() => onNavigateHandler('2')} className={searchParams.get('selected') === '2' && 'selected'} classIcon="fi-rr-exclamation" classIconHover="fi-sr-exclamation" text="מלאי נמוך/חסר" />
                <IconBtn onClick={() => onNavigateHandler('3')} className={searchParams.get('selected') === '3' && 'selected'} classIcon="fi-rr-thumbs-up" classIconHover="fi-sr-thumbs-up" text="הנמכרים ביותר" />
                <IconBtn onClick={() => onNavigateHandler('4')} className={searchParams.get('selected') === '4' && 'selected'} classIcon="fi-rr-thumbs-down" classIconHover="fi-sr-thumbs-down" text="פחות נמכרים" />
            </div>
        </div>
    )
}

export default MyProductsPage
