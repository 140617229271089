const Bar = (props) => {
    return (
        <div className="tools bar flex">
            <div className="text">{props.bar.text}</div>
            <div className="value flex">
                <div className="fill" style={{ width: `${(props.bar.amount / props.total) * 100}%` }}></div>
                <div className="nofill" style={{ width: `${100 - (props.bar.amount / props.total) * 100}%` }}></div>
            </div>
            <div className="amount">({props.bar.amount})</div>
        </div>
    )
}

export default Bar
