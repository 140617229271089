import { useEffect, useState } from 'react'

const Input = (props) => {
    const [className, setClassName] = useState(props.className || '')
    const type = props.type || 'text'

    useEffect(() => {
        if (!props.value && props.validate && !props.notRequired)
            if (props.className) setClassName(props.className + ' outLineDangerColor')
            else setClassName('outLineDangerColor')
        else if (props.validate) {
            if (props.className) setClassName(props.className + ' outLineSafeColor')
            else setClassName('outLineSafeColor')
        } else setClassName(props.className || '')
    }, [props.value])

    const setValueHandler = (input) => {
        if (type === 'checkbox') props.setValue(input.checked)
        else props.setValue(input.value)
        // else if (props.type !== 'number' || (props.max && String(value).length < String(props.max).length) || (props.min && String(value).length > String(props.min).length)) props.setValue(input.value)
    }
    return (
        <input
            id={props.id}
            name={props.name}
            checked={type === 'checkbox' && props.value}
            type={type}
            value={type !== 'checkbox' && props.value}
            onChange={(e) => setValueHandler(e.target)}
            placeholder={props.placeholder}
            className={'tools input ' + className}
            disabled={props.isDisabled}
            title={props.title}
            autoComplete={props.autoComplete}
            pattern={props.pattern}
            max={props.max}
            min={props.min}
            required={props.notRequired ? false : true}
        />
    )
}

export default Input
