import { useRef, useState } from 'react'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import IconBtn from 'utils/tools/iconBtn/IconBtn'
import Scroll from 'utils/tools/scroll/Scroll'
import Title from 'utils/tools/title/Title'
import TopCategory from 'utils/tools/topCategory/TopCategory'

const TopCategoriesScroll = (props) => {
    const className = props.className ? ' ' + props.className : ''
    const scroll = useRef()
    const [leftBtn, setLeftBtn] = useState(false)
    const [rightBtn, setRightBtn] = useState(true)

    const onSetProduct = (product) => {
        props.setProducts((pre) => {
            const tmpArr = [...pre]
            const index = tmpArr.findIndex((x) => x.id === product.id)
            tmpArr[index] = product
            return tmpArr
        })
    }

    const onLeftClickHandler = () => {
        scroll.current.scrollLeft -= 280
    }

    const onRightClickHandler = () => {
        scroll.current.scrollLeft += 280
    }

    return (
        <div className={'productsScroll' + className}>
            <div className="header">
                {props.title && <Title text={props.title} />}
                <IconBtn icon={BiChevronLeft} text="הצג הכל" className="leftBtn" onClick={() => {}} />
            </div>
            <div className="products gridOne">
                <Scroll onLeft={setLeftBtn} onRight={setRightBtn} ref={scroll}>
                    {props.categories && props.categories.map((category) => <TopCategory key={category.id + Date.now()} category={category} />)}
                </Scroll>
                <span className="bg left full" />
                <span className="bg right full" />
                {leftBtn && <IconBtn icon={BiChevronLeft} className="leftBtn" onClick={onLeftClickHandler} />}
                {rightBtn && <IconBtn icon={BiChevronRight} className="rightBtn" onClick={onRightClickHandler} />}
            </div>
        </div>
    )
}

export default TopCategoriesScroll
