import Btn from "utils/tools/btn/Btn"

const Track = (props) => {
    return (
        <div className="tools track">
            <div className="title">יקר לך מידי?</div>
            <div className="text">ניתן לעקוב אחרי מוצר זה ואנחנו נודיע לך מתי המחיר ירד!</div>
            <Btn>עקוב אחר מוצר זה</Btn>
        </div>
    )
}

export default Track
