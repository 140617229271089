const currentCart = process.env.REACT_APP_LS_CURRENT_CART
const allCarts = process.env.REACT_APP_LS_ALL_CARTS

export const addToCurrentCartService = (storeId, product, amount, isToBuy, isToAddAmount) => {
    let productId = product.masterProductId || product.id
    let tmpCart = JSON.parse(localStorage.getItem(currentCart))
    let tmpProduct
    tmpProduct = tmpCart[productId] || { ...product }

    tmpProduct.isToBuy = isToBuy
    if (isToAddAmount && tmpProduct.amountInCart) tmpProduct.amountInCart += amount
    else tmpProduct.amountInCart = amount

    tmpCart[productId] = tmpProduct

    if (tmpCart && tmpCart.storeId === storeId) {
        localStorage.setItem(currentCart, JSON.stringify({ ...tmpCart }))
        updateCurrentInAllCartService({ ...tmpCart })
    } else {
        localStorage.setItem(currentCart, JSON.stringify({ storeId: storeId, ...tmpCart }))
        updateCurrentInAllCartService({ storeId: storeId, ...tmpCart })
    }
}

export const removeFromCurrentCartService = (storeId, product) => {
    let productId = product.masterProductId || product.id
    let tmpCart = JSON.parse(localStorage.getItem(currentCart))
    if (tmpCart.storeId === storeId) delete tmpCart[productId]

    localStorage.setItem(currentCart, JSON.stringify({ ...tmpCart }))
    updateCurrentInAllCartService({ ...tmpCart })
}

export const clearCrentCartService = (id) => {
    if (!id) id = JSON.parse(localStorage.getItem(currentCart)).storeId
    localStorage.setItem(currentCart, JSON.stringify({ storeId: id }))
    updateCurrentInAllCartService()

    triggerLocalStorageEvent()
}

export const updateCurrentInAllCartService = (cart) => {
    let tmpCart = { ...cart }
    if (tmpCart.storeId) {
        let storeId = tmpCart.storeId
        delete tmpCart.storeId
        let tmpAllCart = JSON.parse(localStorage.getItem(allCarts))

        if (tmpAllCart) {
            if (tmpAllCart[tmpCart.storeId]) {
                let cartToReplace = tmpAllCart[tmpCart.storeId]
                cartToReplace = { [storeId]: { ...tmpCart } }
            }
            localStorage.setItem(allCarts, JSON.stringify({ ...tmpAllCart, [storeId]: { ...tmpCart } }))
        } else localStorage.setItem(allCarts, JSON.stringify({ [storeId]: { ...tmpCart } }))
    }

    triggerLocalStorageEvent()
}

export const loadCurrentCartService = (storeId) => {
    let tmpAllCart = JSON.parse(localStorage.getItem(allCarts))
    if (tmpAllCart) {
        let tmpCurrentCart = tmpAllCart[storeId]
        localStorage.setItem(currentCart, JSON.stringify({ ...tmpCurrentCart }))
        triggerLocalStorageEvent()
    }
}

export const triggerLocalStorageEvent = (value) => {
    window.dispatchEvent(new Event('cartChange'))
    // window.dispatchEvent(new Event('storage'))
}
