import IconBtn from '../iconBtn/IconBtn'
import { BsHeartFill, BsHeart } from 'react-icons/bs'
import Loading from '../loading/Loading'
import formatMoney from 'servises/local/functions/formatMoney'
import Rate from '../rate/Rate'
import AmountManeger from '../amountManeger/AmountManeger'
import { useState, useEffect } from 'react'
import getLocalStorageController from 'controllers/localStorage/getLocalStorageController'
import { currentCartController } from 'controllers/cart/cartController'
import { useParams } from 'react-router-dom'
import getImageProduct from 'controllers/images/getImageProduct'
import getImageLogo from 'controllers/images/getImageLogo'
import { randomIntFromInterval } from 'servises/local/functions/randomIntFromInterval'

const ProductCardHome = (props) => {
    const [sizeClass, setSizeClass] = useState()
    useEffect(() => {
        let tmpClass = 'regularSize'
        if (props.isRandomSize) {
            const rndInt = randomIntFromInterval(1, 3)
            if (rndInt % 3 === 0) {
                tmpClass = 'smallSize'
            } else if (rndInt % 2 === 0) {
                tmpClass = 'bigSize'
            }
        }
        setSizeClass(tmpClass)
    }, [])

    const onLikeHandler = () => {
        // if (props.value === undefined) return
        // let tmpProduct = { ...props.value }
        // tmpProduct.isLike = !tmpProduct.isLike
        // props.set(tmpProduct)
    }

    const onClickHandler = () => {
        props.setProductPopup(true)
    }

    return (
        <div className={'tools productCardHome ' + sizeClass} title="לחץ/י לפתיחת מוצר" onClick={onClickHandler}>
            {props.value ? (
                <div className="details">
                    <div className="top gridOne">
                        <img src={getImageProduct(props.value.advancedDataItems.images[0])} className="image" />
                        <img src={props.value.logo ? getImageLogo(props.value.logo) : '/assets/images/logo.svg'} className="logo" />
                        <IconBtn icon={props.value.isLike ? BsHeartFill : BsHeart} className="like" onClick={onLikeHandler} />
                        {(props.value.storeProductisSale || props.value.isSale) && <span className="isSale">עכשיו במבצע!</span>}
                    </div>
                    <div className="bot gridOne">
                        <div className="prodDit">
                            <span className="name">{props.value.name}</span>
                            <span className="company">{props.value.companyName}</span>
                        </div>
                        <div className="storeDit">
                            <div className="prices flex">
                                {(props.value.storeProductisSale || props.value.isSale) && <span className="salePrice">{formatMoney(props.value.storeProductsalePrice)}</span>}
                                <span className={props.value.storeProductisSale || props.value.isSale ? 'price sale' : 'price'}>{formatMoney(props.value.storeProductPrice || props.value.price)}</span>
                            </div>
                            {Rate(props.value.ratingAverage)}
                        </div>
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    )
}

export default ProductCardHome
