import { getProductByIdController, getProductsByCategoryIdController, getProductsController } from 'controllers/controllers'
import getImageProduct from 'controllers/images/getImageProduct'
import getProductsByCompanyController from 'controllers/products/getProductsByCompanyController'
import getProductsByLogoController from 'controllers/products/getProductsByLogoController'
import getProductsBySearchController from 'controllers/products/getProductsBySearchController'
import getSessionStorageController from 'controllers/sessionStorage/getSessionStorageController'
import addStoreProductController from 'controllers/stores/addStoreProductController'
import getStoreProductByIdandstoreIdController from 'controllers/stores/getStoreProductByIdandstoreIdController'
import updateStoreProductController from 'controllers/stores/updateStoreProductController'
import { useEffect, useState } from 'react'
import { VscChevronLeft } from 'react-icons/vsc'
import FileWrapper from 'utils/layout/fileWrapper/FileWrapper'
import FullPopup from 'utils/layout/fullPopup/FullPopup'
import GridProducts from 'utils/layout/gridProducts/GridProducts'
import MessageWithBtns from 'utils/layout/messageWithBtns/MessageWithBtns'
import Btn from 'utils/tools/btn/Btn'
import IconBtn from 'utils/tools/iconBtn/IconBtn'
import Image from 'utils/tools/image/Image'
import Input from 'utils/tools/input/Input'
import Loading from 'utils/tools/loading/Loading'
import Option from 'utils/tools/option/Option'
import Select from 'utils/tools/select/Select'
import Textarea from 'utils/tools/textarea/Textarea'

let isLoad = false

const AddProductPage = (props) => {
    const [allProducts, setAllProducts] = useState()
    const [inputsClassName, setInputsClassName] = useState('')

    const [productImage, setProductImage] = useState()
    const [productName, setProductName] = useState()
    const [productNameIsDisable, setProductNameIsDisable] = useState(false)
    const [productBarcode, setProductBarcode] = useState()
    const [productBarcodeIsDisable, setProductBarcodeIsDisable] = useState(false)
    const [productDescription, setProductDescription] = useState()
    const [productDescriptionIsDisable, setProductDescriptionIsDisable] = useState(false)
    const [productCompany, setProductCompany] = useState()
    const [productCompanyIsDisable, setProductCompanyIsDisable] = useState(false)
    const [productBuyPrice, setProductBuyPrice] = useState()
    const [profitPrecent, setProfitPrecent] = useState(30)
    const [productSellPrice, setProductSellPrice] = useState()
    const [productSellUnit, setProductSellUnit] = useState(1)
    const [productAmount, setProductAmount] = useState()

    const [precentClass, setPrecentClass] = useState('')

    const [storeProduct, setStoreProduct] = useState()
    const [selectedProduct, setSelectedProduct] = useState()

    const [isShowPopupSubmit, setIsShowPopupSubmit] = useState(false)
    const [isShowPopupClientError, setIsShowPopupClientError] = useState(false)
    const [clientErrorText, setClientErrorText] = useState('')
    const [isShowPopupFromServer, setIsShowPopupFromServer] = useState(false)
    const [responseFromServer, setResponseFromServer] = useState()

    const { searchBy, setSearchBy } = props.searchByInput
    const { inputSearchPropducts, setInputSearchPropducts } = props.searchInput
    const { allCategories, currentCategory, setCurrentCategory, lastCategory, setLastCategory } = props.categoryArr

    useEffect(() => {
        if (!isShowPopupFromServer) setResponseFromServer()
    }, [isShowPopupFromServer])

    useEffect(() => {
        isLoad = false
        setAllProducts()
        if (allCategories && inputSearchPropducts) setCurrentCategory({ ...allCategories[0] })
        loadProducts(1)
    }, [inputSearchPropducts, searchBy])

    useEffect(() => {
        if (currentCategory && currentCategory.id) {
            loadProducts(1)
            if (currentCategory.id !== 'ctgy_bIZqbE17701lqCW8770') setInputSearchPropducts('')
        }
    }, [currentCategory, lastCategory])

    const loadProducts = async (pageNum) => {
        if (currentCategory && currentCategory.id && currentCategory.id !== 'ctgy_bIZqbE17701lqCW8770') {
            await setProductsByCategoryHandler(pageNum)
        } else if (inputSearchPropducts && searchBy)
            switch (searchBy) {
                case 'bySearch':
                    await setProductsBySearchHandler(pageNum)
                    break
                case 'byLogo':
                    await setProductsBylogoHandler(pageNum)
                    break
                case 'byCompany':
                    await setProductsByCompanyHandler(pageNum)
                    break
                default:
                    break
            }
        else await setAllProductsHandler(pageNum)

        isLoad = false
    }

    const setAllProductsHandler = async (pageNum) => {
        const tmpProducts = await getProductsController(pageNum)
        updateProducts(tmpProducts)
    }
    const setProductsByCategoryHandler = async (pageNum) => {
        let tmpCat = lastCategory ? lastCategory : currentCategory
        const tmpProducts = await getProductsByCategoryIdController(tmpCat.id, pageNum)
        updateProducts(tmpProducts)
    }
    const setProductsBySearchHandler = async (pageNum) => {
        const tmpProducts = await getProductsBySearchController(inputSearchPropducts, pageNum)
        updateProducts(tmpProducts)
    }
    const setProductsBylogoHandler = async (pageNum) => {
        const tmpProducts = await getProductsByLogoController(inputSearchPropducts, pageNum)
        updateProducts(tmpProducts)
    }
    const setProductsByCompanyHandler = async (pageNum) => {
        const tmpProducts = await getProductsByCompanyController(inputSearchPropducts, pageNum)
        updateProducts(tmpProducts)
    }

    const updateProducts = (products) => {
        setAllProducts((pre) => {
            if (pre && pre.list.length > 0 && pre.currentPage == products.currentPage - 1) {
                pre.list = [...pre.list, ...products.list]
                pre.currentPage = products.currentPage
                pre.totalPages = products.totalPages
                return { ...pre }
            } else if (products.currentPage === 1) return products
            return pre
        })
    }

    const onScroll = (e) => {
        let offset = 2000
        let loadPresent = e.scrollHeight - e.offsetHeight - offset

        if (!isLoad && loadPresent < e.scrollTop) {
            isLoad = true
            if (allProducts.currentPage < allProducts.totalPages) {
                loadProducts(allProducts.currentPage + 1)
                console.log('Load!')
            }
        }
    }

    const onClickHandler = async (inputProduct) => {
        const product = await getProductByIdController(inputProduct.id)
        setSelectedProduct(product)
        setProductImage(product.advancedDataItems.images[0] || '')
        setProductName(product.name)
        setProductBarcode(product.barCode)
        setProductDescription(product.description)
        setProductCompany(product.companyName)
        setProductNameIsDisable(true)
        setProductBarcodeIsDisable(true)
        setProductDescriptionIsDisable(true)
        setProductCompanyIsDisable(true)
        setProfitPrecent(30)

        const store = await getSessionStorageController(process.env.REACT_APP_SS_STORE)
        const tmpStoreProduct = await getStoreProductByIdandstoreIdController(store.id, product.id)

        if (tmpStoreProduct) {
            setProductSellPrice(parseFloat(tmpStoreProduct.storeProductPrice).toFixed(2))
            setProductBuyPrice(parseFloat((100 * tmpStoreProduct.storeProductPrice) / (100 + 30)).toFixed(2))
            setProductAmount(tmpStoreProduct.storeProductQuantity)
        } else {
            setProductBuyPrice('')
            setProductSellPrice('')
            setProductAmount('')
        }
        setStoreProduct(tmpStoreProduct)
    }

    const onNameClickHandler = (product) => {
        setSearchBy('bySearch')
        setInputSearchPropducts(product.name)
    }

    const onLogoClickHandler = (product) => {
        setSearchBy('byLogo')
        setInputSearchPropducts(product.logo)
    }

    const onCompanyClickHandler = (product) => {
        setSearchBy('byCompany')
        setInputSearchPropducts(product.companyName)
    }

    const openCloseHandler = () => {
        inputsClassName === 'active' ? setInputsClassName('') : setInputsClassName('active')
    }

    const [isBuyPriceChange, setIsBuyPriceChange] = useState(false)
    const productBuyPriceHandler = (value) => {
        setIsBuyPriceChange(true)
        if (value && profitPrecent && !isSellPricChange) {
            setProductSellPrice(parseFloat(value) * ((100 + parseFloat(profitPrecent)) / 100))
        }
        setProductBuyPrice(value)
    }

    const [isProfitPrecentChange, setIsProfitPrecentChange] = useState(false)
    const profitPrecentHandler = (value) => {
        setIsProfitPrecentChange(true)
        if (value && productBuyPrice) {
            setProductSellPrice(parseFloat(parseFloat(productBuyPrice) * ((100 + parseFloat(value)) / 100)).toFixed(2))
        }
        setProfitPrecent(value)
    }
    useEffect(() => {
        profitPrecent < 0 ? setPrecentClass('dangerColor') : setPrecentClass('')
    }, [profitPrecent])

    const [isSellPricChange, setIsSellPricChange] = useState(false)
    const productSellPriceHadler = (value) => {
        setIsSellPricChange(true)
        if (!isBuyPriceChange && productBuyPrice && value) setProfitPrecent((parseFloat(parseFloat(value) * 100) / parseFloat(productBuyPrice) - 100).toFixed(2))
        setProductSellPrice(value)
    }

    const clearHandler = () => {
        setProductImage('')
        setProductDescription('')
        setProductName('')
        setProductBarcode('')
        setProductCompany('')
        setProductBuyPrice('')
        setProfitPrecent(30)
        setProductSellPrice('')
        setProductSellUnit(1)
        setProductAmount('')
        setStoreProduct()
        setProductNameIsDisable(false)
        setProductBarcodeIsDisable(false)
        setProductDescriptionIsDisable(false)
        setProductCompanyIsDisable(false)
    }

    const setProductBarcodeHandler = async (value) => {
        if (value && value.length > 4) {
            const res = await getProductsBySearchController(value)
            setAllProducts(res.list)
        } else if (!value) {
            const res = await getProductsController()
            setAllProducts(res.list)
        }
        setProductBarcode(value)
    }

    const onSubmitHandler = () => {
        if (!selectedProduct) {
            setClientErrorText('לא נבחר מוצר')
            setIsShowPopupClientError(true)
        } else if (!productImage || !productDescription || !productName || !productBarcode || !productCompany || !productBuyPrice || !profitPrecent || !productSellPrice || !productSellUnit || !productAmount) {
            setClientErrorText('לא כל השדות מלאות')
            setIsShowPopupClientError(true)
        } else {
            setIsShowPopupSubmit(true)
        }
    }

    const onSubmitToServerHandler = async () => {
        setIsShowPopupSubmit(false)
        const store = await getSessionStorageController(process.env.REACT_APP_SS_STORE)
        if (!selectedProduct || !store) return

        let ret
        setIsShowPopupFromServer(true)
        if (storeProduct) {
            //update
            const tmpProduct = await getStoreProductByIdandstoreIdController(store.id, selectedProduct.id)
            if (tmpProduct) ret = await updateStoreProductController(tmpProduct.storeProductId, productBuyPrice, productSellPrice, productAmount)
        } else {
            //add
            ret = await addStoreProductController(store.id, selectedProduct.id, productBuyPrice, productSellPrice, productAmount)
        }

        ret ? setResponseFromServer({ text: storeProduct ? 'מוצר עודכן בהצלחה' : 'מוצר נוסף בהצלחה', isSucceed: true }) : setResponseFromServer({ text: 'מוצר לא נוסף, בבקשה נסה שוב', isSucceed: false })
    }

    return (
        <div className="addProductPage flex">
            <div className={'selectProducts flex ' + inputsClassName}>
                <GridProducts
                    onScroll={onScroll}
                    productType="small"
                    title="מצא מוצר"
                    products={allProducts ? allProducts.list : []}
                    onClick={onClickHandler}
                    onNameClick={onNameClickHandler}
                    onLogoClick={onLogoClickHandler}
                    onCompanyClick={onCompanyClickHandler}
                />
                <IconBtn icon={VscChevronLeft} onClick={openCloseHandler} />
            </div>
            <form className={'inputs ' + inputsClassName}>
                <FileWrapper className="item1">
                    <Image validate={true} src={productImage ? getImageProduct(productImage) : ''} />
                </FileWrapper>
                <Input value={productBarcode} setValue={(value) => setProductBarcodeHandler(value)} validate={true} className="item4" placeholder="ברקוד" type="number" isDisabled={productBarcodeIsDisable} />
                <Input value={productName} setValue={setProductName} validate={true} className="item3" placeholder="שם המוצר" isDisabled={productNameIsDisable} />
                <Textarea value={productDescription} setValue={setProductDescription} validate={true} className="item2" placeholder="תיאור המוצר" type="textarea" isDisabled={productDescriptionIsDisable} />
                <Input value={productCompany} setValue={setProductCompany} validate={true} className="item5" placeholder="שם חברה" isDisabled={productCompanyIsDisable} />
                <div className="item6 inputAndDropdown flex">
                    <Input value={productBuyPrice} setValue={(value) => productBuyPriceHandler(value)} validate={true} placeholder="מחיר קנייה" type="number" />
                    <Select validate={true}>
                        <Option text="₪" />
                        <Option text="$" />
                    </Select>
                </div>
                <div className="item8 inputAndDropdown flex ">
                    <Input value={profitPrecent} setValue={(value) => profitPrecentHandler(value)} validate={true} placeholder="אחוזי רווח" type="number" className={precentClass} />
                    <Select className={precentClass} validate={true}>
                        <Option text="%" />
                    </Select>
                </div>
                <div className="item7 inputAndDropdown flex">
                    <Input value={productSellPrice} setValue={(value) => productSellPriceHadler(value)} validate={true} placeholder="מחיר מכירה" type="number" />
                    <Select validate={true}>
                        <Option text="₪" />
                        <Option text="$" />
                    </Select>
                </div>
                <div className="item8 inputAndDropdown flex">
                    <Input value={productSellUnit} setValue={setProductSellUnit} validate={true} placeholder="צורת מחירה" type="number" />
                    <Select validate={true}>
                        <Option text="יחידות" />
                        <Option text='מ"ג' />
                        <Option text="גרם" />
                        <Option text='ק"ג' />
                        <Option text='מ"ל' />
                        <Option text="ליטר" />
                    </Select>
                </div>
                <Input value={productAmount} setValue={setProductAmount} validate={true} className="item9" placeholder="כמות במלאי" type="number" />
                <div className="btns center">
                    <Btn type="submit" className={storeProduct && 'inStore'} onClick={onSubmitHandler}>
                        {storeProduct ? 'עדכן מוצר לחנות' : 'הוסף מוצר לחנות'}
                    </Btn>
                    <Btn className="clear" onClick={clearHandler}>
                        נקה{' '}
                    </Btn>
                </div>
            </form>

            <FullPopup isShow={isShowPopupSubmit} setIsShow={setIsShowPopupSubmit}>
                <MessageWithBtns text={storeProduct ? 'האם לעכן מוצר זה בחנות?' : 'האם להוסיף מוצר זה לחנות?'}>
                    <IconBtn className="defaultColor" text={storeProduct ? 'עדכן' : 'הוסף'} onClick={onSubmitToServerHandler} type="submit" />
                    <IconBtn className="dangerColor" text="ביטול" onClick={() => setIsShowPopupSubmit(false)} />
                </MessageWithBtns>
            </FullPopup>
            <FullPopup isShow={isShowPopupClientError} setIsShow={setIsShowPopupClientError}>
                <MessageWithBtns text={clientErrorText}>
                    <IconBtn className="dangerColor" text="חזור" onClick={() => setIsShowPopupClientError(false)} />
                </MessageWithBtns>
            </FullPopup>
            <FullPopup isShow={isShowPopupFromServer} setIsShow={setIsShowPopupFromServer}>
                {responseFromServer ? (
                    <MessageWithBtns text={responseFromServer.text}>
                        <IconBtn className={responseFromServer.isSucceed ? 'defaultColor' : 'dangerColor'} text="חזור" onClick={() => setIsShowPopupFromServer(false)} />
                    </MessageWithBtns>
                ) : (
                    <Loading />
                )}
            </FullPopup>
        </div>
    )
}

export default AddProductPage
