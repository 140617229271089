import Title from 'utils/tools/title/Title'

const SalesPage = (props) => {
    return (
        <div className="salesPage storePage gridOne">
            <div className="bg"></div>
            <Title text="מבצעים" />
        </div>
    )
}

export default SalesPage
