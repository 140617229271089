import getImageCategoryBg from 'controllers/images/getImageCategoryBg'
import { createSearchParams, useNavigate } from 'react-router-dom'

const TopCategory = (props) => {
    const navigate = useNavigate()
    const onClickHandler = () => {
        navigate({ pathname: '', search: createSearchParams({ ctgy: props.category.id }).toString() })
    }

    return (
        <div className="topCategory tools gridOne center clickable" onClick={onClickHandler}>
            <img src={getImageCategoryBg(props.category.metadata.image)} />
            <span></span>
            <div>{props.category.name}</div>
        </div>
    )
}

export default TopCategory
