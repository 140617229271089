import Title from 'utils/tools/title/Title'

const CostumersClubPage = (props) => {
    return (
        <div className="costumersClubPage storePage gridOne">
            <div className="bg"></div>
            <Title text="מועדון לקוחות" />
        </div>
    )
}

export default CostumersClubPage
