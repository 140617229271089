import { useRef } from 'react'
import { useEffect } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import Loading from 'utils/tools/loading/Loading'
import Scroll from 'utils/tools/scroll/Scroll'
import CategoryCard from './categoryCard/CategoryCard'

const CategoriesScroll = (props) => {
    const navigate = useNavigate()
    const scroll = useRef()
    const className = props.className ? ' ' + props.className : ''
    const { allCategories, currentCategory, setCurrentCategory, lastCategory, setLastCategory } = props.categoryArr

    useEffect(() => {
        if (props.isProducts !== undefined && allCategories) setCurrentCategory(props.isProducts ? allCategories[0] : allCategories[1])
    }, [props.isProducts])

    const setCategory = async (value) => {
        if (value.sonsImmediate.length === 0 && value !== lastCategory) {
            setLastCategory(value)
        } else if (value.sonsImmediate.length === 0 && lastCategory) setLastCategory(null)
        else {
            setLastCategory(null)
            setCurrentCategory(value)
        }
    }

    const backCategory = () => {
        if (props.isProducts !== undefined) {
            props.isProducts ? findCategory(currentCategory.parentId, allCategories[0]) : findCategory(currentCategory.parentId, allCategories[1])
        }
    }

    const findCategory = (parentId, category) => {
        if (parentId === category.id) {
            setLastCategory(null)
            setCurrentCategory(category)
            navigate({ pathname: '', search: createSearchParams({ ctgy: category.id }).toString() })
        } else {
            if (category.sonsImmediate)
                for (let i = 0; i < category.sonsImmediate.length; i++) {
                    findCategory(parentId, category.sonsImmediate[i])
                }
        }
    }

    return currentCategory ? (
        <div className={'categoriesScroll flex' + className}>
            <div className="header center clickable" onClick={() => backCategory()}>
                {/* {currentCategory === allCategories[0] ? (props.defaulHeader ? props.defaulHeader : 'קטגוריות') : currentCategory.name} */}
                {currentCategory.name}
            </div>
            <Scroll ref={scroll} className="categories flex">
                {currentCategory &&
                    currentCategory.sonsImmediate &&
                    currentCategory.sonsImmediate.length > 0 &&
                    currentCategory.sonsImmediate.map((category) => <CategoryCard key={'categoriesScroll_categoryCard_' + category.id} value={category} setValue={setCategory} />)}
            </Scroll>
        </div>
    ) : (
        <Loading />
    )
}

export default CategoriesScroll
