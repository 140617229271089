import { postReqAllowed } from '../httpReq'

const api = 'verification/confirm'
export default async (phoneNumber, code) => {
    const body = {
        verificationType: 'PHONE',
        toVerify: phoneNumber,
        code: code,
    }
    const result = await postReqAllowed(api, body)
    return result
}
