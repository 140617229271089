import Input from '../input/Input'
import { BiSearchAlt, BiXCircle } from 'react-icons/bi'

const SearchBar = (props) => {
    const className = props.className ? ' ' + props.className : ''
    return (
        <label className={'tools searchBar center' + className}>
            {props.input ? <BiXCircle className="icon clickable" onClick={() => props.setInput('')} /> : <BiSearchAlt className="icon clickable" />}
            <Input value={props.value} setValue={props.setValue} placeholder={props.placeholder}/>
        </label>
    )
}

export default SearchBar
