import { postReq } from '../httpReq'

const api = 'stores/set-logo'
export default async (formdata, orgId, id) => {
    const headers = {
        "Content-Type": "multipart/form-data"
        // 'x-stelace-organization-id': orgId,
    }
    const result = await postReq(api, formdata, [id], [], headers)
    return result
}
