import { useEffect } from 'react'
import { useState } from 'react'
import ProductCardHome from 'utils/tools/productCardHome/ProductCardHome'
import Title from 'utils/tools/title/Title'

const TwoRoudsProducts = (props) => {
    const [productsRight, setProductsRight] = useState()
    const [productsLeft, setProductsLeft] = useState()

    useEffect(() => {
        if (props.products) {
            let leftArr = []
            let rightArr = []
            props.products.forEach((product, i) => {
                product.sizeNum = i
                if (i % 2) leftArr.push(product)
                else rightArr.push(product)
            })

            setProductsLeft(leftArr)
            setProductsRight(rightArr)
        }
    }, [props.products])

    return (
        <div className="twoRoudsProducts layout" onScroll={(e) => props.onScroll && props.onScroll(e.target)}>
            <div className="header">{props.title && <Title text={props.title} />}</div>
            <div className="products flex">
                <div className="productsRight flexColumn">
                    {productsRight &&
                        productsRight.map((product) => (
                            <ProductCardHome
                                sizeNum={product.sizeNum}
                                key={'gridProducts_productCard_' + product.barCode + product.name + product.id}
                                value={product}
                                setProductPopup={(flag) => props.setProductPopup(flag, product)}
                            />
                        ))}
                </div>
                <div className="productsLeft flexColumn">
                    {productsLeft &&
                        productsLeft.map((product) => (
                            <ProductCardHome
                                sizeNum={product.sizeNum}
                                key={'gridProducts_productCard_' + product.barCode + product.name + product.id}
                                value={product}
                                setProductPopup={(flag) => props.setProductPopup(flag, product)}
                            />
                        ))}
                </div>
            </div>
        </div>
    )
}

export default TwoRoudsProducts
