import getLocalStorageController from 'controllers/localStorage/getLocalStorageController';

export default (store) => {
  let base = '';
  // const store = getLocalStorageController(process.env.REACT_APP_LS_STORE)
  if (store) {
    // let parts = store.name.split(' ')

    // console.log(parts)
    // parts.forEach((part) => {
    //     base += part
    // })
    base += store.name.replace(/\s+/g, '');
  }
  console.log(base);
  return base.toString();
};
