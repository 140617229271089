import removeLocalDataService from 'servises/local/cookies/removeCookieService';
import { stopAutoRefreshToken } from './autoRefreshToken';

let expiredTime = 1800000;
let expireCountdownInterval;
let isRun = false;

export const startExpireCountdown = new CustomEvent('buyWay:startExpireCountdown');
document.addEventListener('buyWay:startExpireCountdown', (e) => {
  if (!isRun) {
    isRun = true;
    expireCountdownInterval = setTimeout(() => {
      console.log('Expire, Login again!');
      removeLocalDataService();
      document.dispatchEvent(stopAutoRefreshToken);
    }, expiredTime);
  }
});

export const resetExpireCountdown = new CustomEvent('buyWay:resetExpireCountdown');
document.addEventListener('buyWay:resetExpireCountdown', (e) => {
  isRun = false;
  clearTimeout(expireCountdownInterval);
  document.dispatchEvent(startExpireCountdown);
});
