import { MdOutlineSendToMobile, MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'
import { useEffect, useState } from 'react'
import verificationConfirmPhoneController from 'controllers/verification/verificationConfirmPhoneController'
import verificationRequestPhoneController from 'controllers/verification/verificationRequestPhoneController'
import Title from 'utils/tools/title/Title'
import InputLable from 'utils/tools/inputLable/InputLable'
import Input from 'utils/tools/input/Input'
import IconBtn from 'utils/tools/iconBtn/IconBtn'
import Loading from 'utils/tools/loading/Loading'

const RegisterStapTwo = (props) => {
    const [isCanNext, setIsCanNext] = useState(false)
    const [confirmed, setConfirmed] = useState(false)
    const [sendCode, setSendCode] = useState(false)

    useEffect(() => {
        if (props.phone && props.email && props.phoneCode && String(props.phoneCode).length === 4 && confirmed) setIsCanNext(true)
        else setIsCanNext(false)
    }, [props.phone, props.email, props.phoneCode, confirmed])

    useEffect(() => {
        if (String(props.phoneCode).length === 4) checkHandler()
        else setConfirmed(false)
    }, [props.phoneCode])

    const checkHandler = async () => {
        let tmp = await verificationConfirmPhoneController(props.phone, props.phoneCode)
        tmp ? setConfirmed(true) : setConfirmed(false)
    }

    const sendCodeHandelr = async () => {
        setSendCode()
        let tmp = await verificationRequestPhoneController(props.phone)
        tmp === true ? setSendCode(true) : setSendCode(false)
    }

    return (
        <div className="step flexColumn">
            <Title text="הרשמה (2/3) - נתונים לאימות" />
            <InputLable text="מס' פלאפון">
                <Input id="phone" type="tel" pattern="[0-9]{3}-[0-9]{4}-[0-9]{3}" value={props.phone} setValue={props.setPhone} />
            </InputLable>
            <InputLable text="אימייל">
                <Input id="email" autoComplete="username" type="email" value={props.email} setValue={props.setEmail} />
            </InputLable>
            {sendCode === true && <IconBtn unclickable={true} text="הקוד נשלח" className="defaultColorSecendery" />}
            {sendCode === undefined && <Loading />}
            {sendCode === false && <IconBtn icon={MdOutlineSendToMobile} className="defaultColorSecendery" text="שלח קוד לפלאפון" onClick={sendCodeHandelr} />}
            {confirmed ? (
                <IconBtn unclickable={true} text="הקוד אושר" className="defaultColorSecendery" />
            ) : (
                <InputLable text="קוד מהפלאפון">
                    <Input id="phoneCode" type="number" max={9999} value={props.phoneCode} setValue={props.setPhoneCode} />
                </InputLable>
            )}
            {String(props.phoneCode).length === 4 && !confirmed && <Loading />}
            <div className="btns center">
                <IconBtn icon={MdKeyboardArrowLeft} className="halfDangerColor ltr" text="חזור" onClick={props.onBackHandler} />
                <IconBtn icon={MdKeyboardArrowRight} text="שלב הבא" onClick={props.onNextHandler} unclickable={!isCanNext} />
            </div>
        </div>
    )
}

export default RegisterStapTwo
