import { getCategoryByIdController, getProductsByCategoryIdController, getProductsController } from 'controllers/controllers';
import getLocalStorageController from 'controllers/localStorage/getLocalStorageController';
import getProductsBySearchController from 'controllers/products/getProductsBySearchController';
import getUserProductsController from 'controllers/products/getUserProductsController';
import { useRef } from 'react';
import { useEffect, useState } from 'react';
import CategoriesScroll from 'utils/layout/categoriesScroll/CategoriesScroll';
import FullPopup from 'utils/layout/fullPopup/FullPopup';
import GridProducts from 'utils/layout/gridProducts/GridProducts';
import ProductPopup from 'utils/layout/productPopup/ProductPopup';
import ProductsScroll from 'utils/layout/productsScroll/ProductScroll';
import TopCategoriesScroll from 'utils/layout/topCategoriesScroll/TopCategoriesScroll';
import IconBtn from 'utils/tools/iconBtn/IconBtn';
import Loading from 'utils/tools/loading/Loading';
import MapWrapper from 'utils/tools/mapWrapper/MapWrapper';
import NotLoggedInPopup from 'utils/tools/notLoggedInPopup/NotLoggedInPopup';
import StoreMapPopup from './storeMapPopup/StoreMapPopup';
import topCategories from '../../../../../data/topCategories.json';

let isLoad = false;
const HomeUserPage = (props) => {
    const homeUserPage = useRef(null);
    const [allProducts, setAllProducts] = useState(null);
    const [productPopupState, setProductPopupState] = useState(false);
    const [productPopup, setProductPopup] = useState(null);
    const [store, setStore] = useState(null);
    const [lastScrolled, setLastScrolled] = useState(0);
    const [isGoToTop, setIsGoToTop] = useState(false);

    const { inputSearch, setInputSearch } = props.searchInput;
    const { allCategories, currentCategory, setCurrentCategory, lastCategory, setLastCategory } = props.categoryArr;

    useEffect(() => {
        if (store) {
            storeCategoryHandler();
        }
    }, [store]);

    const storeCategoryHandler = async () => {
        if (!store.category.id) {
            let tmpCat = await getCategoryByIdController(store.category);
            setStore((pre) => {
                pre.category = tmpCat;
                console.log(pre);
                return { ...pre };
            });
        }
    };

    useEffect(() => {
        if (!props.isProducts && homeUserPage.current) {
            setLastScrolled(homeUserPage.current.scrollTop);
            goToPosition();
        } else {
            setLastScrolled(0);
            goToPosition(lastScrolled);
        }
    }, [props.isProducts]);

    const goToPosition = (y = 0, x = 0, element = homeUserPage.current) => {
        if (element) element.scrollTo(x, y);
    };

    useEffect(() => {
        if (allCategories) setCurrentCategory({ ...allCategories[0] });
    }, [inputSearch]);

    useEffect(() => {
        if (currentCategory && currentCategory.id) {
            loadProducts(1);
            // if (currentCategory.id !== 'ctgy_bIZqbE17701lqCW8770') setInputSearch('')
        }
    }, [currentCategory, lastCategory]);

    const loadProducts = async (pageNum) => {
        let tmpProducts;
        //only products
        if (inputSearch && inputSearch.length > 1) {
            tmpProducts = await getProductsBySearchController(inputSearch.toString(), pageNum);
        } else if (currentCategory && props.isProducts) tmpProducts = await getProductsByCategoryIdController(lastCategory ? lastCategory.id : currentCategory.id, pageNum);
        else tmpProducts = await getProductsController(pageNum);

        if (tmpProducts && tmpProducts.continuationHash) {
            let tmpUserProducts;
            let userLocation = await getLocalStorageController(process.env.REACT_APP_LS_USER_LOCATION);
            tmpUserProducts = await getUserProductsController(tmpProducts.continuationHash, userLocation, pageNum);
            tmpProducts.list.forEach((product, i) => {
                if (tmpUserProducts[i] && product.id === tmpUserProducts[i].productId) {
                    product.isSale = tmpUserProducts[i].isSale;
                    product.like = tmpUserProducts[i].like;
                    product.price = tmpUserProducts[i].price;
                    product.salePrice = tmpUserProducts[i].salePrice;
                }
            });

            if (pageNum === 1) setAllProducts(tmpProducts);
            else {
                setAllProducts((pre) => {
                    if (pre) {
                        pre.list = [...pre.list, ...tmpProducts.list];
                        pre.currentPage = tmpProducts.currentPage;
                        pre.continuationHash = tmpProducts.continuationHash;
                    } else pre = tmpProducts;

                    return { ...pre };
                });
            }
        } else setAllProducts(tmpProducts);

        if (tmpProducts && tmpProducts.currentPage < tmpProducts.totalPages) isLoad = false;
    };

    const productPopupHandler = (isShow, product) => {
        setProductPopupState(isShow);
        setProductPopup(product);
    };

    const onScroll = (e) => {
        if (e.scrollTop > 0) props.setIsScrolled(true);
        else props.setIsScrolled(false);

        if (e.scrollTop > 2000) setIsGoToTop(true);
        else setIsGoToTop(false);

        let offset = 2000;
        let loadPresent = e.scrollHeight - e.offsetHeight - offset;

        if (!isLoad && loadPresent < e.scrollTop) {
            isLoad = true;
            if (allProducts.currentPage < allProducts.totalPages) {
                console.log('Load!');
                loadProducts(allProducts.currentPage + 1);
            }
        }
    };

    return (
        <div ref={homeUserPage} className={!props.isProducts ? 'homeUserPage active' : 'homeUserPage'} onScroll={(e) => onScroll(e.target)}>
            {isGoToTop && <IconBtn className="backBtn toTop" classIcon="fi-rr-angle-up" onClick={goToPosition}></IconBtn>}
            {/* {lastScrolled > 0 && <IconBtn className="backBtn toScroll" classIcon="fi-rr-angle-up" classIconHover="fi-sr-angle-up"></IconBtn>} */}
            <div className={props.isScrolled ? 'hide' : 'marginTop'}></div>
            <CategoriesScroll isProducts={props.isProducts} categoryArr={props.categoryArr} />
            {allProducts ? (
                <div className={!props.isProducts ? 'homeContent active' : 'homeContent'}>
                    <div className="products">
                        {/* <Image src={'/assets/images/bgs/saleBg1.png'} /> */}
                        <ProductsScroll productType="home" title="מוצרים בשבילך" products={allProducts.list} setProducts={setAllProducts} setProductPopup={productPopupHandler} />
                        <TopCategoriesScroll title="קטגוריות פופולאריות" categories={topCategories} />
                        {/* <ProductsScroll productType="home" title="מבצעים והנחות" products={allProducts.list} setProducts={setAllProducts} setProductPopup={productPopupHandler} /> */}
                        <GridProducts productType="home" title="מוצרים פופולאריים" products={allProducts.list} setProducts={setAllProducts} setProductPopup={productPopupHandler} />
                        <FullPopup isShow={productPopupState} setIsShow={setProductPopupState}>
                            <ProductPopup product={productPopup} IsShow={productPopupState} setIsShow={setProductPopupState} />
                        </FullPopup>
                        <NotLoggedInPopup />
                    </div>
                    <div className="stores">
                        <MapWrapper onStoreClick={(store) => setStore(store)} allStores={true} />
                        {store && <StoreMapPopup store={store} setStore={setStore} />}
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default HomeUserPage;
