import Title from 'utils/tools/title/Title'

const VipCostumersPage = (props) => {
    return (
        <div className="vipCostumersPage storePage gridOne">
            <div className="bg"></div>
            <Title text="VIP" />
        </div>
    )
}

export default VipCostumersPage
