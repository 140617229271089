import refreshTokenService from 'servises/httpReqServices/auth/refreshTokenService';
import readLocalDataService from 'servises/local/cookies/readCookieService';
import updateLocalDataService from 'servises/local/cookies/updateCookieService';

export default async () => {
  const data = await readLocalDataService();

  const body = {
    refreshToken: data.refreshToken,
    grantType: 'refreshToken',
  };

  const result = await refreshTokenService(body);
  updateLocalDataService({ accessToken: result.data.accessToken });
};
