import getImageStore from 'controllers/images/getImageStore'
import { useNavigate } from 'react-router-dom'
import Btn from 'utils/tools/btn/Btn'
import IconBtn from 'utils/tools/iconBtn/IconBtn'
import Loading from 'utils/tools/loading/Loading'
import Rate from 'utils/tools/rate/Rate'

const StoreMapPopup = (props) => {
    const navigate = useNavigate()
    const todayHours = Object.values(props.store.regularHours)[new Date().getDay()]
    const isStoreOpen = () => {
        if (todayHours) {
            const hours = todayHours.split('-').join(':').split(':') //[startH, startM, endH, endM]
            const startHour = Number(hours[0]) * 60 + Number(hours[1])
            const endHour = Number(hours[2]) * 60 + Number(hours[3])

            const date = new Date()
            let hourToCheck = date.getHours() * 60 + date.getMinutes()
            return hourToCheck > startHour && hourToCheck < endHour
        } else return false
    }

    return (
        <div className="storeMapPopup homeUserPage">
            <IconBtn className="close" classIcon="fi-br-cross" onClick={() => props.setStore(null)} />
            <div className="details">
                <div className="left">
                    <IconBtn image={getImageStore(props.store.logo)} />
                    <div className="nameAndRate">
                        <span>{props.store.name}</span>
                        {Rate(props.store.averageStoreRating)}
                    </div>
                </div>
                {props.store.category && props.store.category.name ? (
                    <div className="right">
                        <div>
                            <span>חנות </span>
                            <span>{props.store.category.name} </span>
                            <span>{props.store.distance.toFixed(2)}</span>
                            <span> ק"מ</span>
                        </div>
                        {todayHours ? (
                            <div>
                                {isStoreOpen() ? (
                                    <span>
                                        <span className="open">פתוח </span>
                                        <span>נסגר ב</span>
                                        <span>{todayHours.split('-')[1]}</span>
                                    </span>
                                ) : (
                                    <span>
                                        <span className="close">סגורה</span>
                                    </span>
                                )}
                            </div>
                        ) : (
                            <div>לא ידוע אם פתוח או סגור</div>
                        )}
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
            <div className="imagesGrid">
                <IconBtn className="big" image={getImageStore(props.store.allImages[0])} />
                <IconBtn image={getImageStore(props.store.allImages[1])} />
                <IconBtn image={getImageStore(props.store.allImages[2])} />
            </div>
            <Btn onClick={() => navigate('/store/' + props.store.id)}>הכנס לחנות</Btn>
        </div>
    )
}

export default StoreMapPopup
