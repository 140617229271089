import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { useState } from 'react'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'
import Loading from '../loading/Loading'
import useOnclickOutside from 'react-cool-onclickoutside'
import { useEffect } from 'react'
import { geo2zip } from 'geo2zip'

const libraries = ['places']
const PlaceMap = (props) => {
    const className = props.className ? ' ' + props.className : ''
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        mapIds: [process.env.REACT_APP_GOOGLE_MAP_ID],
        language: 'HE',
        libraries: libraries,
    })
    const [map, setMap] = useState(/**@type window.google.maps.Map */ null)
    const [center, setCenter] = useState(props.center || { lat: 32.109333, lng: 34.855499 })
    const [zoom, setZoom] = useState(13)
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        if (selected) {
            setCenter(selected)
            setZoom(15)
        }
    }, [selected])

    return isLoaded ? (
        <div className={'tools placeMap' + className}>
            <div className="googleMap">
                <PlacesAutocomplete setSelected={setSelected} setPlace={props.setPlace} />
                <GoogleMap
                    onLoad={(map) => setMap(map)}
                    options={{
                        mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
                        disableDoubleClickZoom: true,
                        zoomControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        clickableIcons: false,
                        fullscreenControl: false,
                    }}
                    zoom={zoom}
                    center={center}
                    mapContainerClassName="map"
                >
                    {selected && <Marker position={selected} />}
                </GoogleMap>
            </div>
        </div>
    ) : (
        <Loading />
    )
}

const PlacesAutocomplete = (props) => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
        },
        debounce: 300,
    })
    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions()
    })

    const handleInput = (e) => {
        // Update the keyword of the input element
        setValue(e.target.value)
    }

    const handleSelect = (data) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(data.description, false)
        clearSuggestions()

        // Get latitude and longitude via utility functions
        getGeocode({ address: data.description }).then(async (results) => {
            const { lat, lng } = getLatLng(results[0])
            console.log('📍 Coordinates: ', { lat, lng })
            props.setSelected({ lat, lng })
            let tmp = {}
            tmp.name = data.description
            tmp.lat = lat
            tmp.lng = lng
            tmp.zip = (await geo2zip({ latitude: lat, longitude: lng }))[0]
            getGeocode({ location: { lat, lng } }).then((result) => {
                if (result[0]) {
                    console.log(result)

                    for (let i = 0; i < result.length; ++i) {
                        let element = result[i].formatted_address

                        if (result[i].types.includes('postal_code')) tmp.postal = element
                        if (result[i].types.includes('street_address')) tmp.street = element
                        if (result[i].types.includes('country')) tmp.country = element
                        if (result[i].types.includes('neighborhood')) tmp.neighborhood = element
                        if (result[i].types.includes('locality')) tmp.city = element
                        if (result[i].types.includes('administrative_area_level_1')) tmp.state = element
                    }
                } else {
                    window.alert('No results found')
                }
            })
            props.setPlace(tmp)
        })
    }

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion

            return (
                <div key={place_id} onClick={handleSelect(suggestion)} className="clickable">
                    <strong>{main_text}</strong> - <small>{secondary_text}</small>
                    <hr />
                </div>
            )
        })

    return (
        <div ref={ref} className="search">
            <input value={value} onChange={handleInput} disabled={!ready} placeholder="חפש מיקום" className="comboboxInput" />
            <hr />
            {data.length > 0 && <div className="suggestions">{status === 'OK' && <ul>{renderSuggestions()}</ul>}</div>}
        </div>
    )
}

export default PlaceMap
