import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'
import { useEffect, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import Title from 'utils/tools/title/Title'
import InputLable from 'utils/tools/inputLable/InputLable'
import Input from 'utils/tools/input/Input'
import IconBtn from 'utils/tools/iconBtn/IconBtn'

const RegisterStapThree = (props) => {
    const [passwordType, setPasswordType] = useState('password')
    const [isCanNext, setIsCanNext] = useState(false)
    useEffect(() => {
        if (props.username && props.password && props.password2 && props.password === props.password2) setIsCanNext(true)
        else setIsCanNext(false)
    }, [props.username, props.password, props.password2])

    return (
        <div className="step flexColumn">
            <Title text="הרשמה (3/3) - נתונים להתחברות" />
            <InputLable text="שם משתמש">
                <Input id="username" autoComplete="username" value={props.username} setValue={props.setUsername} />
            </InputLable>
            <InputLable text="סיסמה">
                <Input id="new-password" autoComplete="new-password" type={passwordType} value={props.password} setValue={props.setPassword} />
            </InputLable>
            <InputLable text="אימות סיסמה">
                <Input id="new-password2" type={passwordType} value={props.password2} setValue={props.setPassword2} />
            </InputLable>
            <IconBtn
                title="הצג סיסמה"
                className="defaultColorSecendery"
                onClick={() => (passwordType === 'password' ? setPasswordType('text') : setPasswordType('password'))}
                icon={passwordType === 'password' ? FaEye : FaEyeSlash}
            />
            <div className="btns center">
                <IconBtn icon={MdKeyboardArrowLeft} className="halfDangerColor ltr" text="חזור" onClick={props.onBackHandler} />
                <IconBtn icon={MdKeyboardArrowRight} type="submit" text="הרשם כמנוי" onClick={props.onNextHandler} unclickable={!isCanNext} />
            </div>
        </div>
    )
}

export default RegisterStapThree
