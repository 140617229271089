import getProductsSearchService from 'servises/httpReqServices/products/getProductsSearchService'

export default async (input, page) => {
    const body = {
        fields: {
            companyName: input,
        },
        page: page,
    }
    const result = await getProductsSearchService(body)
    return result.data
}
