import { startAutoRefreshToken } from 'events/autoRefreshToken'
import { startExpireCountdown } from 'events/expireCountdown'
import { loginController } from 'controllers/controllers'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import RegisterStapOne from './steps/RegisterStapOne'
import RegisterStapTwo from './steps/RegisterStapTwo'
import RegisterStapThree from './steps/RegisterStapThree'
import registerUserController from 'controllers/users/registerUserController'
import Input from 'utils/tools/input/Input'
import IconBtn from 'utils/tools/iconBtn/IconBtn'

const RegisterPage = (props) => {
    const [fName, setFName] = useState('')
    const [lName, setLName] = useState('')
    const [gender, setGender] = useState(0)
    const [birthDate, setBirthDate] = useState('')

    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [phoneCode, setPhoneCode] = useState('')

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')

    const [step, setStep] = useState('stepOne')
    const navigate = useNavigate()

    const submit = async () => {
        const tmpObj = {
            username: username,
            password: password,
            displayName: fName + ' ' + lName,
            firstname: fName,
            lastname: lName,
            email: email,
            description: '',
            type: 'user',
            phone: phone,
            birthDate: birthDate || '',
            gender: Number(gender),
            phoneCode: phoneCode,
        }
        let register = await registerUserController(tmpObj)
        if (register) await onLogginHandler()
    }

    const onLogginHandler = async () => {
        if (username && password) {
            const result = await loginController(username, password)
            if (result) {
                window.dispatchEvent(startAutoRefreshToken)
                window.dispatchEvent(startExpireCountdown)
                navigate(`../home`)
            } else console.log("can't logged in!")
        } else console.log('fill username and password')
    }

    return (
        <div className="registerPage flexColumn">
            <img title="חזור לדף הבית" className="img clickable" src="/assets/images/logo.svg" onClick={() => navigate('../home')} />
            <form className="fields center flexColumn" autoComplete="on">
                <div className={'steps flex ' + step}>
                    <RegisterStapOne
                        fName={fName}
                        setFName={setFName}
                        lName={lName}
                        setLName={setLName}
                        gender={gender}
                        setGender={setGender}
                        birthDate={birthDate}
                        setBirthDate={setBirthDate}
                        onNextHandler={() => setStep('stepTwo')}
                    />
                    <RegisterStapTwo
                        phone={phone}
                        setPhone={setPhone}
                        email={email}
                        setEmail={setEmail}
                        phoneCode={phoneCode}
                        setPhoneCode={setPhoneCode}
                        onBackHandler={() => setStep('stepOne')}
                        onNextHandler={() => setStep('stepThree')}
                    />
                    <RegisterStapThree
                        username={username}
                        setUsername={setUsername}
                        password={password}
                        setPassword={setPassword}
                        password2={password2}
                        setPassword2={setPassword2}
                        onBackHandler={() => setStep('stepTwo')}
                        onNextHandler={submit}
                    />
                </div>
                <Input id="description" value={null} type="hidden" />
                <Input id="type" value="user" type="hidden" />
                <Input id="displayName" value={fName + ' ' + lName} type="hidden" />
                <div onClick={() => navigate('../login')} className="clickable">
                    רשום כבר? התחבר עכשיו
                </div>
            </form>
            <div className="footer">
                <IconBtn classIcon="fi-rr-angle-small-left" classIconHover="fi-rr-arrow-small-left" className="backBtn" text="חזור לדף הראשי" onClick={() => navigate('../home')} />
            </div>
        </div>
    )
}

export default RegisterPage
