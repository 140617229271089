import { useEffect, useState } from 'react'

const Image = (props) => {
    const [className, setClassName] = useState(props.className || '')

    useEffect(() => {
        if (!props.src && props.validate && !props.notRequired)
            if (props.className) setClassName(props.className + ' outLineDangerColor')
            else setClassName('outLineDangerColor')
        else if (props.validate) {
            if (props.className) setClassName(props.className + ' outLineSafeColor')
            else setClassName('outLineSafeColor')
        } else setClassName(props.className || '')
    }, [props.src])

    return <img className={'tools image ' + className} src={props.src || '/assets/images/logo.svg'} />
}

export default Image
