import { getCategoriesController, getCategoryByIdController } from 'controllers/controllers'
import getLocalStorageController from 'controllers/localStorage/getLocalStorageController'
import setLocalStorageController from 'controllers/localStorage/setLocalStorageController'
import updateLocalStorageController from 'controllers/localStorage/updateLocalStorageController'
import { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom'
import CartPage from './userPages/cart/CartPage'
import LikesPage from './userPages/likes/LikesPage'
import MessagesPage from './userPages/messages/MessagesPage'
import ProfilePage from './userPages/profile/ProfilePage'
import ReservationsPage from './userPages/reservations/ReservationsPage'
import StorePage from '../allProfilePages/store/StorePage'
import LoginPage from '../allProfilePages/login/LoginPage'
import RegisterPage from '../allProfilePages/register/RegisterPage'
import NDPRegister from '../allProfilePages/pagesNoDesign/register/NDPRegister'
import NDPStore from '../allProfilePages/pagesNoDesign/store/NDPStore'
import ResetPasswordPage from '../allProfilePages/resetPassword/ResetPasswordPage'
import SideBarUser from './layout/sideBarUser/SideBarUser'
import HomeUserPage from './userPages/homeUser/HomeUserPage'
import Page from 'utils/layout/page/Page'
import MapWrapper from 'utils/tools/mapWrapper/MapWrapper'
import TopBarUser from './layout/topBarUser/TopBarUser'
import CreateNewOrgPage from '../allProfilePages/createNew/CreateNewOrgPage'
import CreateNewStorePage from '../allProfilePages/createNew/CreateNewStorePage'

const UserProfile = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [isScrolled, setIsScrolled] = useState(false)
    const [allCategories, setAllCategories] = useState(null)
    const [lastCategory, setLastCategory] = useState(null)
    const [currentCategory, setCurrentCategory] = useState(null)
    const [lastCatStore, setLastCatStore] = useState(null)
    const [lastCatProduct, setLastCatProduct] = useState(null)
    const [isProducts, setIsProducts] = useState(true)
    const [inputSearch, setInputSearch] = useState('')

    const searchInput = { inputSearch, setInputSearch }
    const categoryArr = { allCategories, currentCategory, setCurrentCategory, lastCategory, setLastCategory }

    useEffect(() => {
        getCategories()
        checkCarts()
        loadLocation()
    }, [])

    useEffect(() => {
        if (allCategories) loadSpecificCategory()
    }, [allCategories, isProducts, searchParams])

    const loadSpecificCategory = async () => {
        let tmpLast = null
        let tmpCurrent = null
        let catId = searchParams.get('ctgy')

        if (catId) {
            let cat = await getCategoryByIdController(catId)
            if (cat) {
                if (isProducts) {
                    if (cat.id !== allCategories[0].id && !cat.parentIds.includes(allCategories[0].id)) {
                        tmpCurrent = lastCatProduct ? lastCatProduct : allCategories[0]
                        if (tmpCurrent.sonsImmediate.length === 0) {
                            tmpLast = tmpCurrent
                            tmpCurrent = await getCategoryByIdController(tmpCurrent.parentId)
                        }
                        setLastCatStore(cat)
                    } else if (cat.sonsImmediate.length === 0) {
                        tmpLast = cat
                        tmpCurrent = await getCategoryByIdController(cat.parentId)
                    } else {
                        tmpCurrent = cat
                    }
                } else {
                    if (cat.id !== allCategories[1].id && !cat.parentIds.includes(allCategories[1].id)) {
                        tmpCurrent = lastCatStore ? lastCatStore : allCategories[1]
                        if (tmpCurrent.sonsImmediate.length === 0) {
                            tmpLast = tmpCurrent
                            tmpCurrent = await getCategoryByIdController(tmpCurrent.parentId)
                        }
                        setLastCatProduct(cat)
                    } else if (cat.sonsImmediate.length === 0) {
                        tmpLast = cat
                        tmpCurrent = await getCategoryByIdController(cat.parentId)
                    } else {
                        tmpCurrent = cat
                    }
                }
            }
        }

        if (!tmpCurrent) {
            if (isProducts) tmpCurrent = allCategories[0]
            else tmpCurrent = allCategories[1]
        }

        setSearchParams({ ctgy: tmpLast ? tmpLast.id : tmpCurrent.id })
        setCurrentCategory(tmpCurrent)
        setLastCategory(tmpLast)
    }

    const getCategories = async () => {
        let tmpCategories = await getLocalStorageController(process.env.REACT_APP_LS_LOCAL_CATEGORIES)
        if (!tmpCategories) {
            await updateLocalStorageController(process.env.REACT_APP_LS_LOCAL_CATEGORIES, await getCategoriesController())
            getCategories()
        } else {
            setAllCategories(tmpCategories)
            let cat = await getCategoryByIdController(searchParams.get('ctgy'))
            setIsProducts(cat.id === tmpCategories[0].id || cat.parentIds.includes(tmpCategories[0].id))
        }
    }

    const checkCarts = async () => {
        let tmpAllCart = await getLocalStorageController(process.env.REACT_APP_LS_ALL_CARTS)
        if (tmpAllCart === null) updateLocalStorageController(process.env.REACT_APP_LS_ALL_CARTS, {})
        let tmpLocalCart = await getLocalStorageController(process.env.REACT_APP_LS_CURRENT_CART)
        if (tmpLocalCart === null) updateLocalStorageController(process.env.REACT_APP_LS_CURRENT_CART, {})
    }

    const loadLocation = async () => {
        let pos
        navigator.geolocation.getCurrentPosition((position) => {
            pos = {
                lat: position.coords.latitude || 32.109333,
                lng: position.coords.longitude || 34.855499,
            }
        })
        if (!pos) pos = { lat: 32.109333, lng: 34.855499 }
        await setLocalStorageController(process.env.REACT_APP_LS_USER_LOCATION, pos)
    }

    return (
        <Routes>
            <Route path={'login'} element={<LoginPage />} />
            <Route path={'register'} element={<RegisterPage />} />
            <Route path={'resetPassword'} element={<ResetPasswordPage />} />
            <Route path={'createNewStore'} element={<CreateNewStorePage />} />
            <Route path={'createNewOrg'} element={<CreateNewOrgPage />} />

            <Route path={'NDPRegister'} element={<NDPRegister />} />
            <Route path={'NDPStore/:fName/:lName/:phone'} element={<NDPStore />} />
            <Route path={'NDPStore'} element={<NDPStore />} />
            <Route path={'store/:id'} element={<StorePage categoryArr={categoryArr} />} />

            <Route
                path={'*'}
                element={
                    <div className="userProfile">
                        <TopBarUser isProducts={isProducts} setIsProducts={setIsProducts} isScrolled={isScrolled} setIsScrolled={setIsScrolled} />
                        <div className="pageWrapper flex">
                            <SideBarUser />
                            <Page>
                                <div className="content">
                                    <Routes>
                                        <Route path={'home'} element={<HomeUserPage categoryArr={categoryArr} isProducts={isProducts} searchInput={searchInput} isScrolled={isScrolled} setIsScrolled={setIsScrolled} />} />
                                        <Route path={'profile'} element={<ProfilePage />} />
                                        <Route path={'messages'} element={<MessagesPage />} />
                                        <Route path={'reservations'} element={<ReservationsPage />} />
                                        <Route path={'likes'} element={<LikesPage />} />
                                        <Route path={'cart'} element={<CartPage />} />
                                        <Route path={'map'} element={<MapWrapper />} />

                                        <Route path={'*'} element={<Navigate to={'home'} />} />
                                    </Routes>
                                </div>
                                {/* <SideCart className={cartClassName} onClick={CartClassNameHandler} /> */}
                            </Page>
                        </div>
                    </div>
                }
            />
        </Routes>
    )
}

export default UserProfile
