import { DirectionsRenderer, GoogleMap, InfoWindowF, MarkerF, PolylineF, useJsApiLoader } from '@react-google-maps/api'
import getSaillingStoresByProductController from 'controllers/stores/getSaillingStoresByProductController'
import { useEffect, useRef, useState } from 'react'
import IconBtn from '../iconBtn/IconBtn'
import Loading from '../loading/Loading'
import { MdDriveEta, MdDirectionsBusFilled, MdOutlineStoreMallDirectory, MdAccessTime } from 'react-icons/md'
import { BsCursorFill } from 'react-icons/bs'
import { FaWalking, FaBicycle } from 'react-icons/fa'
import Scroll from '../scroll/Scroll'
import StoreCard from '../storeCard/StoreCard'
import setLocalStorageController from 'controllers/localStorage/setLocalStorageController'
import getImageStore from 'controllers/images/getImageStore'
import getAllStoresController from 'controllers/stores/getAllStoresController'

const libraries = ['places']
const MapWrapper = (props) => {
    const className = props.className ? ' ' + props.className : ''
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        mapIds: [process.env.REACT_APP_GOOGLE_MAP_ID],
        language: 'HE',
        libraries: libraries,
    })
    const [map, setMap] = useState(/**@type window.google.maps.Map */ null)
    const [myPostion, setMyPostion] = useState(props.center || { lat: 32.109333, lng: 34.855499 })
    const [center, setCenter] = useState(props.center || { lat: 32.109333, lng: 34.855499 })
    const [zoom, setZoom] = useState(13)
    const [stores, setStores] = useState([])
    const googleMapRef = useRef()
    const scrollRef = useRef()
    const [direction, setDirection] = useState()
    const [directionDistance, setDirectionDistance] = useState()
    const [directionDuration, setdirectionDuration] = useState()
    const [currentStoreRoute, setCurrentStoreRoute] = useState()

    const [isMyPosition, setIsMyPosition] = useState(false)
    const [isStoreBarClose, setIsStoreBarClose] = useState(props.isStoreBarClose || false)
    const [closeClass, setCloseClass] = useState('')
    const [travelMode, setTravelMode] = useState()

    useEffect(() => {
        if (navigator.geolocation) {
            loadLocation()
        }
    }, [])

    useEffect(() => {
        if (isStoreBarClose) setCloseClass(' close')
        else setCloseClass('')
    }, [isStoreBarClose])

    useEffect(() => {
        if (map) {
            loadMarkers()
        }
    }, [map])

    const loadLocation = async () => {
        let pos
        navigator.geolocation.getCurrentPosition((position) => {
            pos = {
                lat: position.coords.latitude || 32.109333,
                lng: position.coords.longitude || 34.855499,
            }
            setMyPostion(pos)
            setCenter(pos)
            setZoom(15)
        })
        if (!pos) pos = myPostion
        await setLocalStorageController(process.env.REACT_APP_LS_USER_LOCATION, pos)
    }

    const loadMarkers = async () => {
        let tmpStores
        if (props.product) tmpStores = await getSaillingStoresByProductController(props.product.id)
        else if (props.allStores) tmpStores = await getAllStoresController()

        if (tmpStores) {
            let icon = {
                url: 'assets/images/logos/store.png',
                scaledSize: new window.google.maps.Size(90 * zoom, 42 + zoom),
            }
            console.log(tmpStores)
            tmpStores.list.forEach((store) => {
                if (store.location) {
                    store.position = { lat: store.location.latitude, lng: store.location.longitude }
                    store.distance = haversine_distance(myPostion, store.position)
                }
                store.icon = icon
                store.images = store.image ? store.image.map((image) => getImageStore(image)) : ['assets/images/products/0.jpg']

                if (!store.name) store.name = 'לחנות זו אין שם'

                delete store.image
                delete store.location
            })
            if (tmpStores.list && tmpStores.list.length > 0) {
                tmpStores.list.sort((a, b) => a.distance - b.distance)
                setStores(tmpStores.list)
                setCurrentStoreRoute(tmpStores.list[0])
                if (props.setOutStore) props.setOutStore(tmpStores.list[0])
            }
        }
    }

    const onResizeHandler = () => {
        if (googleMapRef.current && googleMapRef.current.state.map) {
            setZoom(googleMapRef.current.state.map.zoom)
        }
    }

    const onMoveMap = () => {
        setCenter()
        setIsMyPosition()
    }

    useEffect(() => {
        if (travelMode && currentStoreRoute) {
            loadStoreRoute(currentStoreRoute)
        }
    }, [travelMode])

    const onBarStoreClick = async (store) => {
        if (!travelMode) {
            setTravelMode(window.google.maps.TravelMode.DRIVING)
        } else {
            loadStoreRoute(store)
        }
        setCurrentStoreRoute(store)
    }

    const loadStoreRoute = async (store) => {
        setCenter({ ...(store.position || store) })

        if (myPostion) {
            const directionService = new window.google.maps.DirectionsService()

            const result = await directionService.route({
                origin: myPostion,
                destination: store.position || store,
                travelMode: travelMode || window.google.maps.TravelMode.DRIVING,
            })
            setDirection(result)
            setDirectionDistance(result.routes[0].legs[0].distance.text)
            setdirectionDuration(result.routes[0].legs[0].duration.text)

            console.log(result.routes[0].legs[0].distance.text)
            console.log(result.routes[0].legs[0].duration.text)
        }
    }

    const haversine_distance = (p1, p2) => {
        const R = 6371.071 // Radius of the Earth in km
        const rlat1 = p1.lat * (Math.PI / 180) // Convert degrees to radians
        const rlat2 = p2.lat * (Math.PI / 180) // Convert degrees to radians
        const difflat = rlat2 - rlat1 // Radian difference (latitudes)
        const difflon = (p2.lng - p1.lng) * (Math.PI / 180) // Radian difference (longitudes)

        const d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)))
        return d
    }

    const returnToMyLocation = () => {
        setIsMyPosition(true)
        if (myPostion) {
            setCenter(() => {
                return { ...myPostion }
            })
            setZoom(() => {
                let num = 15
                return num
            })
        }
    }

    const onIconClickHandler = (mode) => {
        if (travelMode === mode) {
            loadStoreRoute(myPostion)
            setTravelMode()
        } else {
            setTravelMode(mode)
        }
    }

    if (!isLoaded) return <Loading />

    return props.product || props.store || props.allStores ? (
        <div className={'tools mapWrapper gridOne' + className} key={(props.product && props.product.id) || (props.store && props.store.id) || '' + Date.now}>
            <div className="googleMap">
                <GoogleMap
                    ref={googleMapRef}
                    onLoad={(map) => setMap(map)}
                    onZoomChanged={onResizeHandler}
                    onDrag={onMoveMap}
                    options={{
                        mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
                        disableDoubleClickZoom: true,
                        zoomControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        clickableIcons: false,
                        fullscreenControl: false,
                    }}
                    zoom={zoom}
                    center={center}
                    mapContainerClassName="map"
                >
                    {myPostion && (
                        <InfoWindowF key={'myLocation'} position={myPostion} options={{ pixelOffset: { width: -27, height: -58 } }}>
                            <div className="storeInfo me">
                                <div className="storeLogo center">אתה כאן!</div>
                            </div>
                        </InfoWindowF>
                    )}
                    <div className="info">
                        {stores.map(
                            (store) =>
                                store.position && (
                                    <InfoWindowF key={'marker' + store.id} position={store.position} options={{ pixelOffset: { width: -28, height: -56 }, disableAutoPan: true }}>
                                        <div className="storeInfo">
                                            <img
                                                onClick={() => (props.onStoreClick ? props.onStoreClick(store) : {})}
                                                src={getImageStore(store.logo)}
                                                className={props.onStoreClick ? 'clickable storeLogo' : 'storeLogo'}
                                            />
                                        </div>
                                    </InfoWindowF>
                                )
                        )}
                    </div>
                    <DirectionsRenderer directions={direction} />
                </GoogleMap>
                <div className={'icons flex' + closeClass}>
                    <IconBtn className={!isStoreBarClose ? 'dropDwon active' : 'dropDwon'} title="רשימת חנויות" icon={MdOutlineStoreMallDirectory} onClick={() => setIsStoreBarClose((x) => !x)} />
                    <IconBtn className={isMyPosition && 'active'} title="חזור למיקומך" icon={BsCursorFill} onClick={returnToMyLocation} />
                    <IconBtn
                        className={travelMode === window.google.maps.TravelMode.DRIVING && 'active'}
                        title="ניתוב נסיעה"
                        icon={MdDriveEta}
                        onClick={() => onIconClickHandler(window.google.maps.TravelMode.DRIVING, MdDriveEta)}
                    />
                    <IconBtn
                        className={travelMode === window.google.maps.TravelMode.WALKING && 'active'}
                        title="ניתוב הליכה"
                        icon={FaWalking}
                        onClick={() => onIconClickHandler(window.google.maps.TravelMode.WALKING, FaWalking)}
                    />
                    <IconBtn
                        className={travelMode === window.google.maps.TravelMode.BICYCLING && 'active'}
                        title="ניתוב אופניים"
                        icon={FaBicycle}
                        onClick={() => onIconClickHandler(window.google.maps.TravelMode.BICYCLING, FaBicycle)}
                    />
                    {/* <IconBtn
                    className={travelMode === window.google.maps.TravelMode.TRANSIT && 'active'}
                    title="ניתוב תחבורה ציבורית"
                    icon={MdDirectionsBusFilled}
                    onClick={() => onIconClickHandler(window.google.maps.TravelMode.TRANSIT)}
                /> */}

                    {directionDuration && directionDistance && (
                        <div className="ditails center">
                            <IconBtn
                                className={'directionDistance'}
                                title="מרחק עד החנות"
                                icon={
                                    travelMode === window.google.maps.TravelMode.DRIVING
                                        ? MdDriveEta
                                        : travelMode === window.google.maps.TravelMode.WALKING
                                        ? FaWalking
                                        : travelMode === window.google.maps.TravelMode.BICYCLING && FaBicycle
                                }
                                text={directionDistance}
                                onClick={() => {}}
                                unclickable={true}
                            />
                            |
                            <IconBtn className={'directionDuration'} title="זמן עד החנות" icon={MdAccessTime} text={directionDuration} onClick={() => {}} unclickable={true} />
                        </div>
                    )}
                </div>
            </div>
            <Scroll className={'downStores' + closeClass} ref={scrollRef} key={'scroll' + Date.now}>
                {myPostion && stores && stores.map((store) => <StoreCard key={'storeCard' + store.id} store={store} onClick={onBarStoreClick} />)}
            </Scroll>
        </div>
    ) : (
        <Loading />
    )
}

export default MapWrapper
