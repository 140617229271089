import { refreshTokenController } from 'controllers/controllers';

let refreshTime = 3000000;
let autoRefreshTokenInterval;

export const startAutoRefreshToken = new CustomEvent('buyWay:startAutoRefreshToken');
document.addEventListener('buyWay:startAutoRefreshToken', (e) => {
  autoRefreshTokenInterval = setInterval(() => {
    refreshTokenController();
  }, refreshTime);
});

export const stopAutoRefreshToken = new CustomEvent('buyWay:stopAutoRefreshToken');
document.addEventListener('buyWay:stopAutoRefreshToken', (e) => {
  clearInterval(autoRefreshTokenInterval);
});
