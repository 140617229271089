const Select = (props) => {
    const className = props.className || ''
    
    return (
        <select required value={props.value || -1} onChange={(e) => props.setValue(e.target.value)} className={'tools select ' + className}>
            {props.children}
        </select>
    )
}

export default Select
