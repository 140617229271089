import Title from 'utils/tools/title/Title'

const InOutPage = (props) => {
    return (
        <div className="inOutPage storePage gridOne">
            <div className="bg"></div>
            <Title text="הוצאות/הכנסות" />
        </div>
    )
}

export default InOutPage
