const FileWrapper = (props) => {
    const className = props.className ? ' ' + props.className : ''
    return (
        <label className={'layout fileWrapper clickable' + className}>
            <input type="file" />
            {props.children}
        </label>
    )
}

export default FileWrapper
