import checkUserController from 'controllers/auth/checkUserController'
import { getOrginazationController } from 'controllers/controllers'
import readCookieController from 'controllers/cookies/readCookieController'
import removeCookieController from 'controllers/cookies/removeCookieController'
import getSessionStorageController from 'controllers/sessionStorage/getSessionStorageController'
import removeSessionStorageController from 'controllers/sessionStorage/removeSessionStorageController'
import setSessionStorageController from 'controllers/sessionStorage/setSessionStorageController'
import updateSessionStorageController from 'controllers/sessionStorage/updateSessionStorageController'
import getStoreByOrgIdController from 'controllers/stores/getStoreByOrgIdController'
import getStoreByStoreIdController from 'controllers/stores/getStoreByStoreIdController'
import { useCallback, useState } from 'react'
import { useEffect } from 'react'
import { BiLogOut } from 'react-icons/bi'
import { BsPlusCircle } from 'react-icons/bs'
import { VscChevronDown } from 'react-icons/vsc'
import { useNavigate } from 'react-router-dom'
import FullPopup from 'utils/layout/fullPopup/FullPopup'
import IconBtn from '../iconBtn/IconBtn'

const ProfileBtn = (props) => {
    const [orgs, setOrgs] = useState()
    const [stores, setStores] = useState()
    const [user, setUser] = useState(null)
    const [isShowPopup, setIsShowPopup] = useState(false)
    const [isStoreMode, setIsStoreMode] = useState(true)

    const [orgSelectedInPopup, setOrgSelectedInPopup] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        checkUserHandler()
        checkStoreHandler()
    }, [])

    useEffect(() => {
        if (!isShowPopup && orgs) {
            setOrgSelectedInPopup(null)
            setStores()
        }
    }, [isShowPopup])

    const changeToStoreModeHandler = async (value) => {
        let store = await getStoreByStoreIdController(value.id)
        await updateSessionStorageController(process.env.REACT_APP_SS_ORG, value)
        await updateSessionStorageController(process.env.REACT_APP_SS_STORE, store)
    }

    const changeToUserModeHandler = async () => {
        await removeSessionStorageController(process.env.REACT_APP_SS_ORG)
        await removeSessionStorageController(process.env.REACT_APP_SS_STORE)
    }
    const checkUserHandler = async () => {
        const user = await checkUserController(process.env.REACT_APP_COOKIES_AUTH)
        if (user) setUser(user)
        else setUser()
    }

    const checkStoreHandler = async () => {
        let tmpStore = getSessionStorageController(process.env.REACT_APP_SS_STORE)
        tmpStore ? setIsStoreMode(true) : setIsStoreMode(false)
    }

    const switchToStoreModeHandler = async () => {
        let tmp = (await readCookieController(process.env.REACT_APP_COOKIES_AUTH)).user.organizations
        let arr = []
        if (tmp) {
            for (const [key, value] of Object.entries(tmp)) value.roles.includes('dev') && arr.push(await getOrginazationController(key))
            // arr.length > 0 && updateCookieController(process.env.REACT_APP_COOKIES_USER_ORGS, arr)
        }

        setIsShowPopup(true)
        setOrgs(arr)
    }

    const onOrgClickHandler = async (org) => {
        if (org === orgSelectedInPopup) {
            setOrgSelectedInPopup(null)
            setStores()
        } else {
            setOrgSelectedInPopup(org)
            await setSessionStorageController(process.env.REACT_APP_SS_ORG, org)
            let tmp = await getStoreByOrgIdController(org.id)
            setStores(tmp || [])
        }
    }

    const logoutHandler = () => {
        removeCookieController(process.env.REACT_APP_COOKIES_AUTH)
        setSessionStorageController(process.env.REACT_APP_SS_STORE, null)
        setUser()
        navigate('/home', { replace: true })
    }

    return user ? (
        <div className="profileBtn center" tabIndex="0">
            <img src={user.image || '/assets/images/defaults/user.png'} className="profileImg clickable" />
            <span>{user.username || user.displayName}</span>
            <VscChevronDown className="dropicon clickable" />

            <div className="menuWrapper">
                <div className="menu flexColumn">
                    {!isStoreMode && <IconBtn text="החלף לפרופיל חנות" onMouseDown={switchToStoreModeHandler} onClick={switchToStoreModeHandler} />}
                    {isStoreMode && <IconBtn text="החלף לפרופיל משתמש" onMouseDown={changeToUserModeHandler} />}
                    <IconBtn text="התנתק" icon={BiLogOut} onMouseDown={() => logoutHandler()} />
                </div>
            </div>

            <FullPopup isShow={isShowPopup} setIsShow={setIsShowPopup}>
                {orgs ? (
                    <div className="wrapper center flexColumn">
                        <span className="popupTitle">{orgSelectedInPopup ? 'בחר סניף' : 'בחר ארגון'}</span>
                        {orgs.length > 0 && !orgSelectedInPopup && (
                            <div className="linesWrapper flexColumn center">
                                <span className="alongLine"></span>
                                <span className="acrossLine"></span>
                            </div>
                        )}
                        {orgSelectedInPopup && stores ? (
                            <div className="center flexColumn">
                                <IconBtn className="org" text={orgSelectedInPopup.displayName} onClick={() => onOrgClickHandler(orgSelectedInPopup)} />
                                {stores.length > 0 && (
                                    <div className="linesWrapper flexColumn center">
                                        <span className="alongLine"></span>
                                        <span className="acrossLine"></span>
                                    </div>
                                )}
                                <div className="stores center">
                                    {stores.map((c) => (
                                        <IconBtn className="org" text={c.name} onClick={() => changeToStoreModeHandler(c)} />
                                    ))}
                                    <IconBtn className="org" icon={BsPlusCircle} onClick={() => navigate('../createNewStore')} />
                                </div>
                            </div>
                        ) : (
                            <div className="orgs center">
                                {orgs.map((org) => (
                                    <IconBtn className="org" text={org.displayName} onClick={() => onOrgClickHandler(org)} />
                                ))}
                                <IconBtn className="org" icon={BsPlusCircle} onClick={() => navigate('../createNewOrg')} />
                            </div>
                        )}
                        <div className="btns flex">
                            <IconBtn className="halfDangerColor" text="סגור" onClick={() => setIsShowPopup(false)} />
                        </div>
                    </div>
                ) : (
                    // <div className="center flexColumn">
                    //     <span>אינך מקושר לאף חנות</span>
                    //     <div className="flex noStoreBtns">
                    //         <IconBtn className="safeColor" text="יצירת חנות" onClick={() => navigate('../register')} />
                    //         <IconBtn className="halfDangerColor" text="סגור" onClick={() => setIsShowPopup(false)} />
                    //     </div>
                    // </div>
                    //     )
                    <div className="center flexColumn">
                        <span>אינך מקושר לאף ארגון</span>
                        <div className="flex noStoreBtns">
                            <IconBtn className="safeColor" text="יצירת חנות" onClick={() => navigate('../createOrganization')} />
                            <IconBtn className="halfDangerColor" text="סגור" onClick={() => setIsShowPopup(false)} />
                        </div>
                    </div>
                )}
            </FullPopup>
        </div>
    ) : (
        <IconBtn onClick={() => navigate('../login')} className="connect" text="התחברות" />
    )
}

export default ProfileBtn
