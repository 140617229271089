import { forwardRef, useEffect, useState } from 'react'

const Scroll = forwardRef((props, ref) => {
    const className = props.className ? props.className : ''
    const [isLeft, setIsLeft] = useState(true)
    const [isRight, setIsRight] = useState(false)

    useEffect(() => {
        if (isLeft === undefined || isRight === undefined) return
        if (props.onLeft) props.onLeft(isLeft)
        if (props.onRight) props.onRight(isRight)
    }, [isLeft, isRight])

    useEffect(() => {
        if (props.go > 0 && ref.current !== undefined) {
            ref.current.scrollLeft = 0
        }
    }, [props.go])

    const onScrollHandler = () => {
        if (ref.current === undefined) return
        ref.current.scrollLeft === 0 ? setIsRight(false) : setIsRight(true)
        ref.current.scrollWidth + ref.current.scrollLeft === ref.current.clientWidth ? setIsLeft(false) : setIsLeft(true)
    }

    let pos = { top: 0, left: 0, x: 0, y: 0 }
    const mouseDownHandler = function (e) {
        pos = {
            // The current scroll
            left: ref.current.scrollLeft,
            top: ref.current.scrollTop,
            // Get the current mouse position
            x: e.clientX,
            y: e.clientY,
        }

        document.addEventListener('mousemove', mouseMoveHandler)
        document.addEventListener('mouseup', mouseUpHandler)
    }

    const mouseMoveHandler = function (e) {
        // Change the cursor and prevent user from selecting the text
        ref.current.style.cursor = 'grabbing'
        ref.current.style.userSelect = 'none'
        // How far the mouse has been moved
        const dx = e.clientX - pos.x
        const dy = e.clientY - pos.y

        // Scroll the element
        
        ref.current.scrollTop = pos.top - dy
        ref.current.scrollLeft = pos.left - dx
        
    }

    const mouseUpHandler = function () {
        document.removeEventListener('mousemove', mouseMoveHandler)
        document.removeEventListener('mouseup', mouseUpHandler)

        ref.current.style.cursor = 'grab'
        ref.current.style.removeProperty('user-select')
    }

    return (
        <div className={'tools scroll flexScroll ' + className} onScroll={onScrollHandler} ref={ref} onMouseDown={mouseDownHandler}>
            {props.children}
        </div>
    )
})

export default Scroll
