import getImageIcon from 'controllers/images/getImageIcon'
import { useEffect } from 'react'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import Image from 'utils/tools/image/Image'

const CategoryCard = (props) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const className = props.className ? ' ' + props.className : ''

    const onClickHandler = () => {
        navigate({ pathname: '', search: createSearchParams({ ctgy: props.value.id === searchParams.get('ctgy') ? props.value.parentId : props.value.id }).toString() })
        props.setValue(props.value)
    }

    return (
        <div className={props.value.id === searchParams.get('ctgy') ? 'categoryCard flexColumn clickable selected' + className : 'categoryCard flexColumn clickable' + className} onClick={onClickHandler}>
            <div className="imgWrapper gridOne">
                <Image src={getImageIcon(props.value.metadata.image)} />
                <span className="bg"></span>
            </div>
            <div className="name">{props.value.name}</div>
        </div>
    )
}

export default CategoryCard
