import checkUserController from 'controllers/auth/checkUserController'
import createOrganizationController from 'controllers/organizations/createOrganizationController'
import setSessionStorageController from 'controllers/sessionStorage/setSessionStorageController'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import digitsCount from 'servises/local/functions/digitsCount'
import IconBtn from 'utils/tools/iconBtn/IconBtn'
import Input from 'utils/tools/input/Input'
import InputLable from 'utils/tools/inputLable/InputLable'
import Option from 'utils/tools/option/Option'
import Select from 'utils/tools/select/Select'
import Title from 'utils/tools/title/Title'

const CreateNewOrgPage = (props) => {
    const navigate = useNavigate()
    const [user, setUser] = useState(null)
    useEffect(() => {
        isUserLoggedIn()
    }, [])
    const isUserLoggedIn = async () => {
        let tmpUser = await checkUserController()
        if (!tmpUser) navigate('home')
        setUser(tmpUser)
    }

    const [isUser, setIsUser] = useState(false)

    useEffect(() => {
        console.log(user)
    }, [user])

    const [orgName, setOrgName] = useState('')
    const [idNum, setIdNum] = useState('')
    const [taxType, setTaxType] = useState(0)
    const [fName, setFName] = useState('')
    const [lName, setLName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')

    const [orgNameIsValid, setOrgNameIsValid] = useState(false)
    const [idNumIsValid, setIdNumIsValid] = useState(false)
    const [taxTypeIsValid, setTaxTypeIsValid] = useState(false)
    const [isCanNext, setIsCanNext] = useState(false)
    const [fNameIsValid, setFNameIsValid] = useState('')
    const [lNameIsValid, setLNameIsValid] = useState('')
    const [phoneIsValid, setPhoneIsValid] = useState('')
    const [emailIsValid, setEmailIsValid] = useState(false)

    useEffect(() => {
        let isValid = false
        if (orgNameIsValid && idNumIsValid && taxTypeIsValid && (isUser || (fNameIsValid && lNameIsValid && phoneIsValid)) && (email === '' || emailIsValid)) isValid = true
        setIsCanNext(isValid)
    }, [orgName, email, idNum, taxType])

    const onCreateHandler = async () => {
        const tmpOrg = {
            displayName: orgName,
            firstname: isUser ? user.firstname : fName,
            lastname: isUser ? user.lastname : lName,
            description: '',
            type: 'organization',
            phone: isUser ? user.phone : phone,
            idNumber: idNum,
            taxType: 'SOLE_PROPRIETOR',
            email: isUser ? user.email : email,
        }
        const org = await createOrganizationController(tmpOrg)
        if (org) {
            setSessionStorageController(process.env.REACT_APP_SS_ORG, org)
            navigate('createNewStore')
        }
    }

    return (
        <div className="createNewPage page flexColumn">
            <img title="חזור לדף הבית" className="img clickable" src="/assets/images/logos/logo.svg" onClick={() => navigate('../home')} />
            <form className={isUser ? 'fields center' : 'fields center open'} autoComplete="on">
                {!isUser && (
                    <div className="inputs user flexColumn">
                        <Title text="נתוני אחראי הארגון" />
                        <InputLable text="שם פרטי" validation={fName.length >= 2} setIsValid={setFNameIsValid} error="לפחות שני תוים">
                            <Input id="fName" autoComplete="fName" value={fName} setValue={setFName} />
                        </InputLable>
                        <InputLable text="שם משפחה" validation={lName.length >= 2} setIsValid={setLNameIsValid} error="לפחות שני תוים">
                            <Input id="lName" autoComplete="lName" value={lName} setValue={setLName} />
                        </InputLable>
                        <InputLable text="נייד" validation={/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(phone)} setIsValid={setPhoneIsValid} error="זה לא מספר פלאפון">
                            <Input id="phone" autoComplete="phone" value={phone} setValue={setPhone} />
                        </InputLable>
                        <InputLable notRequired={true} text='דוא"ל' validation={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)} setIsValid={setEmailIsValid} error="אימייל לא תקין">
                            <Input notRequired={true} id="email" type="email" autoComplete="email" value={email} setValue={setEmail} />
                        </InputLable>
                    </div>
                )}

                <div className="inputs flexColumn">
                    <Title text="יצירת ארגון חדש" />
                    <label className="isLabel">
                        <Input value={isUser} setValue={setIsUser} type="checkbox" />
                        <span>להשתמש בנתוני המשתמש הנוכחי</span>
                    </label>
                    <InputLable text="שם הארגון" validation={orgName && orgName.length >= 2} setIsValid={setOrgNameIsValid} error="לפחות שני תוים">
                        <Input id="orgName" autoComplete="orgName" value={orgName} setValue={setOrgName} />
                    </InputLable>
                    <InputLable text='ת"ז / ח"פ' validation={digitsCount(idNum) === 9} setIsValid={setIdNumIsValid}>
                        <Input id="ID" type="number" autoComplete="ID" value={idNum} setValue={setIdNum} />
                    </InputLable>
                    <InputLable text="סוג רישום" validation={taxType} setIsValid={setTaxTypeIsValid}>
                        <Select id="taxType" value={taxType} setValue={setTaxType}>
                            <Option default={true} text="" />
                            <Option value={'SOLE_PROPRIETOR'} text="עוסק פטור" />
                            <Option value={'STANDARD_COMPANY'} text="עוסק מורשה" />
                            <Option value={'CORPORATION'} text='חברה בע"מ' />
                        </Select>
                    </InputLable>

                    <IconBtn text="צור ארגון" onClick={onCreateHandler} unclickable={!isCanNext} />
                </div>
                <div onClick={() => navigate('../home')} className="backToHome clickable">
                    קיים כבר ארגון? התחבר אליו
                </div>
            </form>
            <div className="footer">
                <IconBtn classIcon="fi-rr-angle-small-left" classIconHover="fi-rr-arrow-small-left" className="backBtn" text="חזור לדף הראשי" onClick={() => navigate('../home')} />
            </div>
        </div>
    )
}

export default CreateNewOrgPage
