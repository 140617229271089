import IconBtn from '../iconBtn/IconBtn'
import { BiPlus, BiMinus } from 'react-icons/bi'

const AmountManeger = (props) => {
    const max = props.max || 999
    const min = props.min || 0
    const jump = props.jump || 1
    const className = props.className ? ' ' + props.className : ''

    const addHandler = () => {
        props.setValue((pre) => {
            if (pre === min) return min + jump
            if (pre < max) return pre + jump
            return max
        })
    }
    const subHandler = () => {
        props.setValue((pre) => (pre > min ? pre - jump : min))
    }
    const changeHandler = (input) => {
        const num = parseInt(input)
        let numToSet = min
        if (num > min) {
            if (num < max) numToSet = num
            else numToSet = max
        }
        props.setValue(numToSet)
    }

    return (
        <div className={'tools amountManeger center' + className}>
            <IconBtn icon={BiPlus} className={'plus'} onClick={addHandler} title={props.plusTitle || 'הוספת כמות'} />
            <input
                type="number"
                min={min}
                max={max}
                value={props.value}
                onChange={(e) => {
                    changeHandler(e.target.value)
                }}
            />
            <IconBtn icon={BiMinus} className={'minus'} onClick={subHandler} title={props.minusTitle || 'הורדת כמות'} />
        </div>
    )
}

export default AmountManeger
