import { startAutoRefreshToken } from 'events/autoRefreshToken'
import { startExpireCountdown } from 'events/expireCountdown'
import { getOrginazationController, getUserByIdController, loginController } from 'controllers/controllers'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import getStoreBaseUrl from 'controllers/app/getStoreBaseUrl'
import Title from 'utils/tools/title/Title'
import InputLable from 'utils/tools/inputLable/InputLable'
import Input from 'utils/tools/input/Input'
import IconBtn from 'utils/tools/iconBtn/IconBtn'
import FullPopup from 'utils/layout/fullPopup/FullPopup'
import MessageWithBtns from 'utils/layout/messageWithBtns/MessageWithBtns'
import Loading from 'utils/tools/loading/Loading'

const LoginPage = (props) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [isPopup, setIsPopup] = useState(false)
    const [popupMessage, setPopupMessage] = useState()
    const navigate = useNavigate()
    const onClickHandler = async () => {
        setPopupMessage()
        setIsPopup(true)
        if (username && password) {
            const result = await loginController(username, password)
            if (result) {
                // updateLocalStorageController(process.env.REACT_APP_LS_USER, await getUserByIdController(result.userId))
                // addLocalStorageController(process.env.REACT_APP_LS_ORGANIZATION, await getOrginazationController(result.userId))
                console.log('Logged in!', result)
                window.dispatchEvent(startAutoRefreshToken)
                window.dispatchEvent(startExpireCountdown)
                let base = getStoreBaseUrl()
                console.log(base)
                navigate('../home')
                return
            } else setPopupMessage('הנתונים אינם נכונים, אנא נסה שנית')
        } else setPopupMessage('אנא מלא את כל השדות')
    }

    return (
        <div className="loginPage flexColumn">
            <img title="חזור לדף הבית" className="img clickable" src="/assets/images/logo.svg" onClick={() => navigate('../home')} />
            <form className="fields center flexColumn" autoComplete="on">
                <Title text="התחברות" />
                <InputLable text="שם משתמש">
                    <Input id="username" autoComplete="username" title="שם משתמש" type="email" value={username} setValue={setUsername} />
                </InputLable>
                <InputLable text="סיסמא">
                    <Input id="current-password" autoComplete="current-password" title="סיסמה" type="password" value={password} setValue={setPassword} />
                </InputLable>
                <div className="flex forgot">
                    <span onClick={() => navigate('../resetPassword')} className="clickable">
                        ?שכחת סיסמא
                    </span>
                </div>
                <IconBtn className="loginBtn" text="התחבר" type="submit" onClick={onClickHandler} />
                <IconBtn className="registerBtn" text="הירשם" type="submit" onClick={() => navigate('../register')} />
            </form>
            <div className="footer">
                <IconBtn classIcon="fi-rr-angle-small-left" classIconHover="fi-rr-arrow-small-left" className="backBtn" text="חזור לדף הראשי" onClick={() => navigate('../home')} />
            </div>
            <FullPopup isShow={isPopup} setIsShow={setIsPopup}>
                {popupMessage ? (
                    <MessageWithBtns text={popupMessage}>
                        <IconBtn className="safeColor" text="אישור" onClick={() => setIsPopup(false)} />
                    </MessageWithBtns>
                ) : (
                    <Loading />
                )}
            </FullPopup>
        </div>
    )
}

export default LoginPage
