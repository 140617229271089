import { postReq } from '../httpReq'

const api = 'stores'
export default async (body, orgId) => {
    const headers = {
        'Content-Type': 'application/json',
        'x-stelace-organization-id': orgId,
    }
    const result = await postReq(api, body, [], [], headers)
    return result
}
