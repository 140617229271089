import { getUserByIdController } from 'controllers/controllers'
import loginService from 'servises/httpReqServices/auth/loginService'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
export default async (username, password) => {
    if (!username || !password) return
    const result = await loginService(username, password)
    if (result) {
        result.user = await getUserByIdController(result.userId)
        delete result.userId
        cookies.set(process.env.REACT_APP_COOKIES_AUTH, result)
    }

    return result
}
