import formatMoney from 'servises/local/functions/formatMoney'
import Btn from '../btn/Btn'
import { useNavigate } from 'react-router-dom'
import { MdLocationOn } from 'react-icons/md'
import IconBtn from '../iconBtn/IconBtn'
import getImageStore from 'controllers/images/getImageStore'

const StoreCard = (props) => {
    const navigate = useNavigate()
    const addProductHandler = () => {
        props.inStoreClick && props.inStoreClick(props.store)
        navigate('/store/' + props.store.id)
    }

    return (
        <div key={'storeCard' + props.store.id} className="tools storeCard center clickable" onClick={() => props.onClick(props.store)}>
            <img src={getImageStore(props.store.logo)} alt="" className="storeLogo" />
            <div className="storeInfo">
                <IconBtn className="meters" text={props.store.distance && props.store.distance.toFixed(2) + ' ק"מ'} icon={MdLocationOn} unclickable={true} onClick={() => {}} />
                <div className="name">{props.store.name}</div>
                {props.store.price && <div className="price">{formatMoney(props.store.price)}</div>}
            </div>
            <Btn onClick={addProductHandler}>{props.store.price ? 'הוסף לסל' : 'אל החנות'}</Btn>
        </div>
    )
}

export default StoreCard
