const Option = (props) => {
    return props.default ? (
        <option value={-1} disabled className="tools option">
            {props.text}
        </option>
    ) : (
        <option value={props.value} className="tools option">
            {props.text}
        </option>
    )
}

export default Option
