import { useEffect, useState } from 'react'
import getLocalStorageController from 'controllers/localStorage/getLocalStorageController'
import getProductsByStoreIdController from 'controllers/stores/getProductsByStoreIdController'
import { useNavigate, useParams } from 'react-router-dom'
import getStoreByStoreIdController from 'controllers/stores/getStoreByStoreIdController'
import { loadCurrentCartService } from 'servises/local/carts/cartService'
import getImageStore from 'controllers/images/getImageStore'
import { BsHeart } from 'react-icons/bs'
import Btn from 'utils/tools/btn/Btn'
import CategoriesScroll from 'utils/layout/categoriesScroll/CategoriesScroll'
import SearchBar from 'utils/tools/searchBar/SearchBar'
import ProfileBtn from 'utils/tools/profileBtn/ProfileBtn'
import Rate from 'utils/tools/rate/Rate'
import ProductsScroll from 'utils/layout/productsScroll/ProductScroll'
import GridProducts from 'utils/layout/gridProducts/GridProducts'
import FullPopup from 'utils/layout/fullPopup/FullPopup'
import ProductPopup from 'utils/layout/productPopup/ProductPopup'
import NotLoggedInPopup from 'utils/tools/notLoggedInPopup/NotLoggedInPopup'
import Loading from 'utils/tools/loading/Loading'

const StorePage = (props) => {
    const [storeProducts, setStoreProducts] = useState()
    const [store, setStore] = useState()
    const [productPopupState, setProductPopupState] = useState(false)
    const [productPopup, setProductPopup] = useState()
    const { id } = useParams()
    const [isCategory, setIsCategory] = useState(false)
    const [isShowTopBarBg, setIsShowTopBarBg] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        loadStore()
        loadProducts()
    }, [])

    const loadProducts = async () => {
        const tmpProducts = await getProductsByStoreIdController(id || process.env.REACT_APP_STORE_ID)
        await setStoreProducts(tmpProducts.list)
        loadCurrentCartService(id)
    }
    const loadStore = async () => {
        let store = await getStoreByStoreIdController(id || process.env.REACT_APP_STORE_ID)
        if (store) {
            setStore(store)
            localStorage.setItem(process.env.REACT_APP_LS_STORE, JSON.stringify(store))
        }
    }

    const productPopupHandler = (isShow, product) => {
        setProductPopupState(isShow)
        setProductPopup(product)
    }

    const onScrolledHandler = (e) => {
        console.log(e.target.scrollTop)
        if (e.target.scrollTop >= 500) setIsShowTopBarBg(true)
        else setIsShowTopBarBg(false)
    }

    return store && storeProducts ? (
        <div className="storePage" onScroll={onScrolledHandler}>
            <div className={isShowTopBarBg ? 'torBar gridOne scrolled' : 'torBar gridOne'}>
                <img className="logoBuyWay clickable" src="/assets/images/logos/logo.svg" title="חזור לדף הבית" onClick={() => navigate('../home')} />
                <div className={isCategory ? 'searchBarAndBtnWrapper flex category' : 'searchBarAndBtnWrapper flex search'}>
                    <Btn onClick={() => setIsCategory((pre) => !pre)}>{isCategory ? 'חיפוש' : 'קטגוריות'}</Btn>
                    {isCategory ? <CategoriesScroll categoryArr={props.categoryArr} /> : <SearchBar />}
                </div>
                <ProfileBtn />
            </div>
            <div className="header gridOne">
                <img className="bg" src={getImageStore(store.allImages[0])} />
                <div className="shadow"></div>
                <img src={store.logo ? getImageStore(store.logo) : '/assets/images/logo.svg'} className="logo" />
                <div className="name">{store.name}</div>
                <div className="details flex">
                    <div className="rate">{Rate(store.averageStoreRating * 2)}</div>
                    <div className="like clickable">
                        <BsHeart />
                    </div>
                </div>
            </div>
            <div className="products flexColumn">
                {/* <StoreHeader store={store} /> */}
                <div className="sectionOne flex">
                    <ProductsScroll title="חדש בחנות" products={storeProducts} setProducts={setStoreProducts} setProductPopup={productPopupHandler} />
                </div>
                {/* <ProductsScroll title="מה חדש" products={storeProducts} setProducts={setStoreProducts} setProductPopup={productPopupHandler} /> */}
                <GridProducts title="כל מוצרי החנות" products={storeProducts} setProducts={setStoreProducts} setProductPopup={productPopupHandler} />
            </div>
            <FullPopup isShow={productPopupState} setIsShow={setProductPopupState}>
                <ProductPopup product={productPopup} IsShow={productPopupState} setIsShow={setProductPopupState} />
            </FullPopup>
            <NotLoggedInPopup />
        </div>
    ) : (
        <Loading />
    )
}

export default StorePage
