import getImageLogo from 'controllers/images/getImageLogo'
import getImageProduct from 'controllers/images/getImageProduct'
import Loading from '../loading/Loading'
const ProductCardSmall = (props) => {
    return (
        <div className={'tools productCardSmall'}>
            {props.value ? (
                <div className="details">
                    <div className="top gridOne">
                        <img
                            src={props.value && props.value.advancedDataItems && props.value.advancedDataItems.images ? getImageProduct(props.value.advancedDataItems.images[0]) : '/assets/images/logo.svg'}
                            className="image"
                            title="לחיצה אחת לפתיחת מוצר"
                            onClick={props.onImageClick ? props.onImageClick : props.onClick}
                        />
                        <img onClick={props.onLogoClick ? props.onLogoClick : props.onClick} src={props.value.logo ? getImageLogo(props.value.logo) : '/assets/images/logo.svg'} className="logo" />
                    </div>
                    <div className="bot">
                        <div className="prodDit">
                            <span onClick={props.onCompanyClick ? props.onCompanyClick : props.onClick} className="company">
                                {props.value.companyName}
                            </span>
                            <span onClick={props.onNameClick ? props.onNameClick : props.onClick} className="name">
                                {props.value.name}
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    )
}

export default ProductCardSmall
