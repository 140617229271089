import { getUserByIdController } from 'controllers/controllers'
import readCookieController from 'controllers/cookies/readCookieController'
import { useEffect, useState } from 'react'

const ProfilePage = (props) => {
    const [details, setDetails] = useState([])

    useEffect(() => {
        loadUserDetails()
    }, [])

    const loadUserDetails = async () => {
        let tmp = await readCookieController(process.env.REACT_APP_COOKIES_AUTH)
        let user = await getUserByIdController(tmp.user.id)
        let arr = []
        for (const [key, value] of Object.entries(user)) {
            arr.push({ key: key, value: value })
        }
        setDetails(arr)
    }

    return (
        <div className="profilePage">
            {details.map((x) => (
                <div key={'details' + x.key}>{x.key + ': ' + x.value}</div>
            ))}
        </div>
    )
}

export default ProfilePage
