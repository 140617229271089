import verificationConfirmPhoneService from 'servises/httpReqServices/verification/verificationConfirmPhoneService'

export default async (phoneNumber, code) => {
    phoneNumber = String(phoneNumber)
    code = String(code)
    let result = 'Not valid phone number or code'
    if (phoneNumber.length === 10 && code.length === 4) {
        let tmp = await verificationConfirmPhoneService(phoneNumber, code)
        if (tmp.data) result = tmp.data
    }
    return result
}
