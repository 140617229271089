const MessageWithBtns = (props) => {
    return (
        <form className="messageWithBtns">
            <div className="text center">{props.text}</div>
            <div className="btns center">{props.children}</div>
        </form>
    )
}

export default MessageWithBtns
