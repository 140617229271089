import { getCategoriesController } from 'controllers/controllers'
import getLocalStorageController from 'controllers/localStorage/getLocalStorageController'
import setLocalStorageController from 'controllers/localStorage/setLocalStorageController'
import updateLocalStorageController from 'controllers/localStorage/updateLocalStorageController'
import { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import LoginPage from '../allProfilePages/login/LoginPage'
import RegisterPage from '../allProfilePages/register/RegisterPage'
import ResetPasswordPage from '../allProfilePages/resetPassword/ResetPasswordPage'
import SideBarStore from './layout/sideBarStore/SideBarStore'
import AddProductPage from './storePages/addProduct/AddProductPage'
import TopBarStore from './layout/topBarStore/TopBarStore'
import HomeStorePage from './storePages/homeStore/HomeStorePage'
import StorePage from '../allProfilePages/store/StorePage'
import Page from 'utils/layout/page/Page'
import MyProductsPage from './storePages/myProducts/MyProducts'
import CostumersClubPage from './storePages/costumersClub/CostumersClubPage'
import InOutPage from './storePages/inOut/InOutPage'
import MyEmployeesPage from './storePages/myEmployees/MyEmployeesPage'
import MyOrdersPage from './storePages/myOrders/MyOrdersPage'
import SalesPage from './storePages/sales/SalesPage'
import VipCostumersPage from './storePages/vipCostumers/VipCostumersPage'
import SuppliersPage from './storePages/suppliers/SuppliersPage'
import CreateNewStorePage from '../allProfilePages/createNew/CreateNewStorePage'

const StoreProfile = () => {
    const [allCategories, setAllCategories] = useState(null)
    const [lastCategory, setLastCategory] = useState(null)
    const [currentCategory, setCurrentCategory] = useState(null)
    const [isProducts, setIsProducts] = useState(true)
    const [inputSearchPropducts, setInputSearchPropducts] = useState('')
    const [searchBy, setSearchBy] = useState('bySearch')

    const searchInput = { inputSearchPropducts, setInputSearchPropducts }
    const searchByInput = { searchBy, setSearchBy }
    const categoryArr = { allCategories, currentCategory, setCurrentCategory, lastCategory, setLastCategory }

    useEffect(() => {
        getCategories()
        checkCarts()
        loadLocation()
        loadCategories()
    }, [])

    const loadCategories = async () => {
        let tmpCategories = await getLocalStorageController(process.env.REACT_APP_LS_LOCAL_CATEGORIES)
        setAllCategories(tmpCategories)
        setCurrentCategory(tmpCategories[0])
    }

    const getCategories = async () => {
        let tmpCategories = getLocalStorageController(process.env.REACT_APP_LS_LOCAL_CATEGORIES)
        if (!tmpCategories) await updateLocalStorageController(process.env.REACT_APP_LS_LOCAL_CATEGORIES, await getCategoriesController())
    }

    const checkCarts = async () => {
        let tmpAllCart = await getLocalStorageController(process.env.REACT_APP_LS_ALL_CARTS)
        if (tmpAllCart === null) updateLocalStorageController(process.env.REACT_APP_LS_ALL_CARTS, {})
        let tmpLocalCart = await getLocalStorageController(process.env.REACT_APP_LS_CURRENT_CART)
        if (tmpLocalCart === null) updateLocalStorageController(process.env.REACT_APP_LS_CURRENT_CART, {})
    }

    const loadLocation = async () => {
        let pos
        navigator.geolocation.getCurrentPosition((position) => {
            pos = {
                lat: position.coords.latitude || 32.109333,
                lng: position.coords.longitude || 34.855499,
            }
        })
        if (!pos) pos = { lat: 32.109333, lng: 34.855499 }
        await setLocalStorageController(process.env.REACT_APP_LS_USER_LOCATION, pos)
    }

    return (
        <Routes>
            <Route path={'login'} element={<LoginPage />} />
            <Route path={'register'} element={<RegisterPage />} />
            <Route path={'resetPassword'} element={<ResetPasswordPage />} />
            <Route path={'createNewStore'} element={<CreateNewStorePage />} />

            <Route
                path={'*'}
                element={
                    <div className="storeProfile">
                        <TopBarStore />
                        {/* <TopBarStore
                            setCartClassName={CartClassNameHandler}
                            store={store}
                            categoryArr={categoryArr}
                            isProducts={isProducts}
                            isShowTopCategories={isShowTopCategories}
                            searchInput={searchInput}
                            searchByInput={searchByInput}
                        /> */}
                        <div className="pageWrapper flex">
                            <SideBarStore />
                            <Page>
                                <div className="content">
                                    <Routes>
                                        <Route path={'store/:id'} element={<StorePage />} />
                                        <Route path={'addProduct'} element={<AddProductPage searchByInput={searchByInput} searchInput={searchInput} categoryArr={categoryArr} />} />
                                        <Route path={'home'} element={<HomeStorePage categoryArr={categoryArr} isProducts={isProducts} setIsProducts={setIsProducts} searchInput={searchInput} />} />
                                        <Route path={'costumersClub'} element={<CostumersClubPage />} />
                                        <Route path={'myProducts'} element={<MyProductsPage />} />
                                        <Route path={'inOut'} element={<InOutPage />} />
                                        <Route path={'myEmployees'} element={<MyEmployeesPage />} />
                                        <Route path={'myOrders'} element={<MyOrdersPage />} />
                                        <Route path={'suppliers'} element={<SuppliersPage />} />
                                        <Route path={'sales'} element={<SalesPage />} />
                                        <Route path={'vipCostumers'} element={<VipCostumersPage />} />
                                    </Routes>
                                </div>
                            </Page>
                        </div>
                    </div>
                }
            />
        </Routes>
    )
}

export default StoreProfile
