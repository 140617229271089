import readCookieController from 'controllers/cookies/readCookieController'
import getLocalStorageController from 'controllers/localStorage/getLocalStorageController'

export default async (store) => {
    const auth = await readCookieController(process.env.REACT_APP_COOKIES_AUTH)
    // const store = getLocalStorageController(process.env.REACT_APP_LS_STORE)

    if (auth && auth.user) {
        auth.user.isLogged = true
        if (store && store.id && auth.user.str === store.id) {
            auth.user.isMember = true
        }
    }
    return auth ? auth.user : null
}
