import verificationRequestPhoneService from 'servises/httpReqServices/verification/verificationRequestPhoneService'

export default async (phoneNumber) => {
    phoneNumber = String(phoneNumber)
    let result = 'Not valid phone number'
    if (phoneNumber.length === 10) {
        let tmp = await verificationRequestPhoneService(phoneNumber)
        if (tmp.data) result = tmp.data
    }
    return result
}
