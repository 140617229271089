import getLocalStorageController from 'controllers/localStorage/getLocalStorageController'
import { addToCurrentCartService, removeFromCurrentCartService, triggerLocalStorageEvent } from 'servises/local/carts/cartService'

const store = process.env.REACT_APP_LS_STORE

export const currentCartController = (storeId, product, amount, isToBuy = true, isToAddAmount = false) => {
    if (!product) return
    if (!storeId) storeId = getLocalStorageController(store).id

    if (amount < 1) removeFromCurrentCartService(storeId, product)
    else addToCurrentCartService(storeId, product, amount, isToBuy, isToAddAmount)
}

export const getCartByStoreId = (storeId) => {
    
}
