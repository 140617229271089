import React from 'react'
import { isBrowser } from 'react-device-detect'
import AppBrowser from './appBrowser/AppBrowser'
import AppMobile from './appMobile/AppMobile'

const App = (props) => {
    return <>{isBrowser ? <AppBrowser /> : <AppMobile />}</>
}

export default App
