import { startAutoRefreshToken } from 'events/autoRefreshToken'
import { startExpireCountdown } from 'events/expireCountdown'
import { getOrginazationController, getUserByIdController, loginController } from 'controllers/controllers'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import getStoreBaseUrl from 'controllers/app/getStoreBaseUrl'
import { MdOutlineSendToMobile, MdKeyboardArrowLeft } from 'react-icons/md'
import Title from 'utils/tools/title/Title'
import InputLable from 'utils/tools/inputLable/InputLable'
import Input from 'utils/tools/input/Input'
import IconBtn from 'utils/tools/iconBtn/IconBtn'

const ResetPasswordPage = (props) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()
    const onClickHandler = async () => {
        if (username && password) {
            const result = await loginController(username, password)
            if (result) {
                // updateLocalStorageController(process.env.REACT_APP_LS_USER, await getUserByIdController(result.userId))
                // addLocalStorageController(process.env.REACT_APP_LS_ORGANIZATION, await getOrginazationController(result.userId))
                console.log('Logged in!', result)
                window.dispatchEvent(startAutoRefreshToken)
                window.dispatchEvent(startExpireCountdown)
                let base = getStoreBaseUrl()
                console.log(base)
                navigate('../home')
            } else console.log("can't logged in!")
        } else console.log('fill username and password')
    }

    return (
        <div className="resetPasswordPage flexColumn">
            <img title="חזור לדף הבית" className="img clickable" src="/assets/images/logo.svg" onClick={() => navigate('../home')} />
            <form className="fields center flexColumn" autoComplete="on">
                <Title text="התחברות" />
                <InputLable text="שם משתמש">
                    <Input id="username" autoComplete="username" title="שם משתמש" type="email" value={username} setValue={setUsername} />
                </InputLable>

                <IconBtn icon={MdOutlineSendToMobile} className="defaultColorSecendery" text="שלח קוד לפלאפון" onClick={() => {}} />

                <IconBtn icon={MdKeyboardArrowLeft} className="loginBtn" text="חזור" type="submit" onClick={() => navigate('../login')} />
            </form>
        </div>
    )
}

export default ResetPasswordPage
