import { useState } from 'react'
import { useEffect } from 'react'

const InputLable = (props) => {
    const [isValue, setIsValue] = useState(false)
    const [isChanged, setIsChanged] = useState(false)
    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        let valid
        let value

        value = props.children && props.children.props.value
        if (props.children && props.children.props.value) {
            setIsChanged(true)
        }
        if (props.validation !== undefined && props.setIsValid) valid = Boolean((value && props.validation) || (!value && props.children.props.notRequired))
        else valid = Boolean(value)

        setIsValue(value)
        setIsValid(valid)
        props.setIsValid && props.setIsValid(valid)
    }, [props.children.props.value])

    return (
        <div className="inputLableWrapper">
            <label
                onBlur={() => setIsChanged(true)}
                className={isChanged ? (!isValid ? 'inputLable tools flexColumn error' : 'inputLable tools flexColumn active') : 'inputLable tools flexColumn'}
                title={props.title || props.text}
            >
                {props.children}
                <span className={isValue ? 'text active' : 'text'}>{props.children.props.notRequired ? props.text : props.text + ' *'}</span>
            </label>
            <div className="inputLable__errors tools">
                {!props.children.props.notRequired && isChanged && !isValue && <span className="error">{props.defaultError || 'שדה חובה'}</span>}
                {isChanged && !isValid && isValue && <span className="error">{props.error || 'לא תקין'}</span>}
            </div>
        </div>
    )
}

export default InputLable
