import { useLocation, useNavigate } from 'react-router-dom'
import { BiHomeAlt, BiUserCircle, BiMessageSquareDetail, BiBell, BiHeart, BiCartAlt, BiFolderPlus } from 'react-icons/bi'
import { useEffect, useState } from 'react'
import checkUserController from 'controllers/auth/checkUserController'
import IconBtn from 'utils/tools/iconBtn/IconBtn'
import NotLoggedInPopup from 'utils/tools/notLoggedInPopup/NotLoggedInPopup'

const SideBarStore = (props) => {
    const className = props.className ? ' ' + props.className : ''
    const location = useLocation()
    const [currentPage, setCurrentPage] = useState()
    const [isShowPopup, setIsShowPopup] = useState(false)

    useEffect(() => {
        setCurrentPage(location.pathname)
    })

    const navigate = useNavigate()

    const onClick = async (page, isNeedLogin = false, isNeedMember = false) => {
        if (isNeedLogin) {
            const user = await checkUserController()
            if (!user || !user.isLogged || (isNeedMember && !user.isMember)) {
                setIsShowPopup(true)
                return
            }
        }
        navigate(page)
    }

    return (
        <div className={'sideBarStore' + className}>
            <IconBtn
                // icon={BiHomeAlt}
                classIcon="fi-rr-home" //regular
                classIconHover="fi-sr-home" //regular
                text="בית"
                onClick={() => onClick('home')}
                className={currentPage === '/home' ? 'sideIcon active home' : 'sideIcon home'}
                active={currentPage === `/home` ? true : false}
            />
            <IconBtn
                // icon={BiFolderPlus}
                classIcon="fi-rr-folder-add" //regular
                classIconHover="fi-sr-folder-add" //regular
                text="הוספת מוצר"
                onClick={() => onClick('addProduct', true)}
                className={currentPage === '/addProduct' ? 'sideIcon active' : 'sideIcon'}
                active={currentPage === `/addProduct` ? true : false}
            />
            <IconBtn
                // icon={BiFolderPlus}
                classIcon="fi-rr-box-alt" //regular
                classIconHover="fi-sr-box-alt" //regular
                text="מוצרים שלי"
                onClick={() => onClick('myProducts', true)}
                className={currentPage === '/myProducts' ? 'sideIcon active' : 'sideIcon'}
                active={currentPage === `/myProducts` ? true : false}
            />
            <IconBtn
                classIcon="fi-rr-woman-head"
                classIconHover="fi-sr-woman-head"
                text="עובדים שלי"
                onClick={() => onClick('myEmployees', true)}
                className={currentPage === '/myEmployees' ? 'sideIcon active' : 'sideIcon'}
                active={currentPage === `/myEmployees` ? true : false}
            />
            <IconBtn
                classIcon="fi-rr-star-octogram"
                classIconHover="fi-sr-star-octogram"
                text="מבצעים"
                onClick={() => onClick('sales', true)}
                className={currentPage === '/sales' ? 'sideIcon active' : 'sideIcon'}
                active={currentPage === `/sales` ? true : false}
            />
            <IconBtn
                classIcon="fi-rr-receipt"
                classIconHover="fi-sr-receipt"
                text="הזמנות שלי"
                onClick={() => onClick('myOrders', true)}
                className={currentPage === '/myOrders' ? 'sideIcon active' : 'sideIcon'}
                active={currentPage === `/myOrders` ? true : false}
            />
            <IconBtn
                classIcon="fi-rr-glass-cheers"
                classIconHover="fi-sr-glass-cheers"
                text="מועדון לקוחות"
                onClick={() => onClick('costumersClub', true)}
                className={currentPage === '/costumersClub' ? 'sideIcon active' : 'sideIcon'}
                active={currentPage === `/costumersClub` ? true : false}
            />
            <IconBtn
                classIcon="fi-rr-gem"
                classIconHover="fi-sr-gem"
                text="לקוחות VIP"
                onClick={() => onClick('vipCostumers', true)}
                className={currentPage === '/vipCostumers' ? 'sideIcon active' : 'sideIcon'}
                active={currentPage === `/vipCostumers` ? true : false}
            />
            <IconBtn
                classIcon="fi-rr-signal-alt"
                classIconHover="fi-sr-signal-alt-2"
                text="הוצאות/הכנסות"
                onClick={() => onClick('inOut', true)}
                className={currentPage === '/inOut' ? 'sideIcon active' : 'sideIcon'}
                active={currentPage === `/inOut` ? true : false}
            />
            <IconBtn
                classIcon="fi-rr-truck-side"
                classIconHover="fi-sr-truck-side"
                text="ספקים"
                onClick={() => onClick('suppliers', true)}
                className={currentPage === '/suppliers' ? 'sideIcon active' : 'sideIcon'}
                active={currentPage === `/suppliers` ? true : false}
            />
            <NotLoggedInPopup isShow={isShowPopup} setIsShow={setIsShowPopup} />
        </div>
    )
}

export default SideBarStore
