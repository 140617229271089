import { postReq } from '../httpReq'
import readLocalDataService from 'servises/local/cookies/readCookieService'

const api = 'storeProducts'
export default async (body) => {
    const data = await readLocalDataService('data')
    const headers = {
        'Content-Type': 'application/json',
        'x-stelace-organization-id': Object.keys(data.user.organizations)[0],
    }
    const result = await postReq(api, body, [], [], headers)
    return result
}
