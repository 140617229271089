import { postReqAllowed } from '../httpReq'

const api = 'verification/request'
export default async (phoneNumber) => {
    const body = {
        verificationType: 'PHONE',
        toVerify: phoneNumber,
    }
    const result = await postReqAllowed(api, body)
    return result
}
