import checkUserController from 'controllers/auth/checkUserController'
import { getCategoriesController } from 'controllers/controllers'
import getSessionStorageController from 'controllers/sessionStorage/getSessionStorageController'
import addStoreController from 'controllers/stores/addStoreController'
import addStoreImagesController from 'controllers/stores/addStoreImagesController'
import addStoreLogoController from 'controllers/stores/addStoreLogoController'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FullPopup from 'utils/layout/fullPopup/FullPopup'
import DraggableUploader from 'utils/tools/draggableUploader/DraggableUploader'
import IconBtn from 'utils/tools/iconBtn/IconBtn'
import Input from 'utils/tools/input/Input'
import InputLable from 'utils/tools/inputLable/InputLable'
import Option from 'utils/tools/option/Option'
import PlaceMap from 'utils/tools/placeMap/PlaceMap'
import Select from 'utils/tools/select/Select'
import Title from 'utils/tools/title/Title'

const CreateNewStorePage = (props) => {
    const navigate = useNavigate()
    const [org, setOrg] = useState(null)

    const [storeName, setStoreName] = useState('')
    const [storeNameIsValid, setStoreNameIsValid] = useState('')

    const [phone, setPhone] = useState('')
    const [phoneIsValid, setPhoneIsValid] = useState('')

    const [storeCategories, setStoreCategories] = useState([])
    const [typeCategory, setTypeCategory] = useState()
    const [category, setCategory] = useState()
    const [categoryIsValid, setCategoryIsValid] = useState()

    const [logo, setLogo] = useState()

    const [isNeedDeliveries, setIsNeedDeliveries] = useState(false)
    const [isProvidesDeliveries, setIsProvidesDeliveries] = useState(false)
    const [isOnlineStore, setIsOnlineStore] = useState(false)
    const [isHaveStorage, setIsHaveStorage] = useState(false)
    const [isCollectable, setIsCollectable] = useState(false)

    const [place, setPlace] = useState()

    const [isPopup, setIsPopup] = useState(false)
    const [isCanCreate, setIsCanCreate] = useState(false)

    useEffect(() => {
        isUserLoggedIn()
    }, [])

    const isUserLoggedIn = async () => {
        let tmpUser = await checkUserController()
        let tmpOrg = await getSessionStorageController(process.env.REACT_APP_SS_ORG)
        if (!tmpUser || !tmpOrg) navigate('home')
        else {
            setOrg(tmpOrg)
            loadCategories()
        }
    }

    const loadCategories = async () => {
        const tmp = await getCategoriesController()
        setStoreCategories(tmp[1].sonsImmediate)
    }

    useEffect(() => {
        if (storeNameIsValid && categoryIsValid && category && place && phoneIsValid) setIsCanCreate(true)
        else setIsCanCreate(false)
    }, [storeNameIsValid, categoryIsValid, category, place, phoneIsValid])

    const onCreateHandler = async () => {
        const tmpStore = {
            name: storeName,
            category: JSON.parse(category).id,
            // logo: logo ? logo.name : '',
            needsDeliveryServices: isNeedDeliveries,
            offersDelivery: isProvidesDeliveries,
            allowsPickup: isCollectable,
            storeType: 'STORE',
            isOnline: isOnlineStore,
            managedStock: isHaveStorage,
            address: {
                telephone: phone || org.metadata.phone,
            },
            location: {
                latitude: place.lat,
                longitude: place.lng,
            },
            regularHours: {
                sunday: '08:00-20:00',
                monday: '08:00-20:00',
                tuesday: '08:00-20:00',
                wednesday: '08:00-20:00',
                thursday: '08:00-20:00',
                friday: '08:00-20:00',
                saturday: '08:00-20:00',
            },
            specialHours: {
                '20/12': '09:00-21:00',
                '20/06': '07:00-22:30',
            },
        }
        // if (place.city) tmpStore.address.city = place.city
        // if (place.zip) tmpStore.address.zip = place.zip
        // if (place.country) tmpStore.address.country = place.country
        // if (place.street) tmpStore.address.street = place.street
        // if (place.state) tmpStore.address.state = place.state
        // if (place.name) tmpStore.address.name = place.name

        // let store = await addStoreController(tmpStore, org.id)
        // console.log(store)
        // let img1
        // if (store) {
        //     img1 = await addStoreLogoController(logo, org.id, store.id)
        // }
        
        // if (store && img1) navigate('home')
        let img1 = await addStoreLogoController(logo, org.id, "ast_jvewjKE1Akr1pII2UAkr")
        console.log(img1)
    }

    return (
        org && (
            <div className="createNewPage page flexColumn">
                <img title="חזור לדף הבית" className="img clickable" src="/assets/images/logos/logo.svg" onClick={() => navigate('../home')} />
                <form className={'fields center open'} autoComplete="on">
                    <div className="inputs checks flexColumn">
                        <label className="isLabel">
                            <Input notRequired={true} value={isNeedDeliveries} setValue={setIsNeedDeliveries} type="checkbox" />
                            <span>אני צריך שירות משלוחים</span>
                        </label>
                        <label className="isLabel">
                            <Input notRequired={true} value={isProvidesDeliveries} setValue={setIsProvidesDeliveries} type="checkbox" />
                            <span>יש לי משלוחים משלי</span>
                        </label>
                        <label className="isLabel">
                            <Input notRequired={true} value={isCollectable} setValue={setIsCollectable} type="checkbox" />
                            <span>ניתן לאסוף מהחנות שלי</span>
                        </label>
                        <label className="isLabel">
                            <Input notRequired={true} value={isOnlineStore} setValue={setIsOnlineStore} type="checkbox" />
                            <span>אני חנות אונליין</span>
                        </label>
                        <label className="isLabel">
                            <Input notRequired={true} value={isHaveStorage} setValue={setIsHaveStorage} type="checkbox" />
                            <span>יש ברשות החנות מחסן</span>
                        </label>
                    </div>
                    <div className="inputs flexColumn">
                        <Title text="יצירת סניף חדש" />
                        <InputLable text="שם הסניף" validation={storeName && storeName.length >= 2} setIsValid={setStoreNameIsValid} error="לפחות שני תוים">
                            <Input id="orgName" autoComplete="orgName" value={storeName} setValue={setStoreName} />
                        </InputLable>
                        <InputLable text="טלפון של החנות" validation={/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(phone)} setIsValid={setPhoneIsValid} error="זה לא מספר פלאפון">
                            <Input notRequired={true} type="number" id="phone" autoComplete="phone" value={phone} setValue={setPhone} />
                        </InputLable>
                        <div className="selects center">
                            {typeCategory && JSON.parse(typeCategory).sonsImmediate != undefined && (
                                <InputLable text="קטגוריה" validation={category} setIsValid={setCategoryIsValid}>
                                    <Select id="category" value={category} setValue={setCategory}>
                                        <Option default={true} text="" />
                                        {JSON.parse(typeCategory).sonsImmediate.map((x) => (
                                            <Option key={'storeCategories' + x.id} value={JSON.stringify(x)} text={x.name} />
                                        ))}
                                    </Select>
                                </InputLable>
                            )}
                            <InputLable text="סוג החנות" validation={typeCategory && JSON.parse(typeCategory).id != undefined} setIsValid={null}>
                                <Select id="storeType" value={typeCategory} setValue={setTypeCategory}>
                                    <Option default={true} text="" />
                                    {storeCategories.map((x) => (
                                        <Option key={'storeCategories' + x.id} value={JSON.stringify(x)} text={x.name} />
                                    ))}
                                </Select>
                            </InputLable>
                        </div>

                        {place ? (
                            <div className="placeChangeBtnWrapper">
                                <div>{place.name}</div>
                                <IconBtn text="שנה" onClick={() => setIsPopup(true)} />
                            </div>
                        ) : (
                            <IconBtn text="מיקום החנות" onClick={() => setIsPopup(true)} />
                        )}
                        {logo ? (
                            <div className="logoWrapper">
                                <IconBtn text="מחק לוגו" onClick={() => setLogo()} />
                                <img src={URL.createObjectURL(logo)} />
                            </div>
                        ) : (
                            <DraggableUploader text="גרור לכאן לוגו" btnText="בחר לוגו" setFile={setLogo} />
                        )}
                    </div>
                    <IconBtn className="createBtn" text="צור סניף" onClick={onCreateHandler} unclickable={!isCanCreate} />
                    <div onClick={() => navigate('../home')} className="backToHome clickable">
                        קיים כבר סניף? התחבר אליו
                    </div>
                </form>
                <div className="footer">
                    <IconBtn classIcon="fi-rr-angle-small-left" classIconHover="fi-rr-arrow-small-left" className="backBtn" text="חזור לדף הראשי" onClick={() => navigate('../home')} />
                </div>
                <FullPopup isShow={isPopup} setIsShow={setIsPopup}>
                    <div className="placeMapWrapper">
                        <PlaceMap setPlace={setPlace} />
                        <div className="btnWrapper">
                            <IconBtn classIcon="fi-rr-check" onClick={() => setIsPopup(false)} unclickable={!place} />
                            <IconBtn
                                classIcon="fi-sr-cross"
                                onClick={() => {
                                    setIsPopup(false)
                                    setPlace(null)
                                }}
                            />
                        </div>
                    </div>
                </FullPopup>
            </div>
        )
    )
}

export default CreateNewStorePage
