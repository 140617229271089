import axios from 'axios'
import { resetExpireCountdown } from 'events/expireCountdown'
import readLocalDataService from 'servises/local/cookies/readCookieService'
import Logger from 'servises/local/logs/logger'

const baseUrl = process.env.REACT_APP_SERVER_URL

const reqUrlBuilder = (api, PathVariables, QueryParams) => {
    const tmpVariables = Array.isArray(PathVariables) && PathVariables.length > 0 ? '/' + PathVariables.join('/') : ''
    const tmpQuerys = Array.isArray(QueryParams) && QueryParams.length > 0 ? '?' + QueryParams.join('&') : ''
    return baseUrl + api + tmpVariables + tmpQuerys
}

const reqBuilder = async (url, body, headers, callback, isAuthorization = true) => {
    try {
        const data = await readLocalDataService(process.env.REACT_APP_COOKIES_AUTH)
        if (isAuthorization) headers.Authorization = `Bearer ${data.accessToken}`
        console.log('input', url, body, { headers })
        let result
        body ? (result = await callback(url, body, { headers: { ...headers } })) : (result = await callback(url, { headers: { ...headers } }))
        console.log('output', result)
        document.dispatchEvent(resetExpireCountdown)
        return result
    } catch (error) {
        Logger(error)
    }
}

export const getReq = async (api, PathVariables = [], QueryParams = [], headers = {}, body = {}) =>
    await reqBuilder(reqUrlBuilder(api, PathVariables, QueryParams), body, headers, axios.get, false)

export const postReqAllowed = async (api, body, PathVariables = [], QueryParams = [], headers = {}) =>
    await reqBuilder(reqUrlBuilder(api, PathVariables, QueryParams), body, headers, axios.post, false)

export const deleteReq = async (api, PathVariables = [], QueryParams = [], headers = {}) =>
    await reqBuilder(reqUrlBuilder(api, PathVariables, QueryParams), false, headers, axios.delete)

export const postReq = async (api, body, PathVariables = [], QueryParams = [], headers = {}) =>
    await reqBuilder(reqUrlBuilder(api, PathVariables, QueryParams), body, headers, axios.post)

export const putReq = async (api, body, PathVariables = [], QueryParams = [], headers = {}) =>
    await reqBuilder(reqUrlBuilder(api, PathVariables, QueryParams), body, headers, axios.put)

export const patchReq = async (api, body, PathVariables = [], QueryParams = [], headers = {}) =>
    await reqBuilder(reqUrlBuilder(api, PathVariables, QueryParams), body, headers, axios.patch)
