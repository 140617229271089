const Btn = (props) => {
    const classname = props.className || ''

    const onClickHandler = (e) => {
        e.preventDefault()
        props.onClick()
    }
    return (
        <button className={'tools btn clickable ' + classname} onClick={(e) => onClickHandler(e)} type="submit">
            {props.children}
        </button>
    )
}

export default Btn
