import { postReqAllowed } from '../httpReq'

export default async (username, password) => {
    const api = 'auth/login'

    const body = {
        username: username,
        password: password,
    }

    const result = await postReqAllowed(api, body)
    return result && result.data
}
