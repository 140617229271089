import { useEffect, useState } from 'react'
import { getProductsByCategoryIdController, getProductsController } from 'controllers/controllers'
import getLocalStorageController from 'controllers/localStorage/getLocalStorageController'
import getProductsBySearchController from 'controllers/products/getProductsBySearchController'
import getUserProductsController from 'controllers/products/getUserProductsController'
import TwoBtnSwitch from 'utils/layout/twoBtnSwitch/TwoBtnSwitch'
import CategoriesScroll from 'utils/layout/categoriesScroll/CategoriesScroll'
import ProductsScroll from 'utils/layout/productsScroll/ProductScroll'
import GridProducts from 'utils/layout/gridProducts/GridProducts'
import FullPopup from 'utils/layout/fullPopup/FullPopup'
import ProductPopup from 'utils/layout/productPopup/ProductPopup'
import NotLoggedInPopup from 'utils/tools/notLoggedInPopup/NotLoggedInPopup'
import Loading from 'utils/tools/loading/Loading'

const HomeStorePage = (props) => {
    const [allProducts, setAllProducts] = useState(null)
    const [productPopupState, setProductPopupState] = useState(false)
    const [productPopup, setProductPopup] = useState(null)

    const { inputSearchPropducts, setInputSearchPropducts } = props.searchInput
    const { allCategories, currentCategory, setCurrentCategory, lastCategory, setLastCategory } = props.categoryArr

    let isLoad = false

    useEffect(() => {
        // setCurrentCategory({ ...allCategories[0] })
        // loadProducts(1)
    }, [inputSearchPropducts])

    useEffect(() => {
        if (currentCategory && currentCategory.id) {
            loadProducts(1)
            if (currentCategory.id !== 'ctgy_bIZqbE17701lqCW8770') setInputSearchPropducts('')
        }
    }, [currentCategory])

    const loadProducts = async (pageNum) => {
        let tmpProducts
        //only products
        if (inputSearchPropducts && inputSearchPropducts.length > 1) {
            tmpProducts = await getProductsBySearchController(inputSearchPropducts.toString(), pageNum)
        } else if (currentCategory && (currentCategory.id === 'ctgy_bIZqbE17701lqCW8770' || currentCategory.parentIds.includes('ctgy_bIZqbE17701lqCW8770')))
            tmpProducts = await getProductsByCategoryIdController(currentCategory.id, pageNum)
        else tmpProducts = await getProductsController(pageNum)

        if (tmpProducts) {
            let tmpUserProducts
            let userLocation = await getLocalStorageController(process.env.REACT_APP_LS_USER_LOCATION)
            if (tmpProducts.continuationHash) {
                tmpUserProducts = await getUserProductsController(tmpProducts.continuationHash, userLocation, pageNum)
                tmpProducts.list.forEach((product, i) => {
                    if (tmpUserProducts[i] && product.id === tmpUserProducts[i].productId) {
                        product.isSale = tmpUserProducts[i].isSale
                        product.like = tmpUserProducts[i].like
                        product.price = tmpUserProducts[i].price
                        product.salePrice = tmpUserProducts[i].salePrice
                    }
                })
            }
        }

        if (pageNum === 1) setAllProducts(tmpProducts)
        else
            setAllProducts((pre) => {
                if (pre) {
                    pre.list = [...pre.list, ...tmpProducts.list]
                    pre.currentPage = tmpProducts.currentPage
                    pre.continuationHash = tmpProducts.continuationHash
                } else pre = tmpProducts

                return { ...pre }
            })
        if (tmpProducts && tmpProducts.currentPage < tmpProducts.totalPages) isLoad = false
    }

    const productPopupHandler = (isShow, product) => {
        setProductPopupState(isShow)
        setProductPopup(product)
    }

    useEffect(() => {
        if (allProducts) console.log(allProducts)
    }, [allProducts])

    const onScroll = (e) => {
        let offset = 2000
        let loadPresent = e.scrollHeight - e.offsetHeight - offset

        if (!isLoad && loadPresent < e.scrollTop) {
            isLoad = true
            if (allProducts.currentPage < allProducts.totalPages) {
                console.log('Load!')
                loadProducts(allProducts.currentPage + 1)
            }
        }
    }

    return (
        <div className="homeStorePage" onScroll={(e) => onScroll(e.target)}>
            <TwoBtnSwitch firstText="מוצרים" secondText="חנויות" setFlag={props.setIsProducts} flag={props.isProducts} />
            <CategoriesScroll categoryArr={props.categoryArr} isProducts={props.isProducts} />
            {allProducts ? (
                <div className={!props.isProducts ? 'homeContent active' : 'homeContent'}>
                    <div className="products">
                        <GridProducts productType="home" title="מוצרים פופולאריים" products={allProducts.list} setProducts={setAllProducts} setProductPopup={productPopupHandler} />
                        <FullPopup isShow={productPopupState} setIsShow={setProductPopupState}>
                            <ProductPopup product={productPopup} IsShow={productPopupState} setIsShow={setProductPopupState} />
                        </FullPopup>
                        <NotLoggedInPopup />
                    </div>
                    <div className="stores">חנויות</div>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    )
}

export default HomeStorePage
