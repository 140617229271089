import { useCallback } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import FullPopup from '../../layout/fullPopup/FullPopup'
import MessageWithBtns from '../../layout/messageWithBtns/MessageWithBtns'
import IconBtn from '../iconBtn/IconBtn'

const NotLoggedInPopup = (props) => {
    const navigate = useNavigate()
    const goToLoginPageHandler = useCallback(() => Navigate('/login', { replace: true }), [navigate])

    return (
        <FullPopup isShow={props.isShow || false} setIsShow={props.setIsShow}>
            <MessageWithBtns className="tools notLoggedInPopup" text="פעולה זו דורשת התחברות, אנא התחבר">
                <IconBtn className="defaultColor" text="התחברות" onClick={goToLoginPageHandler} />
                <IconBtn className="dangerColor" text="ביטול" onClick={() => props.setIsShow(false)} />
            </MessageWithBtns>
        </FullPopup>
    )
}

export default NotLoggedInPopup
