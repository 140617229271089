import StorePage from 'appBrowser/components/allProfilePages/store/StorePage'
import getStoreBaseUrl from 'controllers/app/getStoreBaseUrl'
import { getCategoriesController } from 'controllers/controllers'
import getLocalStorageController from 'controllers/localStorage/getLocalStorageController'
import setLocalStorageController from 'controllers/localStorage/setLocalStorageController'
import updateLocalStorageController from 'controllers/localStorage/updateLocalStorageController'
import getStoreByStoreIdController from 'controllers/stores/getStoreByStoreIdController'
import { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import BotBar from 'utils/layout/botBar/BotBar'
import Home from './pages/home/Home'

const UserMode = (props) => {
    const [store, setStore] = useState()
    const [storeBaseUrl, setStoreBaseUrl] = useState('BuyWay')
    const [allCategories, setAllCategories] = useState(null)
    const [lastCategory, setLastCategory] = useState(null)
    const [currentCategory, setCurrentCategory] = useState(null)
    const [isProducts, setIsProducts] = useState(true)
    const [inputSearchPropducts, setInputSearchPropducts] = useState('')

    const searchInput = { inputSearchPropducts, setInputSearchPropducts }
    const categoryArr = { allCategories, currentCategory, setCurrentCategory, lastCategory, setLastCategory }

    useEffect(() => {
        getStoreDetails()
        getCategories()
        checkCarts()
        loadLocation()
        window.addEventListener('storage', getStoreDetails, false)
        return () => {
            window.removeEventListener('storage', getStoreDetails)
        }
    }, [])

    const loadCategories = async () => {
        let tmpCategories = await getLocalStorageController(process.env.REACT_APP_LS_LOCAL_CATEGORIES)
        setAllCategories(tmpCategories)
        setCurrentCategory(tmpCategories[0])
    }

    const getCategories = async () => {
        let tmpCategories = await getLocalStorageController(process.env.REACT_APP_LS_LOCAL_CATEGORIES)
        if (!tmpCategories) await updateLocalStorageController(process.env.REACT_APP_LS_LOCAL_CATEGORIES, await getCategoriesController())
        await loadCategories()
    }

    const getStoreDetails = async () => {
        let tmpStore = await getLocalStorageController(process.env.REACT_APP_LS_STORE)
        if (!tmpStore) {
            tmpStore = await getStoreByStoreIdController(process.env.REACT_APP_STORE_ID)
            updateLocalStorageController(process.env.REACT_APP_LS_STORE, tmpStore)
            setStoreBaseUrl(getStoreBaseUrl())
        }
        setStore(tmpStore)
    }

    const checkCarts = async () => {
        let tmpAllCart = await getLocalStorageController(process.env.REACT_APP_LS_ALL_CARTS)
        if (tmpAllCart === null) updateLocalStorageController(process.env.REACT_APP_LS_ALL_CARTS, {})
        let tmpLocalCart = await getLocalStorageController(process.env.REACT_APP_LS_CURRENT_CART)
        if (tmpLocalCart === null) updateLocalStorageController(process.env.REACT_APP_LS_CURRENT_CART, {})
    }

    const loadLocation = async () => {
        let pos
        navigator.geolocation.getCurrentPosition((position) => {
            pos = {
                lat: position.coords.latitude || 32.109333,
                lng: position.coords.longitude || 34.855499,
            }
        })
        if (!pos) pos = { lat: 32.109333, lng: 34.855499 }
        await setLocalStorageController(process.env.REACT_APP_LS_USER_LOCATION, pos)
    }

    return (
        <div className="mode userMode">
            <Routes>
                <Route path={'store/:id'} element={<StorePage categoryArr={categoryArr} />} />
                <Route path={'home'} element={<Home categoryArr={categoryArr} isProducts={isProducts} setIsProducts={setIsProducts} searchInput={searchInput} />} />
                <Route path={'*'} element={<Navigate to={'home'} />} />
            </Routes>
            <BotBar />
        </div>
    )
}

export default UserMode
