import updateStoreProductService from 'servises/httpReqServices/stores/updateStoreProductService'

export default async (storeProductId, buyPrice, sellPrice, quantity) => {
    const body = {
        storeProductPurchased: parseFloat(buyPrice),
        storeProductPrice: parseFloat(sellPrice),
        storeProductQuantity: parseFloat(quantity),
    }
    const result = await updateStoreProductService(storeProductId, body)
    return result ? result.data : null
}
