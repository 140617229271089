import checkUserController from 'controllers/auth/checkUserController'
import removeCookieController from 'controllers/cookies/removeCookieController'
import { useCallback, useEffect } from 'react'
import { useState } from 'react'
import { BiChevronDown, BiMessageSquareDetail, BiBell, BiShoppingBag, BiLogOut } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import getSessionStorageController from 'controllers/sessionStorage/getSessionStorageController'
import removeSessionStorageController from 'controllers/sessionStorage/removeSessionStorageController'
import getImageStore from 'controllers/images/getImageStore'
import SearchBar from 'utils/tools/searchBar/SearchBar'
import Select from 'utils/tools/select/Select'
import Option from 'utils/tools/option/Option'
import CategoriesScroll from 'utils/layout/categoriesScroll/CategoriesScroll'
import IconBtn from 'utils/tools/iconBtn/IconBtn'
import ProfileBtn from 'utils/tools/profileBtn/ProfileBtn'

const TopBarStore = (props) => {
    return (
        <div className="topBarStore gridOne">
            <div className="leftBtns">
                <ProfileBtn />
                <IconBtn classIcon="fi-rr-bell" classIconHover="fi-sr-bell" amount={2} />
                <IconBtn classIcon="fi-rr-comment" classIconHover="fi-sr-comment" />
                <IconBtn classIcon="fi-rr-shopping-cart" classIconHover="fi-sr-shopping-cart" amount={3} />
            </div>
            <div className="filters flex">
                <SearchBar value={props.inputSearchPropducts} setValue={props.setInputSearchPropducts} />
                <Select value={props.searchBy} setValue={props.setSearchBy}>
                    <Option value="bySearch" text="לפי מוצר" />
                    <Option value="byLogo" text="לפי לוגו" />
                    <Option value="byCompany" text="לפי חברה" />
                </Select>
            </div>
            <IconBtn className="logoBuyWay" image="/assets/images/logos/logo64.png" title="חזור לדף הבית" />
        </div>
    )
}

export default TopBarStore

// import checkUserController from 'controllers/auth/checkUserController'
// import removeCookieController from 'controllers/cookies/removeCookieController'
// import { useCallback, useEffect } from 'react'
// import { useState } from 'react'
// import { BiChevronDown, BiMessageSquareDetail, BiBell, BiShoppingBag, BiLogOut } from 'react-icons/bi'
// import { useNavigate } from 'react-router-dom'
// import getSessionStorageController from 'controllers/sessionStorage/getSessionStorageController'
// import removeSessionStorageController from 'controllers/sessionStorage/removeSessionStorageController'
// import getImageStore from 'controllers/images/getImageStore'
// import SearchBar from 'utils/tools/searchBar/SearchBar'
// import Select from 'utils/tools/select/Select'
// import Option from 'utils/tools/option/Option'
// import CategoriesScroll from 'utils/layout/categoriesScroll/CategoriesScroll'
// import IconBtn from 'utils/tools/iconBtn/IconBtn'

// const TopBarStore = (props) => {
//     const [store, setStore] = useState()
//     const [user, setUser] = useState()
//     const [dropdownActiveClass, setDropdownActiveClass] = useState('')
//     const [isLogged, setIsLogged] = useState()
//     const { searchBy, setSearchBy } = props.searchByInput
//     const { inputSearchPropducts, setInputSearchPropducts } = props.searchInput

//     const navigate = useNavigate()
//     const goToLoginPageHandler = useCallback(() => navigate('/login', { replace: true }), [navigate])

//     useEffect(() => {
//         checkUserHandler()
//         getCurrentStore()
//     }, [])

//     useEffect(() => {}, [inputSearchPropducts])

//     const getCurrentStore = async () => {
//         setStore(await getSessionStorageController(process.env.REACT_APP_SS_STORE))
//     }

//     const checkUserHandler = async () => {
//         const user = await checkUserController(process.env.REACT_APP_COOKIES_AUTH)
//         if (user) setUser(user)
//         else setUser()

//         return user
//     }

//     const onDropdownClickHandler = async () => {
//         const user = await checkUserHandler()
//         if (dropdownActiveClass) setDropdownActiveClass('')
//         else {
//             setDropdownActiveClass(' active')
//             if (user) setIsLogged(user.isLogged)
//         }
//     }

//     const changeModeHandler = async () => {
//         await removeSessionStorageController(process.env.REACT_APP_SS_ORG)
//         await removeSessionStorageController(process.env.REACT_APP_SS_STORE)
//     }

//     const logoutHandler = () => {
//         removeCookieController(process.env.REACT_APP_COOKIES_AUTH)
//         setIsLogged()
//         setDropdownActiveClass('')
//         changeModeHandler()
//         navigate('/home', { replace: true })
//     }
//     return (
//         <div className="topBarStore center">
//             <div className="filters flex">
//                 <SearchBar input={inputSearchPropducts} setInput={setInputSearchPropducts} />
//                 <Select value={searchBy} setValue={setSearchBy}>
//                     <Option value="bySearch" text="לפי מוצר" />
//                     <Option value="byLogo" text="לפי לוגו" />
//                     <Option value="byCompany" text="לפי חברה" />
//                 </Select>
//                 <CategoriesScroll categoryArr={props.categoryArr} isProducts={props.isProducts} />
//             </div>
//             <div className="btns center right">
//                 <div className={'dropDown' + dropdownActiveClass}>
//                     <IconBtn
//                         className="profile"
//                         icon={BiChevronDown}
//                         image={store && store.logo ? getImageStore(store.logo) : '/assets/images/defaults/user.png'}
//                         text={'שלום ' + ((store && store.name) || 'לקוח')}
//                         onClick={onDropdownClickHandler}
//                     />
//                     <div className="options center">
//                         {isLogged ? (
//                             <div className="member">
//                                 <IconBtn text="הגדרות" />
//                                 <IconBtn text="החלף לפרופיל משתמש" onClick={changeModeHandler} />
//                                 <IconBtn text="התנתקות" onClick={() => logoutHandler()} />
//                             </div>
//                         ) : (
//                             <div className="noMember">
//                                 <div className="message">אנא התחבר</div>
//                                 <IconBtn text="התחברות" icon={BiLogOut} onClick={goToLoginPageHandler} />
//                             </div>
//                         )}
//                     </div>
//                 </div>
//                 <IconBtn className="icon" icon={BiBell} amount={0} />
//                 <IconBtn className="icon" icon={BiMessageSquareDetail} />
//                 <IconBtn className="icon" icon={BiShoppingBag} amount={0} onClick={props.setCartClassName} />
//             </div>
//         </div>
//     )
// }

// export default TopBarStore
