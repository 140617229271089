import { useState } from 'react'
import { useEffect } from 'react'
import Loading from '../loading/Loading'
import Bar from './bar/Bar'

const BarGraph = (props) => {
    const [total, setTotal] = useState()

    useEffect(() => {
        if (props.bars && props.bars.length > 0) {
            let tmp = 0

            for (let i = 0; i < props.bars.length; i++) {
                tmp += props.bars[i].amount
            }

            setTotal(tmp)
        }
    }, [props.bars])

    return props.bars && props.title && total ? (
        <div className="tools barGraph">
            {props.bars.map((bar, i) => (
                <Bar key={'bar' + i} total={total} bar={bar} />
            ))}
        </div>
    ) : (
        <Loading />
    )
}

export default BarGraph
