import { useEffect, useState } from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'
import IconBtn from 'utils/tools/iconBtn/IconBtn'
import Input from 'utils/tools/input/Input'
import InputLable from 'utils/tools/inputLable/InputLable'
import Option from 'utils/tools/option/Option'
import Select from 'utils/tools/select/Select'
import Title from 'utils/tools/title/Title'

const RegisterStapOne = (props) => {
    const [isCanNext, setIsCanNext] = useState(false)
    useEffect(() => {
        if (props.fName && props.lName && props.gender && props.birthDate) setIsCanNext(true)
        else setIsCanNext(false)
    }, [props.fName, props.lName, props.gender, props.birthDate])

    return (
        <div className="step flexColumn">
            <Title text="הרשמה (1/3) - נתונים אישיים" />
            <InputLable text="שם פרטי">
                <Input id="fName" autoComplete="fName" value={props.fName} setValue={props.setFName} />
            </InputLable>
            <InputLable text="שם משפחה">
                <Input id="lName" autoComplete="lName" value={props.lName} setValue={props.setLName} />
            </InputLable>
            <InputLable text="מין">
                {/* <Input id="gender" autoComplete="gender" type="select" value={props.gender} setValue={props.setGender} /> */}
                <Select id="gender" value={props.gender} setValue={props.setGender}>
                    <Option default={true} text="" />
                    <Option value={0} text="זכר" />
                    <Option value={1} text="נקבה" />
                    <Option value={2} text="אחר" />
                </Select>
            </InputLable>
            <InputLable text="תאריך לידה">
                <Input id="birthDate" autoComplete="birthDate" type="date" value={props.birthDate} setValue={props.setBirthDate} />
            </InputLable>
            <IconBtn icon={MdKeyboardArrowRight} text="שלב הבא" onClick={props.onNextHandler} unclickable={!isCanNext} />
        </div>
    )
}

export default RegisterStapOne
