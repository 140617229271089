import { useEffect } from 'react'
import { useState } from 'react'
import UserMode from './components/userMode/UserMode'
import './styles/mobileStyle.css'

const AppMobile = () => {
    const [isStoreMode, setIsStoreMode] = useState(false)
    const modeChangeHandler = () => {
        // let tmpCart = getSessionStorageController(process.env.REACT_APP_SS_ORG)
        // tmpCart ? setIsStoreMode(true) : setIsStoreMode(false)
    }

    useEffect(() => {
        window.addEventListener('sessionStorage', modeChangeHandler, false)
        window.dispatchEvent(new Event('sessionStorage'))

        return () => {
            window.removeEventListener('sessionStorage', modeChangeHandler)
        }
    }, [])

    return (
        <div>
            <UserMode />
        </div>
    )
}

export default AppMobile
