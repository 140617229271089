import { useEffect, useRef, useState } from 'react'
import Producer from './producer/Producer'
import { VscChromeClose, VscChevronLeft } from 'react-icons/vsc'
import Track from './track/Track'
import { useNavigate } from 'react-router-dom'
import getLocalStorageController from 'controllers/localStorage/getLocalStorageController'
import getImageProduct from 'controllers/images/getImageProduct'
import IconBtn from 'utils/tools/iconBtn/IconBtn'
import StoreCard from 'utils/tools/storeCard/StoreCard'
import MapWrapper from 'utils/tools/mapWrapper/MapWrapper'
import BarGraph from 'utils/tools/barGraph/BarGraph'
import Loading from 'utils/tools/loading/Loading'
import Rate from 'utils/tools/rate/Rate'
import Hr from 'utils/tools/hr/Hr'

const ProductPopup = (props) => {
    const className = props.className ? ' ' + props.className : ''
    const formFocus = useRef(null)
    const [imgIndex, setImgIndex] = useState(0)
    const [mapClass, setMapClass] = useState('smallMap')
    const [store, setStore] = useState(null)

    useEffect(() => {
        if (props.IsShow && formFocus.current) formFocus.current.focus()
    }, [props.IsShow])

    const addToCartHandler = () => {
        if (props.product && store) {
            goToStore()
            const cart = getLocalStorageController(process.env.REACT_APP_LS_CURRENT_CART)
            // updateProductInCartController(store, props.product, 1, true, true);
            props.setIsShow(false)
        }
    }

    const navigate = useNavigate()
    const goToStore = () => {
        if (store) navigate('/store/' + store.id)
    }

    return props.product ? (
        <form className={props.isMobile ? 'layout productPopup flexColumn mobile' + className : 'layout productPopup flexColumn browser' + className}>
            <div className="sectionOne flex ">
                <div className={props.isMobile ? 'images gridOne' : 'images flexColumn'}>
                    <img src={getImageProduct(props.product.advancedDataItems.images[imgIndex])} className="mainImg" />
                    <div className="side flex">
                        {props.product.advancedDataItems.images.map((img, i) => (
                            <img key={'sideImg' + i} src={getImageProduct(img)} className={i === imgIndex ? 'sideImg clickable active' : 'sideImg clickable'} onClick={() => setImgIndex(i)} />
                        ))}
                    </div>
                </div>
                <div className="details flexColumn">
                    <div className="category">{props.product.categories[0]}</div>
                    <div className="header center">
                        <div className="name">{props.product.name}</div>
                        {Rate(props.product.ratingAverage)}
                    </div>
                    <div className="companyName">{props.product.companyName}</div>
                    <div className="description">{props.product.description}</div>
                    {/* <div className="colors flex">
                        <span className="text">צבע: </span>
                        {'אפור-לבן'}
                        <span className="color__1 clickable"></span>
                        <span className="color__2 clickable"></span>
                    </div> */}
                    <div className="closeLocation flexColumn">
                        <div className="flex">
                            <span className="text">החנות הקרובה אליך: </span>
                            <IconBtn icon={VscChevronLeft} text={'במיקום הנוכחי'} />
                        </div>
                        {store && <StoreCard store={store} onClick={goToStore} />}
                    </div>
                    <IconBtn className="addProductBtn submitBtn" text="הוסף לעגלה" onClick={() => addToCartHandler()} type="submit" />
                </div>
            </div>
            <div className="divider center">
                <Hr />
                או
                <Hr />
            </div>
            <div>
                <div className="mapContainer flexColumn">
                    <div className="title">חנויות קרובות נוספות</div>
                    <div className={mapClass}>
                        {mapClass === 'bigMap' && (
                            <div className="icon">
                                <IconBtn icon={VscChromeClose} onClick={() => setMapClass('smallMap')} />
                            </div>
                        )}
                        <MapWrapper product={props.product} isStoreBarClose={false} setOutStore={setStore} />
                    </div>
                    <IconBtn className="storeOnMapBtn" text="הצג חנויות על המפה" onClick={() => setMapClass('bigMap')} />
                </div>
            </div>
            <div>
                <Producer title="מותג / יצרן" text="מוצרים נוספים של המותג" product={props.product} onClick={() => {}} />
                <Track onClick={() => {}} />
            </div>
            <div className="sectionOne flex">
                <BarGraph
                    title="תגובות על המוצר"
                    bars={[
                        { text: '5 כוכבים', amount: 26 },
                        { text: '4 כוכבים', amount: 7 },
                        { text: '3 כוכבים', amount: 5 },
                        { text: '2 כוכבים', amount: 3 },
                        { text: 'כוכב אחד', amount: 1 },
                    ]}
                />
            </div>
        </form>
    ) : (
        <Loading />
    )
}

export default ProductPopup
