import { loginController } from 'controllers/controllers'
import registerUserController from 'controllers/users/registerUserController'
import verificationRequestPhoneController from 'controllers/verification/verificationRequestPhoneController'
import { startAutoRefreshToken } from 'events/autoRefreshToken'
import { startExpireCountdown } from 'events/expireCountdown'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const NDPRegister = (props) => {
    const [fName, setFName] = useState('')
    const [lName, setLName] = useState('')
    const [gender, setGender] = useState(0)
    const [birthDate, setBirthDate] = useState('')

    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [phoneCode, setPhoneCode] = useState('')
    const [phoneValidate, setPhoneValidate] = useState(false)

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [isPassword, setIsPassword] = useState(false)

    const navigate = useNavigate()

    const sendCodeHandelr = async () => {
        let tmp = await verificationRequestPhoneController(phone)
        if (tmp === true) setPhoneValidate(true)
    }

    const submit = async () => {
        if (!phoneValidate) return

        const tmpObj = {
            username: username,
            password: password,
            displayName: fName + ' ' + lName,
            firstname: fName,
            lastname: lName,
            email: email,
            description: '',
            type: 'user',
            phone: phone,
            birthDate: birthDate || '',
            gender: Number(gender),
            phoneCode: phoneCode,
        }
        let register = await registerUserController(tmpObj)
        if (register) await onLogginHandler()
    }

    const onLogginHandler = async () => {
        if (username && password) {
            const result = await loginController(username, password)
            if (result) {
                window.dispatchEvent(startAutoRefreshToken)
                window.dispatchEvent(startExpireCountdown)
                navigate(`../NDPStore/${fName}/${lName}/${phone}`)
            } else console.log("can't logged in!")
        } else console.log('fill username and password')
    }

    return (
        <form
            className="NDPRegister"
            onSubmit={(e) => {
                e.preventDefault()
                submit()
            }}
        >
            <strong>נתונים אישיים</strong>
            <div>
                <span>שם פרטי</span>
                <input required value={fName} onChange={(e) => setFName(e.target.value)} id="firstname" name="firstname" type="text" />
            </div>
            <div>
                <span>שם משפחה</span>
                <input required value={lName} onChange={(e) => setLName(e.target.value)} id="lastname" name="lastname" type="text" />
            </div>
            <div>
                <span>מין</span>
                <select required value={gender} onChange={(e) => setGender(e.target.value)}>
                    <option>בחר מין</option>
                    <option value={0}>זכר</option>
                    <option value={1}>נקבה</option>
                    <option value={2}>אחר</option>
                </select>
            </div>
            <div>
                <span>תאריך לידה</span>
                <input required value={birthDate} onChange={(e) => setBirthDate(e.target.value)} id="birthDate" name="birthDate" type="date" />
            </div>

            <strong>נתוני אימות</strong>
            <div>
                <span>מספר פלאפון</span>
                <input required value={phone} onChange={(e) => setPhone(e.target.value)} id="phone" name="phone" type="tel" />
            </div>
            <div>
                <span>אימייל</span>
                <input required value={email} onChange={(e) => setEmail(e.target.value)} id="email" name="email" type="email" />
            </div>
            <div>
                <div className="btn clickable" onClick={sendCodeHandelr}>
                    שלח אימות
                </div>
                <input required value={phoneCode} onChange={(e) => setPhoneCode(e.target.value)} id="phoneCode" name="phoneCode" type="number" />
            </div>

            <strong>נתוני התחברות</strong>
            <div>
                <span>שם משתמש</span>
                <input required value={username} onChange={(e) => setUsername(e.target.value)} id="username" name="username" type="username" />
            </div>
            <div>
                <span>סיסמה</span>
                <input type="checkbox" name="isPassword" id="isPassword" value={isPassword} onChange={() => setIsPassword((pre) => !pre)} />
                <input required value={password} onChange={(e) => setPassword(e.target.value)} id="password" name="password" type={!isPassword ? 'password' : 'text'} />
            </div>

            <button className="clickable" type="submit">
                שמור
            </button>
        </form>
    )
}

export default NDPRegister
