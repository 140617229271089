import getImageLogo from 'controllers/images/getImageLogo'
import { IoMdArrowDropleft } from 'react-icons/io'
import IconBtn from 'utils/tools/iconBtn/IconBtn'

const Producer = (props) => {
    return (
        <div className="tools producer flexColumn">
            <div className="title">{props.title}</div>
            <div className="logoWrapper flex">
                <img src={props.product.logo ? getImageLogo(props.product.logo) : '/assets/images/logo.svg'} className="logo" />
                <div className="details">
                    <div className="companyName">{props.product.companyName}</div>
                    <IconBtn text={props.text} onClick={props.onClick} icon={IoMdArrowDropleft} />
                </div>
            </div>
        </div>
    )
}

export default Producer
