import IconBtn from 'utils/tools/iconBtn/IconBtn'

const TwoBtnSwitch = (props) => {
    const className = props.className ? ' ' + props.className : ''

    return (
        <div className={'tools twoBtnSwitch gridOne' + className}>
            <div className={!props.flag ? 'btnBg active' : 'btnBg'}></div>
            <div className="btns flex">
                <IconBtn text={props.firstText} onClick={() => props.setFlag(true)} className={props.flag ? 'active' : 'unactive'} />
                <IconBtn text={props.secondText} onClick={() => props.setFlag(false)} className={!props.flag ? 'active' : 'unactive'} />
            </div>
        </div>
    )
}

export default TwoBtnSwitch
