import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import checkUserController from 'controllers/auth/checkUserController'
import IconBtn from 'utils/tools/iconBtn/IconBtn'
import NotLoggedInPopup from 'utils/tools/notLoggedInPopup/NotLoggedInPopup'
// import { BiHomeAlt, BiUserCircle, BiMessageSquareDetail, BiBell, BiHeart, BiCartAlt } from 'react-icons/bi'

const SideBarUser = (props) => {
    const className = props.className ? ' ' + props.className : ''
    const location = useLocation()
    const [currentPage, setCurrentPage] = useState()
    const [isShowPopup, setIsShowPopup] = useState(false)

    useEffect(() => {
        setCurrentPage(location.pathname)
    })

    const navigate = useNavigate()

    const onClick = async (page, isNeedLogin = false, isNeedMember = false) => {
        if (isNeedLogin) {
            const user = await checkUserController()
            if (!user || !user.isLogged || (isNeedMember && !user.isMember)) {
                setIsShowPopup(true)
                return
            }
        }
        navigate(page)
    }

    return (
        <div className={'sideBarUser' + className}>
            <IconBtn
                // icon={BiHomeAlt} //react
                classIcon="fi-rr-home" //regular
                classIconHover="fi-sr-home" //regular
                text="בית"
                onClick={() => onClick('home')}
                className={currentPage === '/home' ? 'sideIcon active home' : 'sideIcon home'}
                active={currentPage === `/home` ? true : false}
            />
            <IconBtn
                // icon={BiUserCircle}//react
                classIcon="fi-rr-user" //regular
                classIconHover="fi-sr-user" //regular
                text="איזור אישי"
                onClick={() => onClick('profile', true)}
                className={currentPage === '/profile' ? 'sideIcon active' : 'sideIcon'}
                active={currentPage === `/profile` ? true : false}
            />
            <IconBtn
                // icon={BiMessageSquareDetail}
                classIcon="fi-rr-comment" //regular
                classIconHover="fi-sr-comment" //regular
                text="הודעות"
                onClick={() => onClick('messages', true)}
                className={currentPage === '/messages' ? 'sideIcon active' : 'sideIcon'}
                active={currentPage === `/messages` ? true : false}
            />
            <IconBtn
                // icon={BiBell} //react
                classIcon="fi-rr-bell" //regular
                classIconHover="fi-sr-bell" //regular
                text="הזמנות פעילות"
                onClick={() => onClick('reservations', true)}
                className={currentPage === '/reservations' ? 'sideIcon active' : 'sideIcon'}
                active={currentPage === `/reservations` ? true : false}
            />
            <IconBtn
                // icon={BiHeart} //react
                classIcon="fi-rr-heart" //regular
                classIconHover="fi-sr-heart" //regular
                text="מה שאהבתי"
                onClick={() => onClick('likes', true)}
                className={currentPage === '/likes' ? 'sideIcon active' : 'sideIcon'}
                active={currentPage === `/likes` ? true : false}
            />
            <IconBtn
                // icon={BiCartAlt} //react
                classIcon="fi-rr-checkbox" //regular
                classIconHover="fi-sr-checkbox" //regular
                text="רשימת קניות"
                onClick={() => onClick('cart', true)}
                className={currentPage === '/cart' ? 'sideIcon active' : 'sideIcon'}
                active={currentPage === `/cart` ? true : false}
            />
            <NotLoggedInPopup isShow={isShowPopup} setIsShow={setIsShowPopup} />
        </div>
    )
}

export default SideBarUser
