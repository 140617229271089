import Title from "utils/tools/title/Title"

const CartPage = (props) => {
    return (
        <div className="cartPage">
            <Title text="רשימת קניות" />
        </div>
    )
}

export default CartPage
