import { VscListSelection } from 'react-icons/vsc'
import { IoBarcodeOutline } from 'react-icons/io5'
import { CgSearchLoading } from 'react-icons/cg'
import IconBtn from 'utils/tools/iconBtn/IconBtn'
import TwoBtnSwitch from 'utils/layout/twoBtnSwitch/TwoBtnSwitch'

const TopHome = (props) => {
    const onMenuClickHandler = () => {
        console.log('menu')
    }
    const onSearchClickHandler = () => {}
    const onScanClickHandler = () => {}

    return (
        <div className={props.isScrolled ? 'topHome mobile utils gridOne scrolled' : 'topHome mobile utils gridOne'} onClick={() => props.setIsScrolled(false)}>
            <div className="rightBtns flex">
                <IconBtn className="scan" icon={IoBarcodeOutline} onClick={onScanClickHandler} />
                <IconBtn className="search" icon={CgSearchLoading} onClick={onSearchClickHandler} />
            </div>
            <img src="/assets/images/logos/logo.svg" className="logo" />
            <IconBtn className="menu" icon={VscListSelection} onClick={onMenuClickHandler} />
            <TwoBtnSwitch flag={props.isSwitch} setFlag={props.setIsSwitch} firstText="מוצרים" secondText="חנויות" />
        </div>
    )
}

export default TopHome
