import { useEffect, useState } from 'react'

const Textarea = (props) => {
    const [className, setClassName] = useState(props.className || '')
    useEffect(() => {
        if (!props.value && props.validate && !props.notRequired)
            if (props.className) setClassName(props.className + ' outLineDangerColor')
            else setClassName('outLineDangerColor')
        else if (props.validate) {
            if (props.className) setClassName(props.className + ' outLineSafeColor')
            else setClassName('outLineSafeColor')
        } else setClassName(props.className || '')
    }, [props.value])

    return (
        <textarea type={props.type || 'text'} value={props.value} onChange={(e) => props.setValue(e.target.value)} placeholder={props.placeholder} className={'tools textarea ' + className} disabled={props.isDisabled} />
    )
}

export default Textarea
