import { useState } from 'react'

const IconBtn = (props) => {
    const className = props.className ? ' ' + props.className : ''
    const imgClassName = props.imgClassName ? ' ' + props.imgClassName : ''
    const unclickable = props.unclickable ? ' unclickable' : ' clickable'

    const [isHover, setIsHover] = useState(false)

    return (
        <button
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            className={'tools iconBtn center' + unclickable + className}
            onMouseDown={() => {
                props.onMouseDown && props.onMouseDown()
            }}
            onClick={(e) => {
                if (props.onClick) {
                    e.preventDefault()
                    props.onClick()
                }
            }}
            title={props.title || props.text}
            type={props.type || ''}
        >
            {props.active && <span className="iconBtn__active"></span>}
            {props.icon && (
                <div className="tools iconBtn__icon gridOne">
                    {props.amount > 0 && <span className="amount center">{props.amount}</span>}
                    {isHover && props.secondIcon ? <props.secondIcon /> : <props.icon />}
                </div>
            )}
            {props.classIcon && (
                <div className="tools iconBtn__icon gridOne">
                    {props.amount > 0 && <span className="amount center">{props.amount}</span>}
                    {isHover && props.classIconHover ? <i className={'fi ' + props.classIconHover}></i> : <i className={'fi ' + props.classIcon}></i>}
                </div>
            )}
            {props.text && <span className="text">{props.text}</span>}
            {props.image && <img className={'image' + imgClassName} src={props.image} />}
        </button>
    )
}

export default IconBtn
