import { useRef } from 'react'
import { useState } from 'react'

const DraggableUploader = (props) => {
    const [dragActive, setDragActive] = useState(false)
    const inputRef = useRef(null)

    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true)
        } else if (e.type === 'dragleave') {
            setDragActive(false)
        }
    }

    const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files[0])
        }
    }

    const handleChange = (e) => {
        e.preventDefault()
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files[0])
        }
    }

    const handleFile = (file) => {
        if (file) props.setFile(file)
    }

    return (
        <label className="draggableUploader tools gridOne" onDragEnter={handleDrag}>
            <input ref={inputRef} type="file" accept="image/*" name="image" id="file" onChange={handleChange} />
            <div className="btns">
                <div className="text">{props.text}</div>
                <div className="btnText">{props.btnText}</div>
            </div>
            {dragActive && (
                <div id="dragFile" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
                    <i className={'fi fi-rr-cloud-upload'}></i>
                </div>
            )}
        </label>
    )
}

export default DraggableUploader
