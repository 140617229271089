import ReactLoading from 'react-loading';
const types = ['balls', 'bars', 'bubbles', 'cubes', 'cylon', 'spin', 'spinningBubbles', 'spokes'];
const Loading = (props) => {
  let className = props.className ? ' ' + props.className : '';

  return (
    <div className={'tools loading center' + className}>
      <ReactLoading type={types[1]} color="#3698ff" />
    </div>
  );
};

export default Loading;
