import SearchBar from 'utils/tools/searchBar/SearchBar'
import IconBtn from 'utils/tools/iconBtn/IconBtn'
import { useLocation } from 'react-router-dom'
import ProfileBtn from 'utils/tools/profileBtn/ProfileBtn'
import TwoBtnSwitch from 'utils/layout/twoBtnSwitch/TwoBtnSwitch'

const TopBarUser = (props) => {
    const location = useLocation()

    return (
        <div className="topBarUser gridOne">
            <div className="leftBtns">
                <ProfileBtn />
                <IconBtn
                    // icon={BiBell}
                    classIcon="fi-rr-bell" //regular
                    classIconHover="fi-sr-bell" //regular
                    amount={2}
                />
                <IconBtn
                    // icon={BiMessageSquareDetail}
                    classIcon="fi-rr-comment" //regular
                    classIconHover="fi-sr-comment" //regular
                />
                <IconBtn
                    // icon={BiCartAlt}
                    classIcon="fi-rr-shopping-cart" //regular
                    classIconHover="fi-sr-shopping-cart" //regular
                    amount={3}
                />
            </div>
            {/* value={inputSearch} setValue={setInputSearch} */}
            <SearchBar className={location.pathname === '/home' && props.isScrolled && 'hide'} placeholder="חפשו בתוך החנות..." />
            {location.pathname === '/home' && <TwoBtnSwitch className={!props.isScrolled && 'scrolled'} setFlag={props.setIsProducts} flag={props.isProducts} firstText="מוצרים" secondText="חנויות" />}
            <IconBtn className="logoBuyWay" image="/assets/images/logos/logo64.png" title="חזור לדף הבית" />
        </div>
    )
}

export default TopBarUser
