import { getProductsByCategoryIdController, getProductsController } from 'controllers/controllers'
import getLocalStorageController from 'controllers/localStorage/getLocalStorageController'
import getUserProductsController from 'controllers/products/getUserProductsController'
import { useEffect, useState } from 'react'
import CategoriesScroll from 'utils/layout/categoriesScroll/CategoriesScroll'
import GridProducts from 'utils/layout/gridProducts/GridProducts'
import ProductPopup from 'utils/layout/productPopup/ProductPopup'
import ProductsScroll from 'utils/layout/productsScroll/ProductScroll'
import TwoRoudsProducts from 'utils/layout/twoRoudsProducts/TwoRoudsProducts'
import DragPopup from 'utils/tools/dragPopup/DragPopup'
import TopHome from './topHome/TopHome'

let isLoad = false

const Home = (props) => {
    const [isShowDragPopup, setIsShowDragPopup] = useState(false)
    const [isSwitch, setIsSwitch] = useState(false)
    const [allProducts, setAllProducts] = useState(null)
    const [productPopup, setProductPopup] = useState(null)
    const { allCategories, currentCategory, setCurrentCategory, lastCategory, setLastCategory } = props.categoryArr
    const [isScrolled, setIsScrolled] = useState(false)

    useEffect(() => {
        if (currentCategory && currentCategory.id) {
            loadProducts(1)
        }
    }, [currentCategory])

    const productPopupHandler = (isShow, product) => {
        setProductPopup(product)
    }

    const onScrollHandler = (e) => {
        if (e.target.scrollTop > 0) setIsScrolled(true)
        else if (e.target.scrollTop === 0) setIsScrolled(false)
    }

    const loadProducts = async (pageNum) => {
        let tmpProducts
        //only products
        if (currentCategory && (currentCategory.id === 'ctgy_bIZqbE17701lqCW8770' || currentCategory.parentIds.includes('ctgy_bIZqbE17701lqCW8770')))
            tmpProducts = await getProductsByCategoryIdController(currentCategory.id, pageNum)
        else tmpProducts = await getProductsController(pageNum)

        if (tmpProducts) {
            let tmpUserProducts
            let userLocation = await getLocalStorageController(process.env.REACT_APP_LS_USER_LOCATION)
            tmpUserProducts = await getUserProductsController(tmpProducts.continuationHash, userLocation, pageNum)
            tmpProducts.list.forEach((product, i) => {
                if (tmpUserProducts[i] && product.id === tmpUserProducts[i].productId) {
                    product.isSale = tmpUserProducts[i].isSale
                    product.like = tmpUserProducts[i].like
                    product.price = tmpUserProducts[i].price
                    product.salePrice = tmpUserProducts[i].salePrice
                }
            })
        }

        if (pageNum === 1) setAllProducts(tmpProducts)
        else
            setAllProducts((pre) => {
                if (pre) {
                    pre.list = [...pre.list, ...tmpProducts.list]
                    pre.currentPage = tmpProducts.currentPage
                    pre.continuationHash = tmpProducts.continuationHash
                } else pre = tmpProducts

                return { ...pre }
            })
        if (tmpProducts && tmpProducts.currentPage < tmpProducts.totalPages) isLoad = false
    }

    return (
        allProducts && (
            <header className="mobile home page" onScroll={onScrollHandler}>
                <TopHome isSwitch={isSwitch} setIsSwitch={setIsSwitch} isScrolled={isScrolled} setIsScrolled={setIsScrolled} />
                <CategoriesScroll className={isScrolled && 'scrolled'} categoryArr={props.categoryArr} />
                <ProductsScroll productType="home" className={isScrolled && 'scrolled'} title="מוצרים בשבילך" products={allProducts.list} setProducts={setAllProducts} setProductPopup={productPopupHandler} />
                {/* <GridProducts productType="home" title="מוצרים פופולאריים" products={allProducts.list} setProducts={setAllProducts} setProductPopup={productPopupHandler} /> */}
                <TwoRoudsProducts productType="home" title="מוצרים פופולאריים" products={allProducts.list} setProducts={setAllProducts} setProductPopup={productPopupHandler} />
                <DragPopup isBot={true} isShow={productPopup} setIsShow={setProductPopup}>
                    {/* <img src="/assets/images/bgs/saleBg1.png" /> */}
                    <ProductPopup isMobile={true} product={productPopup} IsShow={productPopup} setIsShow={setProductPopup} />
                </DragPopup>
            </header>
        )
    )
}

export default Home
