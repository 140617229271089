import Title from 'utils/tools/title/Title'

const MyOrdersPage = (props) => {
    return (
        <div className="myOrdersPage storePage gridOne">
            <div className="bg"></div>
            <Title text="הזמנות שלי" />
        </div>
    )
}

export default MyOrdersPage
