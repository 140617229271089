import { useState, useEffect } from 'react'
import getSessionStorageController from 'controllers/sessionStorage/getSessionStorageController'
import './styles/browserStyle.css'
import StoreProfile from './components/storeProfile/StoreProfilePage'
import UserProfile from './components/userProfile/UserProfile'

const AppBrowser = () => {
    const [isStoreMode, setIsStoreMode] = useState(false)
    const modeChangeHandler = () => {
        let store = getSessionStorageController(process.env.REACT_APP_SS_STORE)
        store ? setIsStoreMode(true) : setIsStoreMode(false)
    }

    useEffect(() => {
        window.addEventListener('sessionStorage', modeChangeHandler, false)
        window.dispatchEvent(new Event('sessionStorage'))
        return () => {
            window.removeEventListener('sessionStorage', modeChangeHandler)
        }
    }, [])

    return (
        <div>
            {isStoreMode ? <StoreProfile /> : <UserProfile />}
            <span className="copyRight">By Nehorai Peisach</span>
        </div>
    )
}

export default AppBrowser
