import addStoreProductService from 'servises/httpReqServices/stores/addStoreProductService'
import readLocalDataService from 'servises/local/cookies/readCookieService'

export default async (storeId, productId, buyPrice, sellPrice, quantity) => {
    const body = {
        masterProductId: productId,
        storeId: storeId,
        // storeProductActive: true,
        // storeProductValidated: true,
        // storeProductPrice: 339,
        // storeProductQuantity: 3,
        // storeProductMinimumQuantity: 0,
        // storeProductMaximumQuantity: 1000,
        // storeProductAmountInStock: 1000,
        // storeProductAmountOnShelf: 1000,
        // storeProductViews: 0,
        // storeProductSold: 0,
        // storeProductPurchased: 0,
        // location: {
        //     latitude: 32.109333,
        //     longitude: 34.855499,
        // },
        storeProductActive: true,
        storeProductValidated: true,
        storeProductPrice: parseFloat(sellPrice),
        storeProductQuantity: parseInt(quantity),
        storeProductMinimumQuantity: Math.ceil(parseFloat(quantity) * 0.1),
        storeProductMaximumQuantity: parseInt(parseFloat(quantity) * 10),
        storeProductAmountInStock: 0,
        storeProductAmountOnShelf: 0,
        storeProductViews: 0,
        storeProductSold: 0,
        storeProductPurchased: parseFloat(buyPrice),
        location: {
            latitude: 0,
            longitude: 0,
        },
    }

    const result = await addStoreProductService(body)
    return result ? result.data : null
}
