import { useEffect, useState } from 'react'
import { VscChromeClose } from 'react-icons/vsc'
import IconBtn from 'utils/tools/iconBtn/IconBtn'

const FullPopup = (props) => {
    const className = props.className ? ' ' + props.className : ''
    const [addedClass, setAddedClass] = useState('close')

    useEffect(() => {
        setAddedClass(props.isShow ? '' : 'close')
    }, [props.isShow])

    const closeHandler = () => {
        setAddedClass('close')
        props.setIsShow(false)
    }

    return (
        <div className={`fullPopup center${className} ${addedClass}`}>
            <div className="content">
                <div className="icon">
                    <IconBtn icon={VscChromeClose} onClick={closeHandler} />
                </div>
                <div className="children">{props.children}</div>
            </div>
            <div className="background" onClick={closeHandler}></div>
        </div>
    )
}

export default FullPopup
