import checkUserController from 'controllers/auth/checkUserController'
import { useEffect, useState } from 'react'
import { BiBell, BiCartAlt, BiFolderPlus, BiHeart, BiHomeAlt, BiMessageSquareDetail, BiUserCircle } from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom'
import IconBtn from 'utils/tools/iconBtn/IconBtn'

const BotBar = (props) => {
    const className = props.className ? ' ' + props.className : ''
    const location = useLocation()
    const [currentPage, setCurrentPage] = useState()

    useEffect(() => {
        setCurrentPage(location.pathname)
    })

    const navigate = useNavigate()

    const onClick = async (page, isNeedLogin = false) => {
        // if (isNeedLogin) {
        //     const user = await checkUserController()
        //     if (!user || !user.isLogged) {
        //         return
        //     }
        // }
        navigate(page)
    }

    return (
        <nav className={'layout botBar flex' + className}>
            <IconBtn icon={BiCartAlt} text="עגלה" onClick={() => onClick('cart', true)} className={currentPage === '/cart' ? 'active' : ''} active={currentPage === `/cart` ? true : false} />
            <IconBtn icon={BiBell} text="התראות" onClick={() => onClick('notifications', true)} className={currentPage === '/notifications' ? 'active' : ''} active={currentPage === `/notifications` ? true : false} />
            <IconBtn icon={BiMessageSquareDetail} text="הודעות" onClick={() => onClick('messages', true)} className={currentPage === '/messages' ? 'active' : ''} active={currentPage === `/messages` ? true : false} />
            <IconBtn icon={BiUserCircle} text="איזור אישי" onClick={() => onClick('profile', true)} className={currentPage === '/profile' ? 'active' : ''} active={currentPage === `/profile` ? true : false} />
            <IconBtn icon={BiHomeAlt} text="בית" onClick={() => onClick('home')} className={currentPage === '/home' ? 'active home' : 'home'} active={currentPage === `/home` ? true : false} />
        </nav>
    )
}

export default BotBar
