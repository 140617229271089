import addStoreLogoService from 'servises/httpReqServices/stores/addStoreLogoService'
export default async (img, orgId, storeId) => {
    // const reader = new FileReader();
    // const file = new File([img], img.name, {type: 'image/png'})
    const formData = new FormData()
    // await formData.append("files", fs.createReadStream(URL.createObjectURL(img)) );

    console.log(formData.getAll('files'))
    const result = await addStoreLogoService(formData, orgId, storeId)
    return result ? result.data : null
}
