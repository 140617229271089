import { useEffect } from 'react'
import { useRef } from 'react'
import { VscChromeClose } from 'react-icons/vsc'
import IconBtn from '../iconBtn/IconBtn'

const DragPopup = (props) => {
    const contentWrapperRef = useRef()
    const contentRef = useRef()

    useEffect(() => {
        if (contentWrapperRef.current && contentWrapperRef.current.style.height === '') {
            contentWrapperRef.current.style.marginTop = `${props.topPrecent || 6}vh`
            contentWrapperRef.current.style.height = `calc(100vh - ${props.topPrecent || 6}vh)`
        }
    }, [contentWrapperRef.current])

    const onTouchStartHansder = (e) => {
        contentWrapperRef.current.style.transition = `0s`
        contentWrapperRef.current.style.animation = ''
        contentRef.current.style.transform = 'scaleX(1.5)'
    }
    const onTouchEndHansder = (e) => {
        let screan = e.view.screen
        let touch = e.changedTouches[0]
        let y = touch.clientY

        contentWrapperRef.current.style.transition = ``
        contentRef.current.style.transform = ''
        contentWrapperRef.current.style.animation = '0.5s bounce'

        if (y < screan.height / 2) {
            contentWrapperRef.current.style.marginTop = `${props.topPrecent || 6}vh`
            contentWrapperRef.current.style.height = `calc(100vh - ${props.topPrecent || 6}vh)`
        } else if (y > screan.height / 2 && y < screan.height - 50 && props.isBot) {
            contentWrapperRef.current.style.marginTop = `calc(100vh - ${props.botPrecent || 50}vh)`
            contentWrapperRef.current.style.height = `calc(${props.botPrecent || 50}vh)`
        } else {
            contentWrapperRef.current.style.marginTop = '100vh'
            contentWrapperRef.current.style.height = '0vh'
            props.setIsShow()
        }
    }

    const onTouchMoveHansder = (e) => {
        let touch = e.touches[0]
        let y = touch.clientY

        if (y > 0) {
            contentWrapperRef.current.style.marginTop = `${y}px`
            contentWrapperRef.current.style.height = `calc(100vh - ${y}px)`
        }
    }

    return (
        <div className={props.isShow ? 'tools dragPopup fullPage gridOne' : 'tools dragPopup fullPage gridOne close'}>
            <div className="contentWrapper gridOne" ref={contentWrapperRef}>
                <div className="content">{props.children}</div>
                <div className="dragSignWrapper gridOne" onTouchStart={onTouchStartHansder} onTouchEnd={onTouchEndHansder} onTouchMove={onTouchMoveHansder}>
                    <IconBtn className="closeBtn " icon={VscChromeClose} text="סגור" onClick={() => props.setIsShow()} />
                    <div className="dragSign" ref={contentRef}></div>
                </div>
            </div>
        </div>
    )
}

export default DragPopup
