import { useEffect, useState } from 'react'
import { TiStarFullOutline, TiStarHalfOutline, TiStarOutline } from 'react-icons/ti'

const Rate = (rate, minRate = 0, maxRate = 10) => {
    if (rate < minRate || rate > maxRate) return
    let leftRate = maxRate - rate
    const stars = []

    while (rate > 1) {
        stars.push(TiStarFullOutline)
        rate -= 2
    }
    rate % 2 === 1 && stars.push(TiStarHalfOutline)
    while (leftRate > 1) {
        stars.push(TiStarOutline)
        leftRate -= 2
    }

    return (
        <div className="tools rate">
            {stars.map((Star, i) => (
                <Star key={'star' + i} />
            ))}
        </div>
    )
}

export default Rate
