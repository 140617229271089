// import readLocalDataService from 'servises/local/cookies/readCookieService'
import { postReqAllowed } from '../httpReq'

const api = 'products/search'
export default async (body) => {
    // const data = await readLocalDataService()
    // const headers = {
    // 'x-stelace-organization-id': data.orgId,
    // }
    const result = await postReqAllowed(api, body)
    return result
}
