import { getCategoriesController } from 'controllers/controllers'
import createOrganizationController from 'controllers/organizations/createOrganizationController'
import addStoreController from 'controllers/stores/addStoreController'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FullPopup from 'utils/layout/fullPopup/FullPopup'
import PlaceMap from 'utils/tools/placeMap/PlaceMap'

const NDPStore = (props) => {
    const { fName, lName, phone } = useParams()

    const [orgName, setOrgName] = useState('')
    const [email, setEmail] = useState('')
    const [idNum, setIdNum] = useState('')
    const [taxType, setTaxType] = useState(0)

    const [storeName, setStoreName] = useState('')
    const [isStoreNameChanged, setIsStoreNameChanged] = useState(false)
    const [storeCategories, setStoreCategories] = useState([])
    const [parentSelectedCaregory, setParentSelectedCaregory] = useState()
    const [parentCategory, setParentCategory] = useState()
    const [sonSelectedCategory, setSonSelectedCategory] = useState()
    const [logo, setLogo] = useState()
    const [needsDeliveryServices, setNeedsDeliveryServices] = useState(false)
    const [offersDelivery, setOffersDelivery] = useState(false)
    const [allowsPickup, setAllowsPickup] = useState(false)
    const [isOnline, setIsOnline] = useState(false)
    const [storeType, setStoreType] = useState('')
    const [managedStock, setManagedStock] = useState(false)
    const [place, setPlace] = useState()

    const [isPopup, setIsPopup] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        loadCategories()
    }, [])

    useEffect(() => {
        show()
    }, [place])

    const show = async () => {
        if (place) {
            console.log(place)
            console.log()
        }
    }
    const loadCategories = async () => {
        const tmp = await getCategoriesController()
        console.log(tmp)
        setStoreCategories(tmp[1].sonsImmediate)
    }

    useEffect(() => {
        if (!isStoreNameChanged) setStoreName(orgName)
    }, [orgName])

    const setStoreNameHander = (value) => {
        setStoreName(value)
        if (!isStoreNameChanged) setIsStoreNameChanged(true)
    }

    const locationHandelr = () => {
        setIsPopup(true)
    }

    const setParentSelectedCaregoryHandler = (id) => {
        setParentSelectedCaregory(id)
        setParentCategory(storeCategories.filter((x) => x.id === id)[0])
    }

    const submit = async () => {
        const tmpOrg = {
            displayName: orgName,
            firstname: fName,
            lastname: lName,
            description: '',
            type: 'organization',
            phone: phone,
            idNumber: idNum,
            taxType: 'SOLE_PROPRIETOR',
        }
        if (email) tmpOrg.email = email
        const org = await createOrganizationController(tmpOrg)
        console.log(org)
        if (org) {
            const tmpStore = {
                name: orgName,
                category: sonSelectedCategory,
                logo: logo ? logo.name : null,
                needsDeliveryServices: needsDeliveryServices,
                offersDelivery: offersDelivery,
                allowsPickup: allowsPickup,
                storeType: storeType,
                isOnline: isOnline,
                managedStock: managedStock,
                address: {
                    telephone: phone,
                },
                location: {
                    latitude: place.lat,
                    longitude: place.lng,
                },
                regularHours: {
                    sunday: '08:00-20:00',
                    monday: '08:00-20:00',
                    tuesday: '08:00-20:00',
                    wednesday: '08:00-20:00',
                    thursday: '08:00-20:00',
                    friday: '08:00-20:00',
                    saturday: '08:00-20:00',
                },
                specialHours: {
                    '20/12': '09:00-21:00',
                    '20/06': '07:00-22:30',
                },
            }
            if (place.city) tmpStore.address.city = place.city
            if (place.zip) tmpStore.address.zip = place.zip
            if (place.country) tmpStore.address.country = place.country
            if (place.street) tmpStore.address.street = place.street
            if (place.state) tmpStore.address.state = place.state
            if (place.name) tmpStore.address.name = place.name

            let store = await addStoreController(tmpStore, org.id)
            console.log(store)

            if (store) {
                navigate(`../home`)
                //     let formData = new FormData()
                //     formData.append('files', logo)
                //     let logoRes = await setLogoController(formData, 'org_0RBkn7E1tAB1pNyW8tAB', 'ast_zRAesfE16Rb1obFw86Rb')
                //     if (logoRes) navigate('../home')
            }
        }
    }

    return (
        <div className="NDPStore">
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    submit()
                }}
            >
                <strong>נתוני חברה</strong>
                <div>
                    <span>שם</span>
                    <input required value={orgName} onChange={(e) => setOrgName(e.target.value)} id="orgName" name="orgName" type="text" />
                </div>
                <div>
                    <span>אימייל (אופציונלי)</span>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} id="email" name="email" type="email" />
                </div>
                <div>
                    <span>מספר תז/חפ</span>
                    <input required value={idNum} onChange={(e) => setIdNum(e.target.value)} id="idNum" name="idNum" type="number" />
                </div>
                <div>
                    <span>סוג רישום</span>
                    <select required value={taxType} onChange={(e) => setTaxType(e.target.value)}>
                        <option>בחר רישום</option>
                        <option value={'SOLE_PROPRIETOR'}>עוסק פטור</option>
                        <option value={'STANDARD_COMPANY'}>עוסק מורשה</option>
                        <option value={'CORPORATION'}>חברה בע"מ</option>
                        {/* <option value={''}>חברה ללא מטרות ריווח</option> */}
                    </select>
                </div>

                <strong>נתוני סניף</strong>
                <div>
                    <span>שם</span>
                    <input required value={storeName} onChange={(e) => setStoreNameHander(e.target.value)} id="storeName" name="storeName" type="text" />
                </div>
                <div>
                    <span>קטגוריה</span>
                    <select required value={parentSelectedCaregory} onChange={(e) => setParentSelectedCaregoryHandler(e.target.value)} id="selectedCaregory" name="selectedCaregory">
                        <option>בחר קטגוריה</option>
                        {storeCategories.map((x) => (
                            <option key={'storeCategories' + x.id} value={x.id}>
                                {x.name}
                            </option>
                        ))}
                    </select>
                    {parentSelectedCaregory && (
                        <select required value={sonSelectedCategory} onChange={(e) => setSonSelectedCategory(e.target.value)} id="sonSelectedCategory" name="sonSelectedCategory">
                            <option>בחר קטגוריה</option>
                            {parentCategory.sonsImmediate.map((x) => (
                                <option key={'sonsImmediate' + x.id} value={x.id}>
                                    {x.name}
                                </option>
                            ))}
                        </select>
                    )}
                </div>
                <div>
                    <span>לוגו</span>
                    <input onChange={(e) => setLogo(e.target.files[0])} id="logo" name="logo" type="file" accept="image/*,audio/*,video/*,svg" />
                </div>
                <div>
                    <span>סוג</span>
                    <select required value={storeType} onChange={(e) => setStoreType(e.target.value)} id="storeType" name="storeType">
                        <option>בחר סוג</option>
                        <option value={'STORE'}>חנות</option>
                    </select>
                </div>
                <div>
                    <span>צריך משלוחים?</span>
                    <input type="checkbox" name="needsDeliveryServices" id="needsDeliveryServices" value={needsDeliveryServices} onChange={() => setNeedsDeliveryServices((pre) => !pre)} />
                </div>
                <div>
                    <span>מציע משלוחים?</span>
                    <input type="checkbox" name="offersDelivery" id="offersDelivery" value={offersDelivery} onChange={() => setOffersDelivery((pre) => !pre)} />
                </div>
                <div>
                    <span>מאפשר איסוף?</span>
                    <input type="checkbox" name="allowsPickup" id="allowsPickup" value={allowsPickup} onChange={() => setAllowsPickup((pre) => !pre)} />
                </div>
                <div>
                    <span>אונליין?</span>
                    <input type="checkbox" name="isOnline" id="isOnline" value={isOnline} onChange={() => setIsOnline((pre) => !pre)} />
                </div>
                <div>
                    <span>מנהל מחסן?</span>
                    <input type="checkbox" name="managedStock" id="managedStock" value={managedStock} onChange={() => setManagedStock((pre) => !pre)} />
                </div>
                <div>
                    <span>מיקום</span>
                    <div className="btn clickable" onClick={locationHandelr}>
                        {place ? place.name : 'בחר מיקום'}
                    </div>
                </div>

                <button className="clickable" type="submit">
                    שמור
                </button>
            </form>
            <FullPopup isShow={isPopup} setIsShow={setIsPopup}>
                <PlaceMap setPlace={setPlace} />
            </FullPopup>
        </div>
    )
}

export default NDPStore
