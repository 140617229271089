import IconBtn from '../iconBtn/IconBtn'
import { BsHeartFill, BsHeart } from 'react-icons/bs'
import Loading from '../loading/Loading'
import formatMoney from 'servises/local/functions/formatMoney'
import Rate from '../rate/Rate'
import AmountManeger from '../amountManeger/AmountManeger'
import { useState, useEffect } from 'react'
import getLocalStorageController from 'controllers/localStorage/getLocalStorageController'
import { currentCartController } from 'controllers/cart/cartController'
import { useParams } from 'react-router-dom'
import getImageProduct from 'controllers/images/getImageProduct'
import getImageLogo from 'controllers/images/getImageLogo'

const ProductCard = (props) => {
    const [amountInCart, setAmountInCart] = useState(0)
    const [amountClass, setAmountClass] = useState(' none')
    const { id } = useParams()

    const onLikeHandler = () => {
        // if (props.value === undefined) return
        // let tmpProduct = { ...props.value }
        // tmpProduct.isLike = !tmpProduct.isLike
        // props.set(tmpProduct)
    }

    const onAddHandler = () => {
        if (props.value === null) return
        props.add(props.value)
    }

    useEffect(() => {
        // console.log(amountInCart)
        if (props.value) {
            let tmpClass = ' none'
            if (amountInCart > 0) tmpClass = ' have'
            currentCartController(id, props.value, amountInCart)
            setAmountClass(tmpClass)
        }
    }, [amountInCart])

    useEffect(() => {
        window.addEventListener('cartChange', isInCartEventHandler, false)
        window.dispatchEvent(new Event('cartChange'))

        return () => {
            window.removeEventListener('cartChange', isInCartEventHandler)
        }
    }, [])

    const isInCartEventHandler = () => {
        if (props.value) {
            const cart = getLocalStorageController(process.env.REACT_APP_LS_CURRENT_CART)
            if (cart != undefined || cart != null) {
                let productId = props.value.masterProductId || props.value.id
                if (cart[productId] && cart[productId].amountInCart && cart[productId].amountInCart != amountInCart) {
                    setAmountInCart(cart[productId].amountInCart)
                } else if (!cart[productId]) {
                    setAmountInCart(0)
                }
            }
        }
    }

    const onClickHandler = () => {
        props.setProductPopup(true)
    }

    const onDoubleClickHandler = () => {
        setAmountInCart((pre) => pre + 1)
    }

    return (
        <div className={'tools productCard' + amountClass} onDoubleClick={onDoubleClickHandler} title="לחיצה כפולה להוספה לסל">
            {props.value ? (
                <div className="details">
                    <div className="top gridOne" onDoubleClick={(e) => e.stopPropagation()}>
                        <img src={getImageProduct(props.value.advancedDataItems.images[0])} className="image" onClick={onClickHandler} title="לחיצה אחת לפתיחת מוצר" />
                        <div className="imgBg gridOne">
                            <div className="color"></div>
                            <div className="number">{amountInCart}</div>
                        </div>
                        <img src={props.value.logo ? getImageLogo(props.value.logo) : '/assets/images/logo.svg'} className="logo" />
                        <IconBtn icon={props.value.isLike ? BsHeartFill : BsHeart} className="like" onClick={onLikeHandler} />
                        {(props.value.storeProductisSale || props.value.isSale) && <span className="isSale">עכשיו במבצע!</span>}
                        {/* {true && <span className="isSale">עכשיו במבצע!</span>} */}
                    </div>
                    <div className="bot gridOne">
                        <div className="prodDit">
                            <span className="name">{props.value.name}</span>
                            <span className="company">{props.value.companyName}</span>
                        </div>
                        <div className="storeDit">
                            <div className="prices flex">
                                {(props.value.storeProductisSale || props.value.isSale) && <span className="salePrice">{formatMoney(props.value.storeProductsalePrice)}</span>}
                                <span className={props.value.storeProductisSale || props.value.isSale ? 'price sale' : 'price'}>{formatMoney(props.value.storeProductPrice || props.value.price)}</span>
                                {/* {true && <span className="salePrice">{formatMoney(props.value.storeProductsalePrice)}</span>} */}
                                {/* <span className={true ? 'price sale' : 'price'}>{formatMoney(props.value.storeProductPrice)}</span> */}
                            </div>
                            {Rate(props.value.ratingAverage)}
                        </div>
                        <div className={'toCart center' + amountClass} onDoubleClick={(e) => e.stopPropagation()}>
                            <AmountManeger value={amountInCart} setValue={setAmountInCart} />
                        </div>
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    )
}

export default ProductCard
