import ProductCard from 'utils/tools/productCard/ProductCard'
import ProductCardHome from 'utils/tools/productCardHome/ProductCardHome'
import ProductCardSmall from 'utils/tools/productCardSmall/ProductCardSmall'
import Title from 'utils/tools/title/Title'

const GridProducts = (props) => {
    return (
        <div className="gridProducts" onScroll={(e) => props.onScroll && props.onScroll(e.target)}>
            <div className="header">{props.title && <Title text={props.title} />}</div>
            <div className="products">
                {props.products &&
                    props.products.map((product, i) =>
                        props.productType === 'small' ? (
                            <ProductCardSmall
                                key={'gridProducts_productCard_' + product.id}
                                value={product}
                                onImageClick={() => (props.onImageClick ? props.onImageClick(product) : props.onClick(product))}
                                onNameClick={() => (props.onNameClick ? props.onNameClick(product) : props.onClick(product))}
                                onLogoClick={() => (props.onLogoClick ? props.onLogoClick(product) : props.onClick(product))}
                                onCompanyClick={() => (props.onCompanyClick ? props.onCompanyClick(product) : props.onClick(product))}
                            />
                        ) : props.productType === 'home' ? (
                            <ProductCardHome key={'gridProducts_productCard_' + product.id} value={product} setProductPopup={(flag) => props.setProductPopup(flag, product)} />
                        ) : (
                            <ProductCard key={'gridProducts_productCard_' + product.id} value={product} setProductPopup={(flag) => props.setProductPopup(flag, product)} />
                        )
                    )}
            </div>
        </div>
    )
}

export default GridProducts
